import React, { useEffect } from 'react'

import type { OrderFragment } from '../graphql/__generated__'

type FeedbackWidgetProps = {
  order: OrderFragment | undefined
}

export default function FeedbackWidget({ order }: FeedbackWidgetProps) {
  // For now we just assume it will be delivered in ten days
  let estimatedDeliveryDate = new Date()
  estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 10)
  const estimatedDeliveryDateString = estimatedDeliveryDate.toISOString().split('T')[0]

  useEffect(() => {
    if (!order || !order.customer?.email) {
      return
    }

    const deliveryCountry = order.designs?.[0]?.destinations?.[0]?.address?.countryCode
    if (!deliveryCountry) {
      return
    }

    // @ts-ignore Weird Google API
    window.renderOptIn = () => {
      // @ts-ignore Weird Google API
      window.gapi.load('surveyoptin', () => {
        // @ts-ignore Weird Google API
        window.gapi.surveyoptin.render({
          merchant_id: "375966816",
          order_id: order.id,
          email: order.customer?.email,
          delivery_country: deliveryCountry,
          estimated_delivery_date: estimatedDeliveryDateString,

          // This is the 10 minute book preorder, we use it for generic 'book'
          products: [{"gtin": "860006940386"}],

          opt_in_style: "CENTER_DIALOG",
        })
      })
    }

    const script = document.createElement('script')
    script.src = "https://apis.google.com/js/platform.js?onload=renderOptIn"
    script.async = true
    document.head.appendChild(script)

    return () => {
      // @ts-ignore Weird Google API
      delete window.renderOptIn
      document.head.removeChild(script)
    }
  }, [order?.id, order?.customer?.email, order?.designs?.[0].destinations?.[0].address?.countryCode])

  return <></>
}
