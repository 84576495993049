import React, { useState } from 'react'
import { AddDestinationDocument, AddDestinationMutation, DesignFragment, DestinationFragment, Maybe, OrderFragment, RemoveDesignDocument, RemoveDesignMutation, UpdateDesignMetadataDocument, UpdateDesignMetadataMutation } from "../graphql/__generated__"
import { useAuthentication } from '../apollo-client'

import DestinationSummary from './DestinationSummary'
import MediaStack from './MediaStack'
import ModalPreview from './ModalPreview'
import Button from './Button'
import TooLongWarning from './TooLongWarning'
import useSteps, { AddressStep, EGiftEmailStep, GiftAddressStep, MediaStep } from '../utils/Steps'
import { DesignValidation, DestinationValidation } from '../utils/OrderValidation'
import { useMutation } from '@apollo/client'
import { booksInBalance, savingsTiers } from '../utils/Price'
import type { SavingsTier } from '../utils/Price'
import { getLastDestinationIDForOrder, getLastTouchedDestinationForOrder, setLastTouchedDestinationForOrder } from '../utils/LastTouchedDestination'
import ButtonLink from './ButtonLink'

type DesignSummaryProps = {
  order: OrderFragment
  design: DesignFragment
  destinations: Maybe<DestinationFragment[]>
  designValidation?: DesignValidation
  destinationValidations?: { [destinationId: string]: DestinationValidation }
  allowEdit: boolean
  shrink?: boolean
  showOffer?: boolean
  showCloneDesign?: boolean
  onShowImport?: () => void
}

export default function DesignSummary({ order, design, destinations, designValidation, destinationValidations, allowEdit, shrink, showOffer, showCloneDesign, onShowImport }: DesignSummaryProps) {
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [shrunk, setShrunk] = useState<boolean>(shrink || false)
  const { navigateTo } = useSteps()

  const { customerData: me } = useAuthentication()

  const [
    removeDesign,
    { loading: removeDesignLoading },
  ] = useMutation<RemoveDesignMutation>(RemoveDesignDocument)

  const [
    updateDesignMetadata,
    { loading: updateDesignMetadataLoading },
  ] = useMutation<UpdateDesignMetadataMutation>(UpdateDesignMetadataDocument)

  const setBlankBookMetadata = () => {
    updateDesignMetadata({
      variables: {
        designId: design.id,
        metadataJSON: JSON.stringify({ blank: true })
      },
    })
  }

  const [
    addDestination,
    { loading: addingDestination },
   ] = useMutation<AddDestinationMutation>(AddDestinationDocument, {
    onError: () => {
      setLastTouchedDestinationForOrder(order.id, undefined)
    },
  })

  const sendToNewDestination = () => {
    addDestination({
      variables: {
        orderId: order.id,
        designId: design.id,
        cloneDestinationId: getLastTouchedDestinationForOrder(order.id) ?? getLastDestinationIDForOrder(order)
      },
      onCompleted: ({ addDestination: { designs } }) => {
        const { destinations } = designs?.find(d => d.design?.id === design.id) ?? {}
        const destinationId = destinations ? destinations[destinations.length - 1].id : undefined

        let newStep = AddressStep
        if (order.isEGift) {
          newStep = EGiftEmailStep
        } else if (order.isGift) {
          newStep = GiftAddressStep
        }

        navigateTo(newStep, {
          orderId: order.id,
          designId: design.id,
          destinationId,
        }, { returnHere: true })
      }
    })
  }

  const editMedia = () => {
    navigateTo(MediaStep, {
      orderId: order.id,
      designId: design.id,
    }, { returnHere: true })
  }

  let totalBooks = 0
  order?.designs?.forEach((design) => {
    design?.destinations?.forEach((dest) => {
      totalBooks += dest?.quantity
    })
  })

  // We only want to show the discount offer if the next book would actually be paid with cash
  // (wasn't already a prebought credit), otherwise we aren't really offering a discount on
  // anything.
  let nextBookWouldBeInCash = false
  let someBooksPaidInCash = false
  if (me?.balance) {
    const inBalance = booksInBalance(me.balance)

    nextBookWouldBeInCash = inBalance <= totalBooks
    someBooksPaidInCash = inBalance < totalBooks
  }

  let savingsNeedMoreText = ""
  let savingsTier: SavingsTier | undefined = undefined
  if (nextBookWouldBeInCash) {
    for (let tier of savingsTiers) {
      if (totalBooks < tier.minQuantity) {
        savingsTier = tier
        break
      }
    }

    if (savingsTier) {
      const needsMore = savingsTier.minQuantity - totalBooks
      if (needsMore === 1) {
        savingsNeedMoreText = "another book"
      } else {
        savingsNeedMoreText = `${ needsMore } more books`
      }
    }
  }

  if (!design || !order) {
    return <></>
  }

  return (
    <div>
      <ModalPreview
        show={ showPreview }
        onClose={ () => setShowPreview(false) }
        designId={ design.id }
        generation={ design.generation }
      />

      { !!design.media?.length &&
        <header>
          <div className="grid grid-cols-12 md:pt-5 md:pb-8">
            <div className="hidden md:block col-span-9" data-cy="media-summary-container">
              <MediaStack media={ design.media || null } />
            </div>
            <div className="col-span-12 md:col-span-3" data-cy="media-buttons-container">
              <Button
                className="m-auto text-sm block my-2 w-48"
                type="primary"
                onClick={ () => setShowPreview(true) }>Watch Preview</Button>
              { allowEdit &&
                <Button
                  className="m-auto text-sm block my-2 w-48"
                  onClick={ editMedia }>Edit Photos / Videos</Button>
              }
              { showCloneDesign &&
                <ButtonLink
                  className="m-auto text-sm block my-2 w-48"
                  to={ `/design/${ design.id }/clone` }>Order Another Copy</ButtonLink>
              }
            </div>
          </div>

          { shrink &&
          <div className="bg-gray-100 p-2 font-bold text-sm mt-1 cursor-pointer" onClick={ () => setShrunk(!shrunk) }>
            { shrunk ? "Show design" : "Hide design" }
          </div>
          }

        </header>
      }

      { designValidation?.blankWithoutMetadata &&
        <div className="p-4 mb-4 bg-red-100 max-w-xl m-auto">
          <p className="mb-4">
            <span className="font-bold">This book has no photos or videos.</span> Please confirm that you'd like a blank book to load yourself, or provide us with photos and videos to load for you (recommended).
          </p>
          <div className="m-2 space-x-2 text-center">
            <Button onClick={ setBlankBookMetadata } active={ updateDesignMetadataLoading } className="my-2 md:my-0">
              Send me a Blank Book
            </Button>
            <Button onClick={ editMedia } type="primary" className="my-2 md:my-0">
              Add Photos / Videos
            </Button>
          </div>
        </div>
      }

      { designValidation?.missingDestinations &&
        <div className="p-2 pl-4 mb-4 bg-red-100 flex rounded">
          <p className="flex-1 m-auto">
            This book is missing an address.
          </p>
          <Button active={ addingDestination } onClick={ sendToNewDestination } type="secondary">
            Add Address
          </Button>
        </div>
      }

      <div className="mb-4">
        <TooLongWarning designValidation={ designValidation } onEditMedia={ editMedia } />
      </div>

      <main className={ (shrunk ? " hidden" : "") }>
        <ul className="space-y-4">
        {
          destinations?.map(destination => (
            <li key={ destination.id } className="bg-gray-50 p-4">
              <DestinationSummary
                order={ order }
                designId={ design.id }
                destination={ destination }
                destinationValidation={ destinationValidations?.[destination.id] }
                allowEdit={ allowEdit }
              />
            </li>
          ))
        }

        { allowEdit && !designValidation?.hasProblems &&
          <li key="new" className="bg-gray-50 p-4">

            { showOffer && savingsTier &&
              <div className="md:mx-4 mt-4 mb-8 bg-white shadow flex">
                <img src={ `${ process.env.PUBLIC_URL }/images/baby-with-book.jpg` } style={{ height: '120px' }} />

                <div className="m-4">
                  <h3>
                    <span className="text-lg">Limited Time Offer</span>
                    <span> — Send more and save.</span>
                  </h3>
                  <p className="mt-2">Add { savingsNeedMoreText } to your cart and
                    { someBooksPaidInCash
                      ? <span className="font-bold"> we will take ${ savingsTier.savings } off every book you buy today</span>
                      : <span className="font-bold"> we will take ${ savingsTier.savings } off your order</span>
                    }
                  .</p>
                  <p className="text-sm mt-2">Only valid with this purchase. Only available for a limited time.</p>
                </div>
              </div>
            }

            <Button
              className="m-auto text-sm block mt-2 mb-4 w-72"
              active={ addingDestination }
              onClick={ sendToNewDestination }
            >Send Another { !order.isEGift && 'Copy' }</Button>

            { onShowImport &&
              <a
                className="text-sm text-gray-500 hidden md:block text-center underline cursor-pointer"
                onClick={ onShowImport }>Import addresses from file</a>
            }
          </li>
        }

        { !order.isGift && !!designValidation?.hasProblems &&
          <li key="remove">
            <Button
              className="m-auto text-sm block my-2 w-72"
              type="danger"
              active={ removeDesignLoading }
              onClick={ () => {
                removeDesign({
                  variables: {
                    orderId: order.id,
                    designId: design.id,
                  },
                })
              }}
            >Remove From Order</Button>
          </li>
        }
        </ul>
      </main>
    </div>
  )
}
