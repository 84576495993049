import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { OrderFragment, OrdersQuery, OrderState } from '../graphql/__generated__'
import { classNames } from '../utils/classes'
import { formatDate } from '../utils/Date'
import { useRemoveOrder } from '../utils/Order'
import Button from './Button'
import DesignSummaryListing from './DesignSummaryListing'

type OrderSummaryListingProps = {
  orders: OrdersQuery["customerOrders"]
  isDraft?: boolean
}

export default function OrderSummaryListing({ orders, isDraft }: OrderSummaryListingProps){
  const navigate = useNavigate()

  const [expandedOrderId, setExpandedOrderId] = useState<string | undefined>()

  const { removeOrder, removing } = useRemoveOrder(orders)

  const { verbById, destinationSummaryById } = useMemo(() => ({
    verbById: orders?.reduce((prev, order) => {
      switch (order.state) {
        case OrderState.Draft:
          prev[order.id] = 'started'
          break
        case OrderState.Paid:
          prev[order.id] = 'paid for'
          break
        case OrderState.Complete:
          prev[order.id] = 'completed'
          break
        // Currently skipped by Orders page:
        // case OrderState.Archived:
        // case OrderState.Canceled:
      }
      return prev
    }, {} as { [orderId: string]: string }) ?? {},
    // firstCoverById:orders?.reduce((prev, order) => {
    //   for (const { destinations } of order.designs ?? []) {
    //     for (const destination of destinations ?? []) {
    //       if (destination.coverId) {
    //         prev[order.id] = destination.coverId
    //         break
    //       }
    //     }
    //     if (prev[order.id]) {
    //       break
    //     }
    //   }

    //   return prev
    // }, {} as { [orderId: string]: string }) ?? {},
    destinationSummaryById: orders?.reduce((prev, order) => {
      let designCount = order.designs?.length ?? 0
      let destinationCount = 0
      order.designs?.forEach(({ destinations }) => {
        destinationCount += destinations?.length ?? 0
      })

      if (order.isEGift) {
        prev[order.id] = `E-Gift Card being sent to ${ destinationCount } email${ destinationCount === 1 ? '' : 's' }.`
      } else if(order.isGift) {
        prev[order.id] = `Blank Gift being sent to ${ destinationCount } destination${ destinationCount === 1 ? '' : 's' }.`
      } else {
        prev[order.id] = `${ designCount } Book${ designCount === 1 ? '' : 's' } being sent to ${ destinationCount } destination${ destinationCount === 1 ? '' : 's' }.`
      }

      return prev
    }, {} as { [orderId: string]: string }) ?? {},
  }), [orders])

  const getURL = (order: OrderFragment) => {
    let prefix = ''

    if (isDraft) {
      if (order.isEGift) {
        prefix = '/e-gift'
      } else if(order.isGift) {
        prefix = '/gift'
      }
    }

    let step = 'status'
    if (isDraft) {
      step = order.isEGift ? 'email' : 'cover'
    }

    return `${ prefix }/order/${ order.id }/${ step }`
  }

  return <>
    { orders?.map(order => <div key={ order.id }>
      <a
        href={ getURL(order) }
        onClick={ e => {
          if (isDraft) {
            e.preventDefault()
            setExpandedOrderId(prev => prev === order.id ? undefined : order.id)
          }
        } }
        tabIndex={ 0 }
        className={ classNames(
          'block w-full cursor-pointer shadow p-6 my-3',
          'bg-white focus:outline-0 focus:border-green-300 hover:border-green-500',
          'flex rounded border-pink-300 border-4',
          expandedOrderId === order.id ? ' border-green-300' : '',
        ) }
      >
        {/* TODO: first cover ID's image falling back to a smaller icon? */}
        {/* <div className="hidden mr-8 md:flex items-center">
          <PencilSquareIcon className="w-14 h-14 md:w-24 md:h-24 text-pink-300" />
        </div> */}

        <div className="items-center">
          <h2 className="text-xl font-bold mb-2">Order #{ order.name } { verbById[order.id] } { formatDate(order.createdAt) }</h2>
          { <p>{ destinationSummaryById[order.id] }</p> }
        </div>
      </a>

      { expandedOrderId === order.id && <div className="my-6 md:mx-10">
        <div className="mb-6">
          <DesignSummaryListing order={ order } hideEmpty />
        </div>

        {/* expandedOrderId shouldn't be set if not isDraft; double check before showing edit/delete buttons */}
        { isDraft && <div className="space-y-2 text-center">
          <div>
            {/* TODO: on mobile (or always), should this send to review step?
                      (to avoid showing multi-designs/destinations) */}
            <Button type="primary" onClick={ () => navigate(getURL(order)) }>
              Continue this Order
            </Button>
          </div>
          <div>
            <Button type="secondary" active={ removing } onClick={ () => removeOrder(order.id) }>
              Delete this Order
            </Button>
          </div>
        </div> }
      </div> }
    </div>) }
  </>
}
