export const gtagAddToCart = (orderId: string) => {
    if (!gtag) {
        return
    }

    gtag('event', 'conversion', {
        'send_to': 'AW-470754614/mUrOCO2c6b4CELbKvOAB',
    })

    gtag('event', "add_to_cart", {
        'transaction_id': orderId,
        'event_category': "Linotype",
        'event_label': "Linotype Add To Cart",
    })
}

export const gtagBeginCheckout = (orderId: string) => {
    if (!gtag) {
        return
    }

    gtag('event', 'begin_checkout', {
        'transaction_id': orderId,
        'event_category': "Linotype",
        'event_label': "Linotype Begin Checkout",
    })
}

export const gtagPurchaseAmount = (orderId: string, amount: number) => {
    if (!gtag) {
        return
    }

    gtag('event', 'conversion', {
        'transaction_id': orderId,
        'send_to': 'AW-470754614/vmpvCKfNifECELbKvOAB',
        'currency': 'USD',
        'value': amount / 100,
    })

    gtag('event', 'purchase', {
        'transaction_id': orderId,
        'event_category': "Linotype",
        'event_label': "Linotype Purchase",
        'currency': 'USD',
        'value': amount / 100,
    })
}