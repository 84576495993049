import React, { useEffect, useState } from 'react'

import MediaStack from './MediaStack'
import QuantityForm from './QuantityForm'
import AddressSummary from './AddressSummary'
import CoverImage from './CoverImage'
import Button from './Button'

import { OrderFragment, DestinationFragment, DesignWithDestinationsFragment, AddDestinationDocument, AddDestinationMutation } from '../graphql/__generated__'
import useSteps from '../utils/Steps'
import { useMutation } from '@apollo/client'

type DestinationSummaryListingProps = {
  order: OrderFragment
  designId?: string
  destinationId?: string
  onEdit: (design: DesignWithDestinationsFragment, destintation: DestinationFragment) => void
  onCancel: () => void
  form?: JSX.Element
  componentName?: string
}

export default function DestinationSummaryListing({ order, designId, destinationId, onEdit, onCancel, form, componentName }: DestinationSummaryListingProps) {
  const { navigateTo, currentStep } = useSteps()

  const [
    addDestination,
    { loading: addingDestination },
   ] = useMutation<AddDestinationMutation>(AddDestinationDocument)

  const [designIdForAddDestination, setDesignIdForAddDestination] = useState<string | undefined>()
  useEffect(() => {
    if (!addingDestination) {
      setDesignIdForAddDestination(undefined)
    }
  }, [addingDestination])

  const sendToNewDestination = (forDesignId?: string) => {
    if (!forDesignId || !currentStep) {
      return
    }

    setDesignIdForAddDestination(forDesignId)
    addDestination({
      variables: {
        orderId: order.id,
        designId: forDesignId,
      },
      onCompleted: ({ addDestination: { designs } }) => {
        const { destinations } = designs?.find(d => d.design?.id === forDesignId) ?? {}
        const destinationId = destinations ? destinations[destinations.length - 1].id : undefined

        navigateTo(currentStep, {
          orderId: order.id,
          designId: forDesignId,
          destinationId,
        }, {})
      }
    })
  }

  return <>
    { order.designs?.map((design, i) => {
      const designKey = design.design?.id ?? `design:${ i }`
      const selectedDesign = !!designId && design.design?.id === designId

      if (!!designId && !selectedDesign) {
        return null;
      }

      return <div key={ designKey }>
        { (order.designs?.length ?? 0) > 1 && <>
          <div className="max-w-full mb-2 flex">
            <h1 className="flex-grow text-lg">Design { i + 1 }</h1>
            { design.design?.isBlank && <span className="italic">Intentionally Blank</span>}
          </div>
          { !!design.design?.media?.length && <div className="max-w-full h-[120px] mb-4">
            <MediaStack media={ design.design?.media } />
          </div> }
        </> }

        <ul className="mt-4">
          <li key="header">
            <div className="w-full my-2 hidden md:flex font-bold">
              <div className="flex text-center">
                <div className="w-16 mr-4">{ !order.isEGift && 'Cover' }</div>
                <div className="md:w-48">Quantity</div>
              </div>
              <div className="flex grow">
                <div className="grow mr-4">{ order.isEGift ? 'Email' : 'Address' }</div>
                <div className="w-32"></div>
              </div>
            </div>
          </li>

          { design.destinations?.map((destination, j) => {
            const selectedDestination = destinationId === destination.id

            return <li key={ designKey + ':' + destination.id }>
              { typeof design.design?.id === 'string' && (!destinationId || selectedDestination) && <>
                <div className="w-full md:my-2 md:flex">
                  <div className="my-2 md:my-0 flex items-center">
                    <div className="w-16 grow md:grow-0 mr-4">
                      <CoverImage coverId={ destination.coverId || null } size={ 4 } />
                    </div>
                    <div className="md:hidden mr-4">Quantity:</div>
                    <div className="md:w-48 md:text-center">
                      <QuantityForm
                        destination={ destination }
                        order={ order }
                        designId={ design.design?.id }
                      />
                    </div>
                  </div>
                  <div className="my-2 md:my-0 flex md:grow items-center">
                    <div className="grow mr-4 overflow-hidden">
                      <AddressSummary address={ destination?.address || null } isEGift={ order.isEGift } />
                    </div>
                    <div className="w-32">
                      { selectedDestination ? <>
                        <Button
                          className="w-32"
                          type="secondary"
                          onClick={ onCancel }
                        >Cancel</Button>
                      </> : <>
                        <Button
                          className="w-32 px-2" // px-2 helps fit longer Address/Message strings
                          onClick={ () => onEdit(design, destination) }
                        >Edit{ componentName ? ` ${componentName}` : '' }</Button>
                      </>}
                    </div>
                  </div>
                </div>
                { selectedDestination && <>
                  { form }
                  { j !== (design.destinations?.length ?? 0) - 1 && <div className="h-6"></div> }
                </> }
              </> }
            </li>
          }) }

          { !destinationId && <li key={ `${ designKey }:add` }>
            <div className="w-full text-center mt-4">
              <Button
                active={ addingDestination && design.design?.id === designIdForAddDestination }
                onClick={ () => sendToNewDestination(design.design?.id) }
              >Add { !!design.destinations?.length && 'Another' }</Button>
            </div>
          </li> }
        </ul>
      </div>
    })}
  </>
}
