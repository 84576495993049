import React, { forwardRef, useImperativeHandle, useCallback, useEffect, useMemo } from 'react'
import { createStore } from 'polotno/model/store'
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno'
import { Toolbar } from 'polotno/toolbar/toolbar'
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons'
import { SidePanel, DEFAULT_SECTIONS } from 'polotno/side-panel'
import { Workspace } from 'polotno/canvas/workspace'

import '../deps/blueprint/scoped-blueprint.css'
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'

import { initPolotnoUploader } from '../utils/uppy'
import { setTranslations } from 'polotno/config'

setTranslations({
  sidePanel: {
    createBody: "Add Small Text",
    createSubHeader: "Add Medium Text",
    createHeader: "Add Large Text",
  },
})

const PageControls = () => {
  return <></>
}

export type ImageEditorState = {
  thumbnail: string
  production: string
  design: any
}

export type ImageEditorRef = {
  getState: () => Promise<ImageEditorState>,
}

type ImageEditorProps = {
  design: any
  onChange: (design: any) => void

  // this simply provides a way to re-render the ImageEditor
  // necessary when creating a new front cover, which starts w/undefined design
  // then saving your changes and moving to a new back cover, which again is undefined
  // in order to re-render the component, we pass in strings such as "front-[coverID]" or "back-new"
  uniqueID: string
}

const ImageEditor = forwardRef(({ design, onChange }: ImageEditorProps, ref) => {
  const store = useMemo(() => {
    const _store = createStore({
      key: 'HTPTBkg0itBALDhLpD61',
      showCredit: false,
    })

    // Our books are 17.5cm, which is about 2000 pixels at 300DPI
    // 1080 is a nice size as there are many templates, but we
    // will export with pixelRatio 2+
    _store.setSize(1080, 1080)
    _store.setScale(0.5)
    _store.addPage()

    // .5cm of the 17.5cm size-with-bleed
    _store.activePage?.set({ bleed: 1080 * .5/17.5 })
    _store.toggleBleed(true)

    // w/o timeout, change event is fired on init
    window.setTimeout(() => _store.on('change', onChange))

    return _store
  }, [onChange])

  useEffect(() => {
    if (design) {
      store.loadJSON(design)
    }
  }, [store, design])


  useEffect(initPolotnoUploader, [])

  const exportState = useCallback(async () => {
    store.setElementsPixelRatio(2)

    return {
      thumbnail: await store.toDataURL({ pixelRatio: 0.25, mimeType: 'image/jpeg' }),
      production: await store.toDataURL({ pixelRatio: 2, includeBleed: true }),
      design: JSON.stringify(store.toJSON()),
    }
  }, [store])

  useImperativeHandle(ref, () => ({
    async getState(): Promise<ImageEditorState> {
      return exportState()
    }
  }))

  let sections: typeof DEFAULT_SECTIONS = []
  for (let i=0; i < DEFAULT_SECTIONS.length; i++){
    // We don't really want to let people resize the image
    if (DEFAULT_SECTIONS[i].name !== 'size'){
      sections.push(DEFAULT_SECTIONS[i])
    }
  }

  return (
    <div className="bp4">
      <PolotnoContainer style={{ width: Math.min(879, window.innerWidth) + 'px', height: '800px' }}>
        <SidePanelWrap>
          {/* TODO: default section of background disappears when navigating from front cover to back cover */}
          <SidePanel store={ store } sections={ sections } defaultSection="background" />
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar store={store} />
          <Workspace store={store} components={{ PageControls }} />
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </div>
  )
})

export default ImageEditor
