import React from 'react'

type LinkProps = {
  href: string
  target?: string
  disabled?: boolean
  className?: string
  ariaCurrent?: boolean | "time" | "false" | "true" | "page" | "step" | "location" | "date" | undefined
  testKey?: string
  children: JSX.Element[] | JSX.Element
}

export default function Link({ href, target, disabled, className, ariaCurrent, children, testKey }: LinkProps) {
  if (disabled) {
    return <span className={ className }>{ children }</span>
  } else {
    return <a data-cy={ testKey } href={ href } target={ target } className={ className } aria-current={ ariaCurrent }>{ children }</a>
  }
}
