import React from 'react'

import PricingSummary from './PricingSummary'
import type { OrderFragment } from '../graphql/__generated__'

type OrderPricingSummaryProps = {
  order: OrderFragment
}

export default function OrderPricingSummary({ order }: OrderPricingSummaryProps) {
  const cost = order?.cost
  if (!cost) {
    return <></>
  }

  const { total } = cost

  if (order.isReplacement) {
    return <div className="text-center">Cost: $0 <span className="text-sm">(if original returned)</span></div>
  }

  return <PricingSummary
    costs={ total }
    isEGift={ order.isEGift }
  />
}
