import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react'
import { useMutation } from '@apollo/client'
import { FormProvider, useForm } from 'react-hook-form'
import GiftMessageInput from './GiftMessageInput'

import type { FormRef, DestFormProps } from './DestComponentEditor'
import { UpdateGiftMessageDocument } from '../graphql/__generated__'
import type { UpdateGiftMessageMutation } from '../graphql/__generated__'
import GiftMessagePreview from './GiftMessagePreview'
import { findDestination } from '../utils/Destination'
import type { MessageFormFields } from './GiftMessageInput'

const MessageForm = forwardRef<FormRef, DestFormProps>(({ order, options, destination, onSave, onError }, formRef) => {
  const [
    updateGiftMessage,
    { loading: saving, error: saveError },
  ] = useMutation<UpdateGiftMessageMutation>(UpdateGiftMessageDocument)

  const submit = async function (values: MessageFormFields) {
    if (!destination?.id) {
      throw new Error("No destination provided")
    }

    updateGiftMessage({
      variables: {
        orderId: order.id,
        destinationId: destination.id,
        message: values.giftMessage,
      },
      onError: err => onError?.(err.message),
      onCompleted: ({ updateGiftMessage: _order }) => {
        const result = findDestination(_order, destination.id)
        result && onSave?.(result)
      },
    })
  }

  const methods = useForm<MessageFormFields>()
  const { watch, handleSubmit, reset } = methods

  const { giftMessage } = destination ?? {}
  useEffect(() => reset({ giftMessage }), [reset, giftMessage])

  const errorHandler = useCallback(
    () => onError?.("Check validation errors on form"),
    [onError],
  )

  useImperativeHandle(formRef, () => ({
    saving,
    submit: handleSubmit(submit, errorHandler),
  }), [saving, errorHandler])

  return <div className="w-[30rem] m-auto max-w-full">
    <FormProvider { ...methods }>
      <form onSubmit={ handleSubmit(submit, errorHandler) }>
        { options.showPreview !== false && <GiftMessagePreview message={ watch('giftMessage.body') } /> }
        <GiftMessageInput />
        { saveError && <p className="p-2 m-4 bg-red-300">Error saving, please try again.</p> }
      </form>
    </FormProvider>
  </div>
})

export default MessageForm
