import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'
import { useBeforeunload } from 'react-beforeunload'
import { QuestionMarkCircleIcon, XMarkIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'

import { CoverFragmentDoc, CustomCoversDocument, OrderDocument, OrderFragment, UpdateBackCoverDocument, UpdateCoverDocument, UpdateOrCreateCustomCoverDesignDocument, UpdateOrCreateCustomCoverDesignMutation } from '../graphql/__generated__'
import type { CoverFragment } from '../graphql/__generated__'
import ImageEditor from '../components/ImageEditor'
import type { ImageEditorRef } from '../components/ImageEditor'
import Button from '../components/Button'
import useSteps, { BuilderFlow, CoverStep, CustomCoverStep, GiftCoverStep, GiftFlow } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import { useAuthenticatedQuery } from '../apollo-client'

interface CustomCoverDesignerProps {
  isGift?: boolean
  face?: "front" | "back"
}

export default function CustomCoverDesigner({ isGift, face }: CustomCoverDesignerProps) {
  const navigate = useNavigate()
  const { orderId, designId, destinationId, coverId } = useParams()

  const [showOnboarding, setShowOnboarding] = useState<boolean>(!localStorage.customCoverOnboardingHidden)

  const hasStepsFlow = orderId && designId && destinationId

  const { setStepState } = useStepContext()
  useEffect(() => {
    if (hasStepsFlow) {
      setStepState({
        flow: isGift ? GiftFlow : BuilderFlow,
        step: isGift ? GiftCoverStep : CoverStep,
        args: { orderId, designId, destinationId, coverId },
      })
    } else {
      setStepState({
        flow: null,
        step: CustomCoverStep,
        args: null,
      })
    }
  }, [isGift, hasStepsFlow, orderId, designId, destinationId, coverId])

  const { navigateNext, currentPath } = useSteps()

  // We load all of this user's custom covers, as this query has likely already
  // been done on the cover selection page.
  const {
    data: { customCovers: covers } = {},
    loading: customCoversLoading,
    error: customCoversError,
  } = useAuthenticatedQuery<{
    customCovers: CoverFragment[],
  }>(CustomCoversDocument, {
    notifyOnNetworkStatusChange: true,
  })

  // We load the Order to skip updateCover mutation if destination already has correct coverId;
  // this query is most likely cached from the CoverEditor, unless user refreshed page.
  const {
    data: { orderStatus: order } = {},
    loading: orderLoading,
    error: orderError,
  } = useAuthenticatedQuery<{
    orderStatus: OrderFragment,
  }>(OrderDocument, {
    skip: !hasStepsFlow,
    variables: {
      orderId,
    },
  })

  const loading = customCoversLoading || orderLoading
  const error = customCoversError || orderError

  const parsedDesign = useMemo(() => {
    if (!covers || !coverId) {
      return
    }

    const cover = covers.find((c: CoverFragment) => c.id == coverId)
    if (!cover?.designJSON) {
      return
    }

    try {
      return JSON.parse(cover.designJSON)
    } catch (e) {
      console.error("Error parsing design", e)
    }
  }, [covers, coverId])

  const destination = useMemo(() => {
    if (!hasStepsFlow) {
      return
    }

    return order?.designs
      ?.find(({ design }) => design?.id === designId)
      ?.destinations?.find(destination => destination?.id === destinationId)
  }, [hasStepsFlow, order?.designs, designId, destinationId])
  const hasCoverIdChanged = coverId !== destination?.coverId

  const [
    updateOrCreateCustomCoverDesign,
    { loading: designSaving, error: designErrorSaving },
  ] = useMutation<{ updateOrCreateCustomCoverDesign: CoverFragment }>(UpdateOrCreateCustomCoverDesignDocument, {
    update(cache, { data }) {
      if (!data?.updateOrCreateCustomCoverDesign) {
        return
      }

      cache.modify({
        fields: {
          customCovers(prev = []) {
            return [...prev, cache.writeFragment({
              data: data.updateOrCreateCustomCoverDesign,
              fragment: CoverFragmentDoc,
            })]
          }
        },
      })
    }
  })
  const [
    updateCover,
    { loading: coverSaving, error: coverErrorSaving },
  ] = useMutation(UpdateCoverDocument)
  const [
    updateBackCover,
    { loading: backCoverSaving, error: backCoverErrorSaving },
  ] = useMutation(UpdateBackCoverDocument)

  const [isSaving, setIsSaving] = useState(designSaving || coverSaving || backCoverSaving)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  const editorRef = useRef<ImageEditorRef | null>(null)
  const editor = useMemo(() => {
    if (loading) {
      return <></>
    }

    return <ImageEditor
      design={ parsedDesign }
      onChange={ () => setHasUnsavedChanges(true) }
      uniqueID={(() => {
        switch (face) {
          case undefined:
            return coverId ?? "new"
          case "front":
            return `front-${coverId ?? "new"}`
          case "back":
            return `back-${coverId ?? "new"}`
        }
      })()}
      ref={ editorRef }
    />
  }, [loading, parsedDesign, face, coverId])

  const save = useCallback(async () => {
    if (editorRef.current) {
      setIsSaving(true)

      const state = await editorRef.current.getState()

      let _coverId = coverId
      if (hasUnsavedChanges) {
        const { data } = await updateOrCreateCustomCoverDesign({
          variables: {
            coverId: coverId || null,
            designJSON: state.design,
            thumbnailImageAsBase64JPG: state.thumbnail,
            productionImageAsBase64PNG: state.production,
          },
        })

        if (data?.updateOrCreateCustomCoverDesign.id) {
          _coverId = data.updateOrCreateCustomCoverDesign.id
        }
      }

      if (!_coverId) {
        // This would be expected if either:
        // - save() called on a new cover w/o any changes
        // - error on updateOrCreateCustomCoverDesign; already logged
        setIsSaving(false)
        return
      }

      if (!hasStepsFlow) {
        if (!coverId) {
          navigate(`/cover/${ _coverId }`, { replace: true })
        }

        setIsSaving(false)
        return
      }

      const finallyFn = () => {
        if (face === "front") {
          setIsSaving(false)

          const nextCoverId = !destination?.backCoverId?.length ? "new" : destination.backCoverId
          navigate(currentPath({ coverId: nextCoverId }).replace(`/cover/${nextCoverId}`, `/cover/back/${nextCoverId}`))
        } else {
          navigateNext({})
        }
      }

      if (!hasCoverIdChanged) {
        finallyFn()
        return
      }

      const updateFn = face === "front" ? updateCover : updateBackCover
      updateFn({
        variables: {
          destinationId,
          coverId: _coverId,
        },
        onCompleted: finallyFn,
      })
    }
  }, [coverId, destinationId, destination, hasUnsavedChanges, hasCoverIdChanged, face, navigateNext, navigate, currentPath])

  const navigateToFront = useCallback(() => {
    if (!destination?.coverId) {
      return
    }
    navigate(currentPath({ coverId: destination.coverId }))
  }, [destination, navigate, currentPath])

  const discard = useCallback(async () => {
    // This should only be called if hasStepsFlow, but doesn't hurt to call navigatePrev regardless.
    if (!hasUnsavedChanges || window.confirm("You have unsaved changes; are you sure you want to discard?")) {
      navigate(currentPath({ coverId: undefined }))
    }
  }, [hasUnsavedChanges, navigate, currentPath])

  const removeBackCover = useCallback(async () => {
    if (face !== "back" || !destinationId) {
      return
    }

    updateBackCover({
      variables: {
        destinationId,
        coverId: '',
      },
      onCompleted: () => navigateNext({}),
    })

  }, [face, destinationId, updateBackCover, navigateNext])

  useBeforeunload(event => {
    if (hasUnsavedChanges) {
      event.preventDefault()
    }
  })

  if (loading) {
    return <div className="my-4 flex justify-center"><CircularProgress /></div>
  }
  if (error) {
    return <p className="my-4 p-2 bg-red-100">Error loading cover.</p>
  }

  const hideOnboarding = () => {
    localStorage.customCoverOnboardingHidden = true
    setShowOnboarding(false)
  }

  return <>
    { (designErrorSaving || coverErrorSaving || backCoverErrorSaving) && <p className="my-2 p-2 bg-red-100">Error saving.</p> }
    <div className="flex flex-row justify-end mb-2 gap-4">
      <div className="flex-grow">
        <h2 className="font-bold">{ (() => {
          switch(face) {
            case undefined:
              return "Custom Cover Designer"
            case "front":
              return "Custom Front Cover [$20]"
            case "back":
              return "Optional Back Cover [$10]"
          }
        })() }&nbsp;<a
          className="underline text-pink-300 hover:text-pink-500"
          target="_blank"
          title="Open the custom cover help document"
          href="https://help.sendheirloom.com/article/12-custom-covers">
            <QuestionMarkCircleIcon className="w-6 h-6 inline" />
          </a></h2>
        <div>Use our design tool to create your own full-color custom cover featuring your photos and text. (Typical turnaround time for custom covers is 5-7 business days.)</div>
      </div>
      <div className="shrink-0">
        { hasStepsFlow &&
          <>
            <Button
              type="secondary"
              onClick={ face === "back" && destination?.coverId ? navigateToFront : discard }
              className="mr-2 mb-2 md:mb-0"
            >{ face === "back" ? "Back" : "Discard" }</Button>
            { face === "back" &&
              <Button
                type="secondary"
                onClick={ coverId ? removeBackCover : () => navigateNext({}) }
                className="mr-2 mb-2 md:mb-0"
              >{ coverId ? "Remove" : "Skip" }</Button>
            }
          </>
        }
        <Button
          type="primary"
          onClick={ save }
          active={ isSaving }
          disabled={ !hasUnsavedChanges && !hasCoverIdChanged && face !== "front"}
        >{ face === "front" ? "Next" : "Save" }</Button>
      </div>
    </div>

    { showOnboarding && <div className="p-4 my-4 space-y-4 bg-gray-50">
      <div className="flex flex-row justify-between">
        <h3 className="text-lg font-bold">Important Tip!
        </h3>
        <button
          className="text-gray-500 hover:text-gray-700"
          onClick={ () => hideOnboarding() }
        ><XMarkIcon className="w-6 h-6" /></button>
      </div>

      <div className="space-y-4">
        <p>
        The red margin signifies the area which may not appear in your final print.
        If you wish to have your cover printed edge-to-edge,
        you must fill the entire design including the red margin, or your
        book will have an irregular white border. But, don’t include anything important, like
        text, in the red area.
        </p>
        <p>
          Learn more and see examples on our <a
          target="_blank"
          href="https://help.sendheirloom.com/article/12-custom-covers"
          className="underline text-pink-300 hover:text-pink-500"
          title="Open the custom cover help document">help site</a>.
        </p>
      </div>
    </div> }

    { editor }
  </>
}
