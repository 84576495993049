import React, { useState } from 'react'
import { CircularProgress } from '@mui/material'

import BigOptions from './BigOptions'
import ButtonLink from './ButtonLink'
import Button from './Button'
import { useMutation } from '@apollo/client'
import { useAuthenticatedQuery } from '../apollo-client'
import { OrderDocument, OrderQuery, OrderQueryVariables, ReplaceBooksMutation, ReplaceBooksMutationVariables, ReplaceBooksDocument } from '../graphql/__generated__'
import { UnknownOrder } from './IssueOrderChooser'
import type { DestQtyMap } from './OrderIssueSelectBooks'
import OrderIssueSelectBooks from './OrderIssueSelectBooks'

type OrderIssueReplaceBookProps = {
  requireChanges: boolean,
  orderId: string,
  supportNote: string,
  onFail: () => void,
}

export default function OrderIssueReplaceBook({ requireChanges, orderId, supportNote, onFail }: OrderIssueReplaceBookProps) {
  const [doReplacement, setDoReplacement] = useState<boolean>(false)

  const { data, loading, error } = useAuthenticatedQuery<OrderQuery, OrderQueryVariables>(OrderDocument, {
    variables: { orderId },
  })

  const [replaceBooks, {
    data: replaceResp,
    loading: replaceLoading,
    error: replaceError }] = useMutation<ReplaceBooksMutation, ReplaceBooksMutationVariables>(ReplaceBooksDocument)

  if (orderId === UnknownOrder) {
    throw new Error("Unknown order used in replace book flow")
  }
  if (loading) {
    return <div className="my-4 flex justify-center"><CircularProgress size="6em" /></div>
  }
  if (error || !data) {
    return <p className="bg-red-100 p-2 my-2">Error loading order, please try again.</p>
  }

  const order = data.orderStatus

  if (!doReplacement) {
    return <div className="space-y-4 pb-12">
      <p>We may be able to replace your book(s), would you like us to do that?</p>

      <BigOptions options={ [
        {
          title: "Yes, please send replacement books",
          subtitle: "You may change the cover, content, or shipping address, of your new book before it is shipped.",
          onClick: () => setDoReplacement(true),
        },
        {
          title: "No, I would like more help",
          subtitle: "I would like to speak to a member of our team to discuss my issue further.",
          onClick: () => onFail(),
        },
      ] } />
    </div>
  }


  function doReplace(destQtyMap: DestQtyMap) {
    replaceBooks({
      variables: {
        orderId: order.id,
        destinationIds: Object.keys(destQtyMap),
        quantities: Object.values(destQtyMap),
        supportNote,
      },
    })
  }

  let totalQty = 0
  order.designs?.forEach(design => {
    design.destinations?.forEach(destination => {
      totalQty += destination.quantity
    })
  })

  if (totalQty <= 0) {
    return <p className="bg-red-100 p-2 my-2">Error loading order, it appears to have no books.</p>
  }

  if (replaceLoading) {
    return <div className="flex flex-col items-center space-y-4 my-4 pb-12">
      <div className="mb-4">Requesting replacement...</div>

      <CircularProgress size="6em" />
    </div>
  }

  let errMessage = <></>
  if (replaceError) {
    errMessage = <p className="bg-red-100 p-2 my-2">Error requesting replacement, please try again.</p>
  }

  if (replaceResp && replaceResp.replaceBooks) {
    const newOrder = replaceResp.replaceBooks

    return <div className="space-y-4">
      <p>You will now be taken to our ordering site where you can make modifications to your
         replacement books before they are shipped out. When you are finished,
         submit your replacement order on that site and you're done!</p>

      <div className="flex justify-end">
        <ButtonLink to={ `/order/${ newOrder.id }?replacement=true` }>Continue</ButtonLink>
      </div>
    </div>
  }

  if (totalQty > 1) {
    return <div className="space-y-2">
      { errMessage }

      <p>
        Please select the book which had the issue, and the quantity which must be replaced.
        You may repeat the process multiple times to replace books sent to multiple addresses.
      </p>
      <p>
        You will be brought to the order editor to make any changes and complete the replacement.
      </p>

      <OrderIssueSelectBooks order={ order } onSelect={ doReplace } />
    </div>
  } else if (totalQty === 1 && order.designs?.[0]?.destinations?.[0]) {
    return <div className="space-y-2">
      { errMessage }

      <p>
        You will be brought to the order editor to make any changes and complete the replacement.
      </p>
      <div className="flex justify-end">
        <Button onClick={ () => {
          let toReplace: DestQtyMap = {}
          // @ts-ignore It misses the test in the if statement above
          toReplace[order.designs[0].destinations[0].id] = 1
          doReplace(toReplace)
        }}>Replace Book</Button>
      </div>
    </div>
  } else {
    return <p className="bg-red-100 p-2 my-2">Error loading order, it appears to have no books.</p>
  }
}
