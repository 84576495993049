import type { StockFragment, StockEntry, Maybe } from '../graphql/__generated__'

type CoverF = StockFragment["books"][0]["covers"][0]

export function getStockCover(stock: StockFragment, id: string): Maybe<CoverF> {
  const covers = stock.books[0].covers
  const cover = covers.find(cover => cover.id === id)
  return cover || null
}

export function isCustomCover(coverId: string): boolean {
  return coverId !== "" && coverId.indexOf("cover-") !== 0
}
