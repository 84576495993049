import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import MessageForm from '../components/MessageForm'
import DestComponentEditor from '../components/DestComponentEditor'
import useSteps, { BuilderFlow, GiftFlow, EGiftFlow, MessageStep, GiftMessageStep, EGiftMessageStep } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import { CircularProgress } from '@mui/material'
import { useRedirectWithDesignDestination } from '../utils/RedirectWithDesignDestination'

interface MessageEditorProps {
  isEGift?: boolean
  isGift?: boolean
}

export default function MessageEditor({ isEGift, isGift }: MessageEditorProps) {
  const { orderId, designId, destinationId } = useParams()

  const step = (() => {
    if (isEGift) {
      return EGiftMessageStep
    }
    if (isGift) {
      return GiftMessageStep
    }
    return MessageStep
  })()

  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow: (() => {
        if (isEGift) {
          return EGiftFlow
        }
        if (isGift) {
          return GiftFlow
        }
        return BuilderFlow
      })(),
      step,
      args: { orderId, destinationId, designId },
    })
  }, [isEGift, isGift, orderId, designId, destinationId])

  const { navigateNext, navigatePrev, currentPath, currentStep, hasReturn } = useSteps()

  const { loading } = useRedirectWithDesignDestination({
    orderId, designId, destinationId,
    skip: currentStep !== step,
    currentPath,
  })

  if (!orderId) {
    return <>Missing order id</>
  }

  if (loading) {
    return <div className="flex my-4 justify-center">
      <CircularProgress />
    </div>
  }

  return <>
    <DestComponentEditor
      form={ MessageForm }
      formOptions={{ showPreview: !isEGift }}
      text={{
        pageTitle: 'Message',
        componentName: 'Message',
        addMoreHint: 'Want to send multiple books with different messages?',
      }}
      showSkip={ true }
      orderId={ orderId }
      destinationId={ destinationId }
      designId={ designId }
      hasReturn={ hasReturn }
      onDone={ () => {
        navigateNext({})
      }}
      onBack={ () => {
        hasReturn ? navigateNext({}) : navigatePrev({})
      }}
    />
  </>
}
