import React, { useState, useEffect } from 'react'
import SliderUnstyled, { SliderThumb } from '@mui/material/Slider'
import { styled } from '@mui/material/styles'

import type { ClipFragment } from '../graphql/__generated__'
import { formatDuration } from '../utils/Duration'

export type MediaEditorTimelineClipsProps = {
  clips: ClipFragment[]
  onChange: (clips: ClipFragment[]) => void
  previewChange: (timeMs: number) => void
  cancelPreview: () => void
  durationMs: number
}

export default function MediaEditorTimelineClips({ durationMs, clips, onChange, previewChange, cancelPreview }: MediaEditorTimelineClipsProps) {
  const [bounds, setBounds] = useState<number[]>([])

  // Map the clip format to the array expected by the slider
  useEffect(() => {
    let bounds = []

    if (clips.length) {
      for (let i = 0; i < clips.length; i++) {
        bounds.push(Math.round(clips[i].startMs))
        bounds.push(Math.round(clips[i].endMs))
      }
    } else {
      bounds.push(0)
      bounds.push(durationMs)
    }

    setBounds(bounds)
  }, [clips])

  const handleChange = (event: Event, newValue: number | number[], sliderIndex: number) => {
    if (typeof newValue === 'number') {
      throw new Error('Expected an array')
    }

    // Don't allow clips to overlap
    let last = 0
    for (let i = 0; i < newValue.length; i++) {
      const v = newValue[i]
      if (v < last) {
        newValue[i] = last
      }
      last = v
    }
    setBounds(newValue)

    // Map the slider values to the clip format
    const clips = []
    for (let i = 0; i < newValue.length; i += 2) {
      clips.push({
        startMs: Math.round(newValue[i]),
        endMs: Math.round(newValue[i + 1]),
        rotation: 0,
      })
    }
    onChange(clips)

    previewChange(newValue[sliderIndex])
  }

  return <div className="absolute top-0 left-0 w-full h-6 flex items-center pointer-events-none">
    <TimelineSlider
      value={ bounds }
      onChange={ handleChange }
      onChangeCommitted={ cancelPreview }
      components={{ Thumb: MediaEditorTimelineClipsThumb }}
      valueLabelFormat={ (v: number) => formatDuration(v / 1000) }
      valueLabelDisplay="auto"
      step={ 33 }
      min={ 0 }
      max={ durationMs }
    />
  </div>
}

const TimelineSlider = styled(SliderUnstyled)(({ theme }) => ({
  pointerEvents: 'none',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 6,
    pointerEvents: 'auto',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    opacity: 1,
    borderRadius: 0,
    borderStyle: 'solid',
    /* We use the before and after elements to make our :focus box shadow look reasonable.
     * If we instead just used a border on three sizes, the box shadow would be too big.
     * */
    '&:after': {
      position: 'absolute',
      borderTopWidth: 6,
      borderRadius: 0,
      width: 12,
      top: 3,
      height: 0,
    },
    '&:before': {
      position: 'absolute',
      borderBottomWidth: 6,
      borderRadius: 0,
      width: 12,
      height: 0,
      bottom: 0,
    },
    '&:after, &:before': {
      boxShadow: 'none',
    },
    '&:focus, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
    },
    '&.left-thumb': {
      borderLeftWidth: 6,
      marginLeft: -6,
      '&, &:after, &:before': {
        borderColor: 'rgb(187 247 208)',
      }
    },
    '&.right-thumb': {
      borderRightWidth: 6,
      marginLeft: 6,
      '&, &:after, &:before': {
        borderColor: 'rgb(254 202 202)',
      },
    },
  },
  '& .MuiSlider-track': {
    display: 'none',
  },
  '& .MuiSlider-rail': {
    display: 'none',
  },
}));

function MediaEditorTimelineClipsThumb(props: any) {
  const { children, ...other } = props;
  other.className += ' ' + (other['data-index'] % 2 === 0 ? 'left-thumb' : 'right-thumb')
  return (
    <SliderThumb { ...other }>
      { children }
    </SliderThumb>
  );
}
