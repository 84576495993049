import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'

import ButtonLink from '../components/ButtonLink'
import { useStepContext } from '../utils/StepContext'
import { DesignDocument } from '../graphql/__generated__'
import type { RenderFragment } from '../graphql/__generated__'
import useSteps, { LocalFlow, AssembleStep } from '../utils/Steps'
import RenderProgress from '../components/RenderProgress'
import { useAuthenticatedQuery } from '../apollo-client'

export default function AssembleBook() {
  const { designId } = useParams()

  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow: LocalFlow,
      step: AssembleStep,
      args: { designId },
    })
  }, [designId])

  const { nextPath } = useSteps()

  const { data, loading, error } = useAuthenticatedQuery(DesignDocument, {
    variables: {
      designId,
    },
    pollInterval: 2_000,
  })
  const design = data?.design

  if (!designId) {
    return <>Missing design id.</>
  }

  const render = design?.renders?.find((render: RenderFragment) =>
    // TODO We should force this to be square when we do the render, but maybe having no cover already will?
    render.formFactor === 'square' && render.task === 'normalize'
  )
  let orientation = "Horizontal"
  if (render?.encodedConfig.vertical) {
    orientation = "Vertical"
  }

  // If design.manualOrientation is set, that means that the book has already been assembled.
  return <>
    <h1 className="text-2xl">Assemble Your Book</h1>

    { loading && <div className="flex justify-center my-4"><CircularProgress /></div> }
    { error && <p className="p-2 my-2 bg-red-300">Error loading design.</p> }

    { design && <>
      { !design.manualOrientation ? <>
        <p className="mt-2">Your book was delivered unattached to allow us to choose the ideal orientation
        for your screen based on your videos and photos. It's time to attach your screen to
        your book's cover.</p>

        { render && render.signedURL ? <>
          <h2 className="my-8 text-xl text-center">Your Orientation is <span className="font-bold">{ orientation }</span></h2>

          <h2 className="mt-2 text-xl">Attaching Your Cover</h2>

          <div className="pl-4">
            <h3 className="text-lg mt-4">1. Test fit</h3>
            <p className="mt-2 pl-4 text-sm">Test placing the book onto the cover to learn how it must fit. It's critical that your
            book is aligned to ensure the cover will close properly. You may test the cover now to ensure
            you understand how the magnets must align before the next step. If the book doesn't properly stay
            closed, it's not aligned.
            </p>

            <h3 className="text-lg mt-4">2. Expose the double-stick tape from the back of your screen</h3>
            <p className="mt-2 pl-4 text-sm">Peal the protective covering off the double-stick tape on the back of your video book's screen.</p>

            <h3 className="text-lg mt-4">3. Carefully place the book onto the cover</h3>
            <p className="mt-2 pl-4 text-sm">Ensure the spacing is equal on the right, top, and bottom edges before pressing the book down. The charging port should be on the bottom or right side.</p>

            <h3 className="text-lg mt-4">4. Press firmly on the edges of the book</h3>
            <p className="mt-2 pl-4 text-sm">Pressing firmly will ensure the book is permanently attached to the cover. DO NOT press on the screen,
            it can be damaged.</p>
          </div>
        </> : <div className="mt-4">
          <RenderProgress design={ design } />
        </div>}
      </> : <>
        <p className="mt-4">Your book is already assembled! You may continue to the next step.</p>
      </> }

      { (render || design.manualOrientation) &&
        <div className="flex justify-end">
          <ButtonLink
            to={ nextPath({}) }
            className="mt-12"
            type="primary">Next</ButtonLink>
        </div>
      }
    </> }
  </>
}
