import React from 'react'
import { CircularProgress } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useAuthenticatedQuery } from '../apollo-client'
import { useMutation } from '@apollo/client'

import { formatDate } from '../utils/Date'
import { formatBooks } from './PreorderRefundListing'
import { BalanceEntriesDocument, RefundPreorderDocument } from '../graphql/__generated__'
import type { BalanceEntriesQuery, BalanceEntriesQueryVariables, BalanceEntryProduct } from '../graphql/__generated__'
import ButtonLink from '../components/ButtonLink'
import Button from '../components/Button'

// These vendors require customers to contact them to get a refund
export const noRefundVendors: Array<string> = ['QVC', 'CBS']

export default function PreorderRefund() {
  const { balanceEntryId } = useParams()

  const { data, loading, error } = useAuthenticatedQuery<BalanceEntriesQuery, BalanceEntriesQueryVariables>(BalanceEntriesDocument)

  const [doRefund, { error: errorRefunding, loading: refunding, data: done }] = useMutation(RefundPreorderDocument, {
    variables: {
      balanceEntryId,
    },
  })

  if (loading) {
    return <div className="my-4 flex justify-center"><CircularProgress size="6em" /></div>
  }
  if (error || !data) {
    return <p className="text-red-500 p-1">Error loading credits, please try again.</p>
  }

  const { balanceEntries } = data

  const entry = balanceEntries.find((be) => be.id === balanceEntryId)
  if (!entry) {
    return <p className="text-red-500 p-1">Balance Entry not found to refund.</p>
  }

  let bookEntry: BalanceEntryProduct = {
    originalQuantity: 0,
    quantityRemaining: 0,
    productId: "",
  }
      
  for (let j = 0; j < entry.products.length; j++) {
    const product = entry.products[j]
    // We just combine all book lengths for now
    if (product?.productId?.startsWith('book_')){
      bookEntry.originalQuantity += product.originalQuantity
      bookEntry.quantityRemaining += product.quantityRemaining
    }
  }

  if (done) {
    return <div className="flex flex-col my-4 mx-auto p-8 space-y-4 max-w-xl">
      <h2 className="text-lg">Refund Completed</h2>
      <p className="max-w-lg text-justify">Your refund has been processed. You will receive an email confirmation shortly.</p>
      <div className="flex justify-center space-x-4">
        <ButtonLink
          type="secondary"
          to="/help/preorder/refund"
        >Back</ButtonLink>
      </div>
    </div>
  }

  let allowRefund = true
  let refundBody = <>
    <p className="max-w-lg text-justify">After your refund is processed these credits will be permanently removed from your
    account. It may not be possible to purchase replacement credits at the discounted rate
    you originally received.</p>
  </>

 if (errorRefunding) {
    refundBody = <div className="flex flex-col my-4 mx-2 p-8 bg-red-100 space-y-2 max-w-xl">
      <p>There was an error issuing your refund. Please try again or contact us for assistance.</p>
    </div>
  } else if (noRefundVendors.includes(entry.vendorDisplayName)) {
    allowRefund = false
    refundBody = <div className="flex flex-col my-4 mx-2 p-8 bg-red-100 space-y-2 max-w-xl">
      <p>Unfortunately { entry.vendorDisplayName } does not permit us to issue refunds directly.
      Please contact them to request a refund.</p>
    </div>
  } else if (bookEntry.quantityRemaining < bookEntry.originalQuantity) {
    allowRefund = false
    refundBody = <div className="flex flex-col my-4 mx-2 p-8 bg-red-100 space-y-2 max-w-xl">
      <p>Unfortunately, you have already customized at least one book in this order and we cannot
      issue an automated refund.</p>
      <p>If you would like to request a refund or replacement for a book
      you have received please use our Issues form.</p>
    </div>
  } else if (entry.refundCompletedAt) {
    allowRefund = false
    refundBody = <div className="flex flex-col my-4 mx-2 p-8 bg-red-100 space-y-2 max-w-xl">
      <p>You have already received a refund for this order. Please allow 5-10 days, and contact us with any questions.</p>
    </div>
  } else if (entry.refundRequestedAt) {
    allowRefund = false
    refundBody = <div className="flex flex-col my-4 mx-2 p-8 bg-red-100 space-y-2 max-w-xl">
      <p>You have already requested a refund for this order. Please allow 5-10 days, and contact us with any questions.</p>
    </div>
  }

  return <div className="space-y-8 flex flex-col justify-center items-center">
    <div className="space-y-4 p-4 md:p-0">
      <h1 className="text-2xl font-bold">Confirm your Refund</h1>

      <p>You are requesting a refund for the following purchase:</p>
    </div>

    <div className="flex flex-col p-8 space-y-2 bg-gray-50">
      <div className="flex-1">
        <p className="font-bold">{ entry.vendorDisplayName } Order</p>
        <p>
          <time dateTime={ entry.createdAt }>{ formatDate(entry.createdAt) }</time>
        </p>
      </div>
      <div className="flex flex-col py-1">
        <span className="font-bold">Purchased</span>
        <span>{ formatBooks(bookEntry.originalQuantity) }</span>
      </div>
      <div className="flex flex-col py-1">
        <span className="font-bold">Remaining</span>
        <span>{ formatBooks(bookEntry.quantityRemaining) }</span>
      </div>
      { entry.amountPaidInCents &&
        <div className="flex flex-col py-1">
          <span className="font-bold">Amount</span>
          <span>${ (entry.amountPaidInCents / 100).toFixed(2) }</span>
        </div>
      }
    </div>

    { refundBody }

    <div className="flex justify-center space-x-4">
      <ButtonLink
        type="secondary"
        to="/help/preorder/refund"
      >Back</ButtonLink>

      { allowRefund &&
        <Button
          type="primary"
          disabled={ bookEntry.quantityRemaining !== bookEntry.originalQuantity }
          title={ bookEntry.quantityRemaining !== bookEntry.originalQuantity ? "This credit has been partially or fully used, and can no longer be refunded." : "" }
          active={ refunding }
          onClick={ () => { doRefund() } }
          >Confirm</Button>
      }
    </div>
  </div>
}
