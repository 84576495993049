import React from 'react'
import { messageAsHTML } from '../deps/loom-client/src/Labels/Message'

type GiftMessageProps = {
  message: string | undefined
}

export default function GiftMessage({ message }: GiftMessageProps) {
  if (!message) {
    return <p className="italic">No message</p>
  }

  return <div
    className="text-sm space-y-1"
    dangerouslySetInnerHTML={{ __html: messageAsHTML(message) }} />
}
