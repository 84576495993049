import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BigOptions from '../components/BigOptions'
import type { BigOption } from '../components/BigOptions'

export default function ReportIssue() {
  const navigate = useNavigate()

  const options: Array<BigOption> = [{
    title: "Damaged or Incorrect Book",
    subtitle: "The book you have received is not working properly or does not contain the media you were hoping for.",
    onClick: () => onSelectType('order'),
  }, {
    title: "Issues Loading Book",
    subtitle: "You are struggling to load your videos and photos onto a book you already have.",
    onClick: () => onSelectType('loading'),
  }, {
    title: "Issues Using Website",
    subtitle: "You are experiencing problem with the book building process on our website.",
    onClick: () => onSelectType('website'),
  }, {
    title: "Other",
    subtitle: "You have an issue not covered by the listed options.",
    onClick: () => onSelectType('other'),
  }]

  const onSelectType = (type: string) => {
    if (type === 'order') {
      navigate('/help/order/issue/report')
    } else if (type === 'other') {
      navigate('/help/contact')
    } else if (type === 'loading') {
      document.location = 'https://help.sendheirloom.com/category/4-adding-photos-videos'
    } else {
      document.location = 'https://help.sendheirloom.com/category/16-ordering'
    }
  }

  return <div className="space-y-4">
    <h2 className="text-lg">Report Issue</h2>
    <p>This tool can help you report an issue with our website or a book you received. Please select
    the option that best describes your issue:</p>
    <BigOptions options={ options } />

    <p className="text-center text-sm pt-8">Hoping to get a refund for an order started on
      our website which you haven't completed?

      Visit our <a
        className="underline cursor-pointer"
        onClick={ () => navigate("/help/preorder/refund") }>
          preorder refund tool
        </a>
      .</p>
  </div>
}
