import React from 'react'
import { ClockIcon } from '@heroicons/react/24/outline'
import { DesignValidation, MAXIMUM_DURATION_USER_FRIENDLY } from '../utils/OrderValidation'
import { classNames } from '../utils/classes'

type TooLongWarningProps = {
  designValidation?: DesignValidation
  onEditMedia?: () => void
}

export default function({ designValidation, onEditMedia }: TooLongWarningProps) {
  const { designDurationExceedsMaximum, designDurationExceedBalancesUsed } = designValidation ?? {}
  if (!designDurationExceedsMaximum && !designDurationExceedBalancesUsed) {
    return null
  }

  const maxMinutes = MAXIMUM_DURATION_USER_FRIENDLY / 60

  return (
    <div className={ classNames(
      'p-2 pl-4 mt-4 flex rounded items-center',
      designDurationExceedsMaximum ? 'bg-red-100' : 'bg-yellow-100',
    ) }>
      <ClockIcon className="h-16 w-16 text-black mr-6" />

      <div className="flex-1 space-y-4">
        { designDurationExceedsMaximum ? <>
          <p>
            Your photos and videos exceed the maximum length of { maxMinutes } minutes.
          </p>
          <p>
            { onEditMedia
            ? <>You need to go back to the <a onClick={ onEditMedia } className="text-blue-500 cursor-pointer">Media</a> step and remove photos or videos to complete your order.</>
            : <>To complete your order, please remove photos or videos until your book is shorter than { maxMinutes } minutes.</>
            }
          </p>
        </> : <>
          <p>
            Your photos and videos exceed the length of your prepaid book. Your order will automatically include an additional upgrade charge for additional minutes.
          </p>
          <p>
            { onEditMedia
            ? <>You may go back to the <a onClick={ onEditMedia } className="text-blue-500 cursor-pointer">Media</a> step and remove photos or videos to avoid this message goes away.</>
            : <>To avoid this charge, please remove photos or videos.</>
            }
          </p>
        </> }
      </div>
    </div>
  )
}
