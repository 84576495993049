import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom'

import ButtonLink from '../components/ButtonLink'
import Button from '../components/Button'
import DesignChooser from '../components/DesignChooser'
import { useStepContext } from '../utils/StepContext'
import useSteps, { LocalFlow, LocalWelcomeStep } from '../utils/Steps'
import { DesignFragment, DesignsQuery, DesignsQueryVariables, DesignsDocument, DesignFilter } from '../graphql/__generated__'
import { useAuthenticatedQuery } from '../apollo-client'

export default function LocalWelcome(){
  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow: LocalFlow,
      step: LocalWelcomeStep,
      args: {},
    })
  }, [])

  const { nextPath } = useSteps()
  const navigate = useNavigate()

  const { loading, error, data } = useAuthenticatedQuery<DesignsQuery, DesignsQueryVariables>(DesignsDocument, {
    variables: {
      filter: DesignFilter.Local,
    }
  })

  const [ignoredWarning, setIgnoredWarning] = useState<boolean>(false)
  const [showDesignChooser, setShowDesignChooser] = useState<boolean>(false)

  const existingDesigns = data?.designs

  if (showDesignChooser) {
    return <>
      <h1 className="text-2xl font-bold mb-4">Choose a design to continue editing</h1>
      <DesignChooser
        filter={ DesignFilter.Local }
        onChange={ (designId: string) => {
          navigate(`/local/design/${ designId }/media`)
        } }
      />
    </>
  }

  return <>
    <h1 className="text-xl">Load your Heirloom Video Book</h1>
    <p className="mt-2">We appreciate your video book purchase, and are thrilled to help you get
      your book loaded. This page will help you load a video book you already have, if you
      don't have a book in-hand <a href="/" className="underline cursor-pointer">start here instead</a>.</p>

    { loading && <div className="flex justify-center mt-12 mb-4"><CircularProgress size="6em" /></div> }
    { error && <div className="p-2 my-2 bg-red-300">Error loading existing designs.</div> }
    { !loading && !error && <>
      <p className="mt-8">There are three steps to loading your book:</p>
      <ol className="list-decimal mt-2 ml-8 text-lg">
        <li>Select your videos and photos.</li>
        <li>Download your combined video.</li>
        <li>Load your combined video onto your video book.</li>
      </ol>

      <p className="mt-8">
        If you run into any trouble at all, please don't hesitate to <a href="/help" className="underline">Contact Us</a>.
      </p>


      <div className="flex justify-center">
        { isMobile && !ignoredWarning &&
          <div className="mt-4 p-4 bg-red-100 space-y-4 flex">
            It is not possible to connect your book to a phone or tablet. We recommend you complete
            this process on your computer.

            <Button
              type="primary"
              className="block"
              onClick={ () => setIgnoredWarning(true) }
              >Ignore</Button>
          </div>
        }

        { (!isMobile || ignoredWarning) && <div className="flex space-x-4 mt-8">

            { !!existingDesigns?.length &&
              <Button
                type="primary"
                onClick={ () => setShowDesignChooser(true) }
              >Continue Previous Design</Button>
            }

            <ButtonLink
              type={ existingDesigns?.length ? "secondary" : "primary" }
              to={ nextPath({}) }>
              { existingDesigns?.length ? "Start New Design" : "Get Started" }
            </ButtonLink>
          </div>
        }
      </div>
    </>}
  </>
}
