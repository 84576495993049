import React, { useRef, useEffect, useState } from 'react'
import type { SyntheticEvent } from 'react'
import Hls from 'hls.js'
import { FireIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import { PreviewDocument } from '../graphql/__generated__'

import Loader from './Loader'
import Button from './Button'
import { useAuthenticatedQuery } from '../apollo-client'

export type PreviewProps = {
  designId: string
  generation: string
}

export default function Preview({ designId, generation }: PreviewProps) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [playbackError, setPlaybackError] = useState<string | undefined>(undefined)
  const [renderingError, setRenderingError] = useState<string | undefined>(undefined)

  const { data, loading, error, refetch } = useAuthenticatedQuery(PreviewDocument, {
    variables: {
      designId,
    },
  })

  useEffect(() => {
    refetch()
  }, [generation])

  useEffect(() => {
    if (!data?.designPreview?.playlistURL || !videoRef.current){
      return
    }

    const url = data.designPreview.playlistURL
    const video = videoRef.current

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.attachMedia(video);
      hls.loadSource(url);
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = url;
    }
  }, [videoRef, data?.designPreview?.playlistURL])

  let timer: ReturnType<typeof setInterval> | undefined
  const onError = (e: SyntheticEvent) => {
    console.error("Error loading preview", e)
    setPlaybackError(e.toString())
    timer && clearInterval(timer)
  }
  const didLoad = () => {
    timer && clearInterval(timer)
  }
  const retry = () => {
    setRenderingError(undefined)
    setPlaybackError(undefined)
    refetch()
  }

  useEffect(() => {
    timer = setInterval(async () => {
      // Every few seconds we download the playlist ourselves to see if it contains an error
      // which would mean the video isn't going to load.
      if (!data?.designPreview?.playlistURL) {
        return
      }

      const resp = await fetch(data.designPreview.playlistURL)
      const body = await resp.text()

      // determine if body contains an error
      if (body.includes("ERROR")) {
        console.error("Error rendering", body)
        setRenderingError("Error rendering")
        timer && clearInterval(timer)
      }
    }, 5000)
    return () => timer && clearInterval(timer)
  }, [videoRef, data?.designPreview?.playlistURL])

  if (loading) {
    return <Loader description="Generating Preview..." />
  }

  let isOverCapacity = false
  if (error && error.message.includes("no capacity")) {
    isOverCapacity = true
  }

  // We've disabled playbackError, as it triggers all the time on mobile during loading
  if (error || renderingError) {
    return <div className="my-2 p-4 space-y-4 bg-red-100 m-auto text-center">
      { isOverCapacity
        ? <>
            <FireIcon className="w-12 h-12 mx-auto text-red-500" />
            <div className="font-bold">Our preview system is currently over capacity.</div>
          </>
        : <>
            <ExclamationTriangleIcon className="w-12 h-12 mx-auto text-red-500" />
            <div className="font-bold">Error generating preview, please try again.</div>
          </>
      }

      { !loading && <Button
        className="block mt-2 mx-auto"
        onClick={ () => { retry() } }
      >Retry</Button> }

      { isOverCapacity
        ? <div>We're very sorry for the inconvenience! Please try again in a few minutes.</div>
        : <div>Please be sure your design does not contain any files which can't be rendered, like .zip or .pdf.</div>
      }
    </div>
  }

  if (!data) {
    return <></>
  }

  return <div className="relative bg-gray m-auto">
    <video ref={ videoRef } controls autoPlay
      onError={ onError }
      onCanPlay={ didLoad }
      style={{ width: 'min(427px, 100%)' }}
      className="m-auto max-h-[427px] rounded-lg">
         <source type="application/x-mpegURL" />
     </video>
  </div>
}
