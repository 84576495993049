import React, { ReactNode, useState } from 'react'
import { StepContext } from '../utils/StepContext'
import { Step, StepArgs } from '../utils/Steps'

export type StepContextProviderProps = {
  children: ReactNode
}

export default function StepContextProvider({ children }: StepContextProviderProps) {
  const [
    { flow, step, args } = { flow: null, step: null, args: null }
    , setStepState
  ] = useState<{
    flow: Step[] | null,
    step: Step | null,
    args: StepArgs | null,
  } | undefined>()

  return (
    <StepContext.Provider value={{
      flow,
      step,
      args,
      setStepState,
    }}>
      {children}
    </StepContext.Provider>
  );
}