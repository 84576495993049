import React from 'react'
import type { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import type { ButtonProps } from './Button'
import Button from './Button'

type ButtonLinkProps = ButtonProps & {
  to: string
}

export default function ButtonLink({ to, children, ...buttonProps }: ButtonLinkProps) {
  return (
    <Link to={ to }>
      <Button {...buttonProps}>
        { children }
      </Button>
    </Link>
  )
}
