import React from 'react'
import { CircularProgress } from '@mui/material'
import { useAuthenticatedQuery } from '../apollo-client'

import { formatDate } from '../utils/Date'
import { pluralize } from '../components/OrderSummaryTile'
import { BalanceEntriesDocument } from '../graphql/__generated__'
import type { BalanceEntriesQuery, BalanceEntriesQueryVariables, BalanceEntryProduct, BalanceEntry } from '../graphql/__generated__'
import ButtonLink from '../components/ButtonLink'

type productEntry = BalanceEntryProduct & {
  balanceEntry: BalanceEntry
}

export default function PreorderRefundListing() {
  const { data, loading, error } = useAuthenticatedQuery<BalanceEntriesQuery, BalanceEntriesQueryVariables>(BalanceEntriesDocument)

  if (loading) {
    return <div className="my-4 flex justify-center"><CircularProgress size="6em" /></div>
  }
  if (error || !data) {
    return <p className="text-red-500 p-1">Error loading credits, please try again.</p>
  }

  const { balanceEntries } = data

  let bookEntries: Array<productEntry> = []
  for (let i = 0; i < balanceEntries.length; i++) {
    const entry = balanceEntries[i]
    if (!entry.products?.length) {
      continue
    }

    let bookEntry: productEntry = {
      balanceEntry: entry,
      originalQuantity: 0,
      quantityRemaining: 0,
      productId: "",
    }
      
    for (let j = 0; j < entry.products.length; j++) {
      const product = entry.products[j]
      // We just combine all book lengths for now
      if (product?.productId?.startsWith('book_')){
        bookEntry.originalQuantity += product.originalQuantity
        bookEntry.quantityRemaining += product.quantityRemaining
      }
    }

    bookEntries.push(bookEntry)
  }

  return <div className="space-y-8 flex flex-col justify-center items-center">
    <div className="space-y-4 p-4 md:p-0">
      <h1 className="text-2xl font-bold">Request a Refund</h1>
      <p>This page will allow you to request a refund for a purchase you made but have
      not yet customized. If you have already customized your book or received it, please
      visit the issues page to request a refund.</p>
    </div>

    <div className="space-y-4">
      <h2 className="text-xl font-bold">Purchases</h2>
      <ul>
        {bookEntries.map((entry) => {
          const refundDate = entry.balanceEntry.refundCompletedAt || entry.balanceEntry.refundRequestedAt

          return <li
            key={ entry.balanceEntry.id }
            className="flex mb-4 md:mb-2 p-4 md:p-0 bg-gray-50 md:bg-transparent">
            <div className="flex flex-col md:flex-row space-y-2 md:space-x-2 md:space-y-2 w-5/6 md:items-center">
              <div className="flex-1 pr-4">
                <p className="font-bold">{ entry.balanceEntry.vendorDisplayName } Order</p>
                <p>
                  <time dateTime={ entry.balanceEntry.createdAt }>{ formatDate(entry.balanceEntry.createdAt) }</time>
                </p>
              </div>
              <div className="flex flex-col py-1 md:p-4 md:bg-gray-50">
                <span className="font-bold">Purchased</span>
                <span className="text-sm">{ formatBooks(entry.originalQuantity) }</span>
              </div>
              <div className="flex flex-col py-1 md:p-4 md:bg-gray-50">
                <span className="font-bold">Unused</span>
                <span className="text-sm">{ formatBooks(entry.quantityRemaining) }</span>
              </div>
            </div>

            <div className="flex flex-col justify-center md:pl-12 md:pt-4">
              { refundDate
                ? <>
                  <span className="font-bold">Refunded At</span>
                  <p>
                    <time dateTime={ refundDate }>{ formatDate(refundDate) }</time>
                  </p>
                </>
                : <ButtonLink
                  type="primary"
                  to={ `/help/preorder/refund/${ entry.balanceEntry.id }` }
                  >Refund</ButtonLink>
              }
          </div>
        </li>
      })}
    </ul>
  </div>

  <p>If you are missing a purchase, it's possible you have not yet <a
    className="text-pink-500"
    href="/code/qvc">entered the code</a> into our system.
    It is not possible to request a refund for a purchase which has already been
    partially or fully used to build a video book. Please <a className="text-pink-500" href="/help">contact us
    </a> if you need more help.</p>
</div>
}

export function formatBooks(qty: number): string {
if (qty === 1) {
  return "1 book"
} else {
  return `${ qty } books`
}
}
