import React, { useMemo } from 'react'
import { DesignFragment, DestinationFragment, OrderFragment, StockFragment } from '../graphql/__generated__'

// MAXIMUM_DURATION_USER_FRIENDLY isn't the same as the product's actual duration.
// book_5in_60min allows you to go over 60min, but this value is still useful to
// show the user in a few places since books are priced in 10min intervals.
export const MAXIMUM_DURATION_USER_FRIENDLY = 60 * 60

export type DesignValidation = {
    blankWithoutMetadata: boolean
    missingDestinations: boolean
    hasProblems: boolean

    // intentionally excluded from hasProblems; simply a warning
    designDurationExceedsMaximum: boolean
    designDurationExceedBalancesUsed: boolean
}

export type DestinationValidation = {
    missingCover: boolean
    missingAddress: boolean
    hasProblems: boolean
}


const validateDesign = (
    design: DesignFragment,
    order: OrderFragment,
    destinations: DestinationFragment[],
    maxDuration: number,
): DesignValidation => {
    const blankWithoutMetadata = !order.isGift && !design.isBlank && design.media?.length === 0
    const missingDestinations = destinations.length === 0
    const designDurationExceedsMaximum = design.durationSeconds > maxDuration

    let designDurationExceedBalancesUsed = false
    const destinationIDs = destinations.map(d => d.id)
    const destinationCosts = order.cost?.byDestination.filter(cost => destinationIDs.includes(cost.destinationId)) ?? []
    for (const destinationCost of destinationCosts) {
        const balanceDurations = destinationCost.costs.balanceUsed.productBalanceUsed
            ?.map(({ product }) => product.durationSec)
            .filter((duration): duration is number => !!duration) ?? []
        for (const balanceDuration of balanceDurations) {
            if (balanceDuration < design.durationSeconds) {
                designDurationExceedBalancesUsed = true
                break
            }
        }
        if (designDurationExceedBalancesUsed) {
            break
        }
    }

    return {
        blankWithoutMetadata,
        missingDestinations,
        hasProblems: blankWithoutMetadata || missingDestinations,

        // intentionally excluded from hasProblems; simply a warning
        designDurationExceedsMaximum,
        designDurationExceedBalancesUsed,
    }
}

const validateAddress = (address: DestinationFragment["address"], isEGift: boolean) => {
    if (!address) {
        return false
    }

    if (isEGift) {
        return !!address.email?.trim()
    }

    const { street1, cityLocality, stateProvinceCode, postalCode, countryCode } = address
    return !!street1?.trim()
        && !!cityLocality?.trim()
        && !!stateProvinceCode?.trim()
        && !!postalCode?.trim()
        && !!countryCode?.trim()
}

const validateDestination = (destination: DestinationFragment, order: OrderFragment): DestinationValidation => {
    const missingCover = !destination.coverId && !order.isEGift
    const missingAddress = !validateAddress(destination.address, order.isEGift)

    return {
        missingCover,
        missingAddress,
        hasProblems: missingCover || missingAddress,
    }
}

// hasProblems will ultimately block checkout
const hasProblems = (designValidations: DesignValidation[], destinationValidations: DestinationValidation[]): boolean => {
    for (const designValidation of designValidations) {
        if (designValidation.hasProblems) {
            return true
        }
    }
    for (const destinationValidation of destinationValidations) {
        if (destinationValidation.hasProblems) {
            return true
        }
    }
    return false
}

export const useOrderValidation = (order: OrderFragment | undefined, stock: StockFragment | undefined) => {
    return useMemo(() => {
        const designValidations: { [designId: string]: DesignValidation } = {}
        const destinationValidations: { [destinationId: string]: DestinationValidation } = {}

        let maxDuration: number
        if (stock) {
            maxDuration = 0
            stock.books.forEach(s => s.durations.forEach(duration => {
                if (duration.ifLessThanDurationSec > maxDuration) {
                    maxDuration = duration.ifLessThanDurationSec
                }
            }))
        } else {
            maxDuration = MAXIMUM_DURATION_USER_FRIENDLY
        }

        order?.designs?.forEach(({ design, destinations }) => {
            if (design) {
                designValidations[design.id] = validateDesign(design, order, destinations ?? [], maxDuration)
            }

            destinations?.forEach(destination => {
                destinationValidations[destination.id] = validateDestination(destination, order)
            })
        })

        return {
            designValidations,
            destinationValidations,
            hasProblems: hasProblems(Object.values(designValidations), Object.values(destinationValidations)),
        }
    }, [order?.designs])
}