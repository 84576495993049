import React, { useState } from 'react'

import CoverImage from './CoverImage'
import AddressSummary from './AddressSummary'
import MediaStack from './MediaStack'
import Button from './Button'
import type { OrderFragment } from '../graphql/__generated__'
  
// An object mapping a string destination id to a number quantity
export type DestQtyMap = { [destinationId: string]: number }

type OrderIssueSelectBooksProps = {
  order: OrderFragment,
  onSelect: (books: DestQtyMap) => void,
}

export default function OrderIssueSelectBooks({ order, onSelect }: OrderIssueSelectBooksProps) {
  const [selected, setSelected] = useState<DestQtyMap>({})
  const [error, setError] = useState<string | null>(null)
  const updateQty = (destId: string, qty: number) => {
    setSelected({
      ...selected,
      [destId]: qty,
    })
  }

  const submit = () => {
    if (Object.keys(selected).length === 0) {
      setError("Please select at least one book to replace")
      return
    }

    onSelect(selected)
  }

  return <div>
    <h1 className="text-2xl font-bold">Select Books to be Replaced</h1>

    <ul className="my-4 space-y-8">
      { order.designs?.map((design, i) => {
        return <li key={ design.design?.id }>
          <MediaStack media={ design?.design?.media || null } />

          <ul className="space-y-4">
            { design.destinations?.map((destination) => {
              console.log(destination.quantity)
              return <li
                key={ destination.id }
                className="grid grid-cols-3 gap-4 items-center"
                >
                <CoverImage coverId={ destination.coverId || '' } />
                <AddressSummary address={ destination.address || null } isEGift={ false } />

                <label className="flex items-center mt-2">
                  <span className="font-bold text-sm mr-3">Quantity To Replace:</span>
                  <select onChange={ (e) => {
                    const qty = parseInt(e.target.value)
                    updateQty(destination.id, qty)
                  } }>
                    <option value="0">0</option>
                    { Array.from({ length: destination.quantity }, (_, i) => i).map((qty) => {
                      return <option key={ qty + 1 } value={ qty + 1 }>{ qty + 1}</option>
                    }) }
                  </select>
                </label>
              </li>
            }) }
          </ul>
        </li>
      }) }
    </ul>

    { error && <p className="bg-red-100 p-2 my-4"><span className="font-bold">Error:</span> { error }.</p> }

    <div className="flex justify-end">
      <Button
        type="primary"
        onClick={ () => submit() }>Submit</Button>
    </div>
  </div>
}
