import React from 'react'

import type { CustomerBalanceProductFragment, Maybe, OrderFragment } from '../graphql/__generated__'

type OrderBalanceUsageProps = {
  order: Maybe<OrderFragment>
}

export default function OrderBalanceUsage({ order }: OrderBalanceUsageProps) {
  const cost = order?.cost?.total

  const bookProducts = cost?.balanceUsed?.productBalanceUsed?.filter(({ product }) => product.type === 'player')
  const bookUsageElement = bookProducts?.length ? <>
    <p className="text-center text-gray-500" title={
      "Books purchased on " + bookProducts.map(({ balanceEntry }) => {
        const fromString = balanceEntry.vendorDisplayName ? `from ${ balanceEntry.vendorDisplayName }` : "as gift"
        return `${ new Date(balanceEntry.createdAt).toLocaleDateString() } ${ fromString }`
      }).join(', ')
    }>Using { bookProducts.reduce(
      (prevQuantity, { quantity }) => prevQuantity + quantity,
      0
    ) } of your prepurchased books.</p>
  </> : undefined

  const giftBoxProducts = cost?.balanceUsed?.productBalanceUsed?.filter(({ product }) => product.id === 'giftbox-5in')
  const giftBoxUsageElement = giftBoxProducts?.length ? <>
    <p className="text-center text-gray-500" title={
      "Gift boxes purchased on " + giftBoxProducts.map(({ balanceEntry }) => {
        const fromString = balanceEntry.vendorDisplayName ? `from ${ balanceEntry.vendorDisplayName }` : "as gift"
        return `${ new Date(balanceEntry.createdAt).toLocaleDateString() } ${ fromString }`
      }).join(', ')
    }>Using { giftBoxProducts.reduce(
      (prevQuantity, { quantity }) => prevQuantity + quantity,
      0
    ) } of your prepurchased gift boxes.</p>
  </> : undefined

  return <>
    { bookUsageElement }
    { giftBoxUsageElement }
  </>
}
