import React from 'react'
import type { FormEvent } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useMutation } from '@apollo/client'
import { CheckIcon } from '@heroicons/react/24/solid'

import { OrderFragment, UpdateOrderCustomerNoteDocument } from '../graphql/__generated__'

type NoteFieldProps = {
  order: OrderFragment
}

export default function NoteField({ order }: NoteFieldProps) {
  const [updateOrderCustomerNote, { loading, error, data }] = useMutation(UpdateOrderCustomerNoteDocument, {
    context: {
      debounceKey: 'customer-note',
      debounceTimeout: 1000,
    },
  })

  const saveSoon = (event: FormEvent) => {
    // @ts-ignore value doesn't always exist on an EventTarget
    const value = event.target.value

    if (!order?.id) {
      console.error("Missing order to update note")
      return
    }

    updateOrderCustomerNote({
      variables: {
        orderId: order.id,
        customerNote: value,
      },
    })
  }

  let noteValue = ""
  if (order?.notes){
    for (let i=0; i < order.notes.length; i++){
      // We actually only ever return this single note via the API, but we loop through to respect
      // the structure of the API and how it could be used in the future.
      if (order.notes[i].createdBy.name === 'customer') {
        noteValue = order.notes[i].content
        break
      }
    }
  }

  return <form className="space-y-2 my-4">
    <div>
      <h2 className="font-bold relative">
        Notes

        { loading && <CircularProgress size="1em" className="absolute top-0 right-0" /> }
        { data && <CheckIcon className="absolute top-0 right-0 h-4 w-4" /> }
      </h2>
      <p className="text-sm text-gray-500">
        Add any notes you'd like to share with our fulfillment team:
      </p>
    </div>

    <textarea
      onChange={ saveSoon }
      defaultValue={ noteValue }
      className="w-full"
    />
    { error && <p className="p-2 bg-red-100">Error saving note.</p>}
  </form>
}
