import React from 'react'
import type { Address, Maybe } from "../graphql/__generated__"

type AddressSummaryProps = {
  address: Maybe<Address>
  isEGift: boolean
}

export default function AddressSummary({ address, isEGift }: AddressSummaryProps) {
  if (!address) {
    return <></>
  }

  if (isEGift) {
    if (!address.email) {
      return <div className="italic">No email yet.</div>
    }

    return <div>{ address.email }</div>
  }

  if (!address.street1 && !address.cityLocality) {
    return <div className="italic">No address yet.</div>
  }

  return (
    <div>
      <div>{ address.fullName }</div>
      <div>{ address.street1 }</div>
      { address.street2 && <div>{ address.street2 }</div> }
      <div>{ address.cityLocality }{ address.cityLocality && ', ' }{ address.stateProvinceCode } { address.postalCode }</div>
      { address.countryCode !== 'US' && <div>{ address.countryCode }</div> }
    </div>
  )
}
