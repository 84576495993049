import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { OrdersQuery, RemoveOrderDocument } from '../graphql/__generated__'

export function useRemoveOrder(orders: OrdersQuery["customerOrders"]) {
  const navigate = useNavigate()

  // TODO: support onError argument if GQL mutation failures need to be handled

  const [removeOrder, { loading: removing }] = useMutation(RemoveOrderDocument)

  return {
    removing,
    removeOrder: (orderId: string, next?: string) => {
      if (window.confirm('Are you sure you want to delete this order? This action cannot be undone.')) {
        const deletingOrder = orders?.find(o => o.id === orderId)
        removeOrder({
          variables: { orderId },
          update(cache) {
            if (deletingOrder) {
              cache.evict({ id: cache.identify(deletingOrder) });
              cache.gc();
            }
          },
          onCompleted: () => {
            if (next) {
              navigate(next)
            }
          }
        })
      }
    },
  }
}
