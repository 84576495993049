import { createContext, Dispatch, SetStateAction, useContext } from "react"
import { Step, StepArgs } from "./Steps"

interface StepContextType {
    flow: Step[] | null
    step: Step | null
    args: StepArgs | null

    setStepState: Dispatch<SetStateAction<{
        flow: Step[] | null,
        step: Step | null,
        args: StepArgs | null,
    } | undefined>>
}

export const StepContext = createContext<StepContextType | null>(null)

export function useStepContext() {
    const ctx = useContext(StepContext)
    if (!ctx) {
        throw new Error("Must use StepContext within its Provider")
    }
    return ctx
}