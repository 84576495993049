import React, { useMemo } from 'react'
import { CircularProgress } from '@mui/material'
import { useAuthenticatedQuery, useAuthentication } from '../apollo-client'
import OrderSummaryListing from '../components/OrderSummaryListing'
import { OrdersDocument, OrdersQuery, OrdersQueryVariables, OrderState, OrderFilter } from '../graphql/__generated__'
import { useNavigate } from 'react-router-dom'

type OrdersProps = {
  drafts?: boolean
}

export default function Orders({ drafts }: OrdersProps) {
  const navigate = useNavigate()
  const { customerId } = useAuthentication()

  const {
    data: { customerOrders } = {},
    loading: orderLoading,
    error: orderError,
  } = useAuthenticatedQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, {
    skip: !customerId,
    variables: {
      customerId: customerId ?? '',
      drafts: drafts ? OrderFilter.OnlyDrafts : OrderFilter.NoDrafts,
    },
  })

  const orders = customerOrders

  return <div className="bg-gray-50 rounded mb-4 p-4">
    <h2 className="text-xl mb-2">{ drafts
      ? 'Resume a Draft Order'
      : 'Complete Orders'
    }</h2>

    { drafts && <p className="mb-6 text-sm">
      We are here to help you get your video book built! Click on the Help link above if you need assistance.
    </p> }

    { orderError && <p className="p-4 my-2 bg-red-200">
      There was an error fetching your orders, please try again.
    </p> }

    { orderLoading && <div className="text-center">
      <CircularProgress />
    </div> }

    { !orderLoading && !orders?.length && <p>
      { drafts ? <>
        No draft orders found;&nbsp;
        <a
          onClick={ () => navigate('/') }
          className="text-blue-500 cursor-pointer"
        >start a new one</a>?
      </> : <>
        No complete orders found;&nbsp;
        <a
          onClick={ () => navigate('/orders/draft') }
          className="text-blue-500 cursor-pointer"
        >view draft orders</a>?
      </> }
    </p> }

    <OrderSummaryListing orders={ orders } isDraft={ drafts } />
  </div>
}
