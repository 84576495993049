import React, { useState, useEffect } from 'react'

import type { MediaEntryFragment, ClipFragment } from '../graphql/__generated__'
import MediaEditorTimelineClips from './MediaEditorTimelineClips'
import MediaEditorTimelineProgress from './MediaEditorTimelineProgress'

export type MediaEditorTimelineProps = {
  durationMs: number
  clips: ClipFragment[]
  setClips: (clips: ClipFragment[]) => void
  previewChange: (timeMs: number) => void
  cancelPreviewChange: () => void
  currentTimeMs: number
  onSeek: (timeMs: number) => void
}

export default function MediaEditorTimeline({
  durationMs, currentTimeMs, onSeek, clips, setClips,
  previewChange, cancelPreviewChange }: MediaEditorTimelineProps) {
  if (!durationMs) {
    return <div className="bg-gray-200 p-2">Media length unknown.</div>
  }

  return <div>
    <div className="w-full h-full relative">
      <MediaEditorTimelineProgress
        onSeek={ onSeek }
        previewChange={ previewChange }
        cancelPreview={ cancelPreviewChange }
        currentTimeMs={ currentTimeMs }
        durationMs={ durationMs }
      />

      <MediaEditorTimelineClips
        clips={ clips }
        onChange={ setClips }
        previewChange={ previewChange }
        cancelPreview={ cancelPreviewChange }
        durationMs={ durationMs }
      />
    </div>
  </div>
}

