import type { OrderFragment, DestinationFragment } from '../graphql/__generated__'

export function findDestination(order: OrderFragment, destId: string): DestinationFragment | undefined {
  if (!!!order?.designs) {
    return undefined
  }

  for (let i=0; i < order.designs.length; i++){
    const design = order.designs[i]
    if (!!!design?.destinations) {
      continue
    }

    for (let j=0; j < design.destinations.length; j++){
      const dest = design.destinations[j]
      if (dest.id == destId) {
        return dest
      }
    }
  }

  return undefined
}
