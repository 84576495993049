import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { CircularProgress } from '@mui/material'
import { useMutation, ApolloError } from '@apollo/client'
import { XMarkIcon } from '@heroicons/react/24/outline'

import Button from './Button'
import { ClaimBalanceEntriesDocument } from '../graphql/__generated__'

export type CreditsModalProps = {
  opened: boolean
  close: () => void
  presetCodes?: string
}

type CreditsFormData = {
  codes: string
}

export default function CreditsModal({ opened, close, presetCodes }: CreditsModalProps) {
  const onSubmit = ({ codes }: CreditsFormData) => {
    claimEntries({
      variables: { codes },
      onCompleted: () => {
        reset()
      },
    })
  }

  const [claimEntries, { loading, error, data: creditsClaimed }] = useMutation(ClaimBalanceEntriesDocument)

  const [presetEditable, setPresetEditable] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreditsFormData>({
    defaultValues: {
      codes: presetCodes || '',
    },
  })

  const form = <>
    <form className="mt-2 space-y-3" onSubmit={ handleSubmit(onSubmit) }>
      <div>
        { presetCodes && !presetEditable
          ? <div className="mt-4">
            <label htmlFor="codes" className="text-slate-600 mb-2 block">Unique code(s)</label>
            <span className="font-bold text-slate-600">{ presetCodes }</span>
            <button
              onClick={ () => { setPresetEditable(true) } }
              className="ml-2 text-slate-600 hover:text-indigo-500 underline text-sm">
                <XMarkIcon className="inline-block w-4 h-4 top-[-2px] relative" />
              </button>
          </div>
          : <div className="mt-4">
              <label htmlFor="codes" className="text-slate-600 mb-2 block">Unique code(s)</label>
              <input id="codes" type="text" autoComplete="none" required data-cy="codes-input"
                {...register('codes', { required: true })}
                defaultValue={ presetCodes || '' }
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-100 text-gray-900
                          rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-lg sm:text-md"
                placeholder="QVKEJEZR, QCFJSAEE, ..." />
              <span className="text-sm text-gray-500">Have more than one code? Seperate them with commas.</span>
            </div>
        }
      </div>
      { error && (
        error.message === 'code already assigned'
        ? <p className="py-2 text-red-700">This code has already been assigned to a different email address. Please contact us for assistance.</p>
        : <p className="py-2 text-red-700">There was an error adding your credits. Please double check the spelling and try again.</p>
      )}

      { creditsClaimed &&
        <div className="p-2 bg-blue-100 rounded space-y-2">
          <p className="font-bold">Your credits have been added to your account.</p>

          <p>If you have no additional credits, you may continue by closing this dialog with the X at the
             upper right corner.</p>
        </div>
      }

      <div>
        <Button
          type="primary"
          className="mt-2"
          active={ loading }
        >
          <>Claim Credits</>
        </Button>
      </div>
    </form>
  </>

  return <>
    { opened &&
      <div className="fixed top-0 left-0 right-0 bottom-0 z-50
                      backdrop-blur flex items-center justify-center"
           onClick={ close }>
        <div className="max-w-md w-full space-y-6 p-8 bg-white rounded"
          onClick={ (e) => e.stopPropagation() }>
          <h1 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
            Claim Credits

            <button
              type="button"
              className="absolute top-2 right-2 shadow-sm p-2
                        bg-white border-none rounded-md
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={ close }
            ><XMarkIcon className="h-6 w-6" /></button>
          </h1>

          <div>
            <p className="text-sm">If you purchased video books and received a code, enter it here
            to add the credit to your account.</p>

            { form }
          </div>
        </div>
      </div>
  }
  </>
}
