import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import { DesignFragment, DesignsQuery, DesignsQueryVariables, DesignsDocument, DesignFilter } from '../graphql/__generated__'
import { formatDate } from '../utils/Date'
import MediaStack from './MediaStack'
import { useAuthenticatedQuery } from '../apollo-client'
import { classNames } from '../utils/classes'

type DesignChooserProps = {
  onChange: (designId: string) => void
  filter?: DesignFilter
}

export default function DesignChooser({ onChange, filter }: DesignChooserProps) {
  const { loading, error, data } = useAuthenticatedQuery<DesignsQuery, DesignsQueryVariables>(DesignsDocument, {
    variables: {
      filter,
    }
  })

  if (loading) {
    return <div className="flex justify-center my-4"><CircularProgress size="6em" /></div>
  }

  if (error || !data?.designs) {
    return <div className="p-2 my-2 bg-red-300">Error loading designs</div>
  }

  let designs = data?.designs?.slice() ?? []

  designs.sort((a: DesignFragment, b: DesignFragment) => {
    return +new Date(b.createdAt) - +new Date(a.createdAt)
  })

  return <>
    <ul>
      { designs.length ?
          designs.map((design: DesignFragment) => {
            if (!design.id || !design.media?.length) {
              return null
            }

            return <li key={ design.id }>
              <input
                id={ design.id }
                type="radio"
                name="design"
                className="invisible absolute peer"
                onChange={ () => onChange(design.id) } />
              <label
                htmlFor={ design.id }
                className="flex w-full cursor-pointer shadow p-6 my-3
                          bg-white rounded border-pink-300 border-4
                          hover:border-green-500 focus:border-green-300 peer-checked:border-green-300 peer-checked:bg-pink-100
                ">
                <div>
                  <h2 className="text-xl font-bold mb-2">Book created { formatDate(design.createdAt) }</h2>
                  <MediaStack media={ design.media || null } />
                </div>
              </label>
            </li>
        })
      :
        <div className="p-2 text-center">Your account doesn’t have any designs yet.</div>
      }
  </ul>
  </>
}
