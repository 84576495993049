import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import type { DesignFragment, RenderFragment } from '../graphql/__generated__'
import { formatDuration } from '../utils/Duration'

interface Props {
  design: DesignFragment
}

export default function RenderProgress({ design }: Props) {
  const render = design?.renders?.find((render: RenderFragment) =>
    render.formFactor === 'square' && render.task === 'normalize'
  )

  if (!render) {
    return <p className="p-2 my-2 bg-red-300">No render found.</p>
  }

  let secondsRemaining = 0
  if (render.encodeProgress && render.encodeProgress < 100) {
    const progress = render.encodeProgress / 100
    let start = +(new Date(render.encodeStart))

    // It takes a while for the progress to start updating, _after_ the time which
    // is supposedly the encode start, which makes our time estimates artifically long.
    // This also helps us not show a progress too early in the process.
    // TODO Fix the issue where the first rendered estimate is too optimistic
    start += 1000 * (design.durationSeconds / 40)

    const time = (+(new Date) - start) / 1000
    if (time > 0) {
      const unitsPerSecond = progress / time
      secondsRemaining = (1 - progress) / unitsPerSecond
    }
  }

  let renderState = <>Initializing...</>
  let remainingTime = <></>
  if (render?.encodeProgress > 5) {
    renderState = <>{ render.encodeProgress | 0 }%</>

    if (Math.floor(secondsRemaining) > 0) {
      remainingTime = <> ({ formatDuration(secondsRemaining) } remaining)</>
    }
  }

  if (!design?.media?.length) {
    return <p className="mt-4 p-2 bg-gray-100">
        Your book does not have any media yet. Add some on the Media step to continue.
      </p>
  }

  return (
    <div className="flex flex-col justify-center">
      <div className="flex justify-center my-4"><CircularProgress /></div>

      <div className="flex justify-center my-4 flex-col text-center">
        { render ? <p className="my-1 font-lg">
          { renderState } { remainingTime }
        </p> : <></> }

        <p className="mt-4">Your book is being rendered, this may take several minutes.</p>
      </div>
    </div>
  )
}
