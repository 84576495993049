import React, { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'

import MediaEditor from './MediaEditor'
import useWindowDimensions from '../utils/WindowDimensions'
import type { MediaEditorProps } from './MediaEditor'

type ModalMediaEditorProps = MediaEditorProps & {
  show: boolean
  onClose: () => void
}

export default function ModalMediaEditor(props: ModalMediaEditorProps) {
  const { show, onClose } = props
  const [saving, setSaving] = useState(false)

  const { width } = useWindowDimensions()

  if (!show) {
    return <></>
  }

  const doClose = () => {
    // We can't close while we're still saving, as the component will be unmounted.
    if (!saving) {
      onClose()
    }
  }

  return <Dialog
    onClose={ doClose }
    open={ show }
    fullWidth={ true }
    maxWidth={ 'lg' }
  >
    <DialogTitle>
      <div className="absolute top-2 right-2">
        { saving
          ? <div className="relative top-[-4px]"><CircularProgress size="1em" /></div>
          : <a
              title="Close preview"
              className="cursor-pointer"
              onClick={ doClose }>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </a>
        }
      </div>
    </DialogTitle>

    <DialogContent>
      <div className="flex items-center justify-center">
        { /* Height is based on the height of the adjacent left column */ }
        <MediaEditor
          { ...props }
          setSaving={ setSaving }
          height={ width >= 768 ? "414px" : "" }
        />
      </div>
    </DialogContent>
  </Dialog>
}

