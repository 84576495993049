import { ApolloClient, ApolloError, ApolloLink, createHttpLink, DocumentNode, InMemoryCache, OperationVariables, QueryHookOptions, QueryResult, TypedDocumentNode, useQuery } from "@apollo/client"
import { setContext } from '@apollo/client/link/context'
import { onError } from "@apollo/client/link/error"
import { makeVar, useReactiveVar } from '@apollo/client'
import type { TypePolicies, FieldMergeFunction } from '@apollo/client'
import DebounceLink from 'apollo-link-debounce'
import * as Sentry from '@sentry/react'

import { getFieldNames } from './utils/Graphql'
import { addMouseflowTag } from './utils/Tracking'
import { CustomerFragment, MediaEntryFragmentDoc, MeDocument } from './graphql/__generated__'
import { useEffect } from "react"
import { useNavigate, NavigateFunction } from "react-router-dom"

declare let gtag: Function

export const API_HOST = localStorage.DEBUG_local_server ? "http://localhost:8088" : "https://api.sendheirloom.com"

type Token = {
  token: string | undefined
  expiration: number | undefined

  // sudo is an email of a customer to impersonate, if token belongs to admin
  sudo?: string | undefined
}

export const TOKEN_VAR = makeVar<Token>({
  token: localStorage.getItem("token") || undefined,
  expiration: parseInt(localStorage.getItem("tokenExpiration") || '', 10) || undefined,
  sudo: localStorage.getItem("sudo") || undefined,
})

export function isAuthenticated(): boolean {
  const {token, expiration} = TOKEN_VAR()

  if (!token || !expiration) {
    return false
  }

  if (Date.now() > expiration) {
    return false
  }

  return true
}

const httpLink = createHttpLink({
  uri: API_HOST + "/graphql/client",
})

const authLink = setContext((_, { headers }) => {
  const { token, sudo } = TOKEN_VAR()

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      ["X-Heirloom-Sudo-Email"]: sudo ?? "",
    }
  }
})

type AuthenticationStatus = {
  isAuthenticated: boolean
  customerLoading?: boolean
  customerError?: ApolloError
  customerData?: CustomerFragment
  customerId?: string
  isSudo?: boolean
}

// NOTE: even if isAuthenticated, customerId won't be available until after the Me query is completed.
export function useAuthentication(): AuthenticationStatus {
  // We don't use it directly, but it forces rerenders of components which use this hook.
  const { token, sudo } = useReactiveVar(TOKEN_VAR)
  const authed = isAuthenticated()

  // We query Me, instead of only relying on customer ID from token, for sudo.
  const { data, loading, error } = useQuery<{ me: CustomerFragment }>(MeDocument, {
    skip: !authed,
  })

  // We also check token's definition here to make TypeScript happy.
  if (token === undefined || !authed) {
    return {
      isAuthenticated: false,
    }
  }

  // Even if sudoing, we'll use the logged-in user's ID (from the token).
  const [, originalCustomerId] = token.split('!')
  gtag && gtag('config', 'GA_MEASUREMENT_ID', {
    'user_id': originalCustomerId,
  })
  addMouseflowTag(originalCustomerId)

  const customerData = data?.me
  const isSudo = !!sudo && customerData && customerData.id !== originalCustomerId

  if (!isSudo && customerData?.email) {
    addMouseflowTag(customerData.email)
  }

  return {
    isAuthenticated: true,
    customerLoading: loading,
    customerError: error,
    customerData,
    customerId: customerData?.id,
    isSudo,
  }
}

export function useAuthenticatedPage() {
  const navigate = useNavigate()

  const { customerId, customerLoading } = useAuthentication()
  useEffect(() => {
    if (!customerLoading && !customerId) {
      // user isn't logged in
      navigateToLogin(navigate)
    }
  }, [customerLoading, customerId])
}

export function navigateToLogin(navigate: NavigateFunction) {
  if (window.location.pathname != '/' && window.location.pathname.indexOf('/code/') !== 0) {
    // LoginModal only rendered on Onboarding page
    navigate(`/?next=${encodeURIComponent(window.location.pathname)}`, { replace: true })
  }
}

// wrapper around useQuery to ensure an authenticated token exists (successful Me query) for the user
export function useAuthenticatedQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  useAuthenticatedPage()

  const { customerId, customerLoading } = useAuthentication()

  const result = useQuery<TData, TVariables>(query, {
    ...options,
    skip: !customerId || !!options?.skip,
  })

  return {
    ...result,
    loading: customerLoading || result.loading,
  }
}

export function logout() {
  localStorage.removeItem("email")
  localStorage.removeItem("token")
  localStorage.removeItem("tokenExpiration")
  localStorage.removeItem("sudo")
  TOKEN_VAR({
    token: undefined,
    expiration: undefined,
    sudo: undefined,
  })
}

// Signed S3 URLs get regenerated often, which continually breaks our client-side
// cache, forcing rerenders. Even if we cache the URLs on the server, it's common
// to hit a new instance and get a new URL. Instead we don't evict URLs unless they
// have really truely changed or have expired.
let keepIfNotExpired: FieldMergeFunction
keepIfNotExpired = (existing, incoming) => {
  if (!existing || !incoming) {
    return incoming
  }

  const iUrl = new URL(incoming)
  const eUrl = new URL(existing)
  if (iUrl.pathname !== eUrl.pathname || iUrl.origin !== eUrl.origin) {
    return incoming
  }

  const date = eUrl.searchParams.get('X-Amz-Date')
  const expiresIn = eUrl.searchParams.get('X-Amz-Expires')
  if (!date || !expiresIn) {
    return incoming
  }

  const expiresAt = +new Date(date) + (1000 * +expiresIn)
  if ((expiresAt - 10 * 1000) < new Date().getTime()) {
    return incoming
  }

  return existing
}

const signedFields = getFieldNames(MediaEntryFragmentDoc).filter(field => field.startsWith('signed'))
let mediaEntryFields: TypePolicies = {}
signedFields.forEach((fieldName: string) => {
  mediaEntryFields[fieldName] = {merge: keepIfNotExpired}
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  graphQLErrors?.forEach((graphQLError) => {
    Sentry.captureException(graphQLError)

    const { message, locations, path } = graphQLError
    console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
  })

  if (networkError) {
    // @ts-ignore Even though we are checking for statusCode's existence, TypeScript isn't happy as some Error types don't have it.
    if (networkError.statusCode && networkError.statusCode === 403) {
      console.log("Auth is invalid, logging out")
      logout()
    } else {
      Sentry.captureException(networkError)
      console.error(`[Network error]: ${networkError}`)
    }
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    new DebounceLink(10),
    authLink,
    httpLink,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      MediaEntry: {
        fields: mediaEntryFields,
      },
      ShippingLabel: {
        keyFields: ['label_id'],
      },
      Design: {
        fields: {
          media: {
            merge(_, incoming) {
              return incoming
            },
          },
        },
      },
    },
  })
});

export default client
