import React from 'react'
import SliderUnstyled, { SliderThumb } from '@mui/material/Slider'
import { styled } from '@mui/material/styles'

import type { ClipFragment } from '../graphql/__generated__'
import { formatDuration } from '../utils/Duration'

type MediaEditorTimelineProgressProps = {
  durationMs: number
  currentTimeMs: number
  onSeek: (time: number) => void
  previewChange: (timeMs: number) => void
  cancelPreview: () => void
}

export default function MediaEditorTimelineProgress({ durationMs, currentTimeMs, onSeek, previewChange, cancelPreview }: MediaEditorTimelineProgressProps) {
  return <div className="absolute top-0 left-0 w-full h-6 flex items-center">
    <TimelinePlaybackSlider
      value={ currentTimeMs }
      onChange={ (e: Event, val: number | number[], activeThumb: number) => {
        if (typeof val !== 'number') {
          throw new Error('Expected a number')
        }

        onSeek(val)

        // As you seek we want to show you what's under the head, but when you really
        // commit the change we want to snap the playback head to the closest clip.
        previewChange(val)
      }}
      onChangeCommitted={ cancelPreview }
      valueLabelFormat={ (v: number) => formatDuration(v / 1000) }
      valueLabelDisplay="auto"
      step={ 33 }
      min={ 0 }
      max={ durationMs }
    />
  </div>
}


const TimelinePlaybackSlider = styled(SliderUnstyled)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    height: 34,
    width: 6,
    pointerEvents: 'auto',
    backgroundColor: 'rgb(96 165 250)',
    boxShadow: 'none',
    borderRadius: 0,
    '&:after': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:focus, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
    },
  },
  '& .MuiSlider-track': {
    display: 'none',
  },
  '& .MuiSlider-rail': {
    borderRadius: 0,
    backgroundColor: '#bbb',
  },
}));
