import React, { useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import type { DraggableSyntheticListeners } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'

import Button from './Button'
import useAudio, { PUBLIC_PATH, MUSIC } from '../utils/Audio'
import type { Id3InfoFragment } from '../graphql/__generated__'

type Action = 'add' | 'remove' | null

type MusicBrowserItemProps = {
  songId: string
  action: Action
  id3?: Id3InfoFragment | null
  onAction?: () => void
  signedUrl?: string
  confirmActionButton?: string
  dragHandleListeners?: DraggableSyntheticListeners
}

type SortableMusicBrowserItemProps = MusicBrowserItemProps & {
  id: string
}

export function SortableMusicBrowserItem({ songId, id3, action, onAction, id, signedUrl, confirmActionButton }: SortableMusicBrowserItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
  })

  return <div
    style={{ transform: CSS.Transform.toString(transform), transition }}
    className="cursor-move select-none"
    ref={ setNodeRef }
    { ...attributes }
  >
    <MusicBrowserItem songId={songId} id3={id3} action={action} onAction={onAction} confirmActionButton={ confirmActionButton } dragHandleListeners={ listeners } signedUrl={ signedUrl } />
  </div>
}

export default function MusicBrowserItem({ songId, id3, action, onAction, confirmActionButton, dragHandleListeners, signedUrl }: MusicBrowserItemProps) {
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const song = MUSIC.find(song => song.id === songId)
  const audio = useAudio(signedUrl || (song && `${ PUBLIC_PATH }/${ song.id }.mp3`))

  const togglePlayback = () => {
    if (audio) {
      audio.seek(0)
      audio.setPlaying(!audio.playing)
    }
  }

  const icon = audio.playing
    ? <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 m-auto opacity-90 hover:opacity-100 cursor-pointer" viewBox="0 0 20 20" fill="white">
        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" style={{ stroke: "black", strokeWidth: "0.25px" }} clipRule="evenodd" />
      </svg>
    : <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 m-auto opacity-90 hover:opacity-100 cursor-pointer" viewBox="0 0 20 20" fill="white">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" style={{ stroke: "black", strokeWidth: "0.25px" }} clipRule="evenodd" />
      </svg>

  let actionButton: JSX.Element
  switch (action) {
    case 'add':
      actionButton = (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
        </svg>
      )
      break
    case 'remove':
      actionButton = (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M20 12H4" />
        </svg>
      )
      break
    default:
      actionButton = <div />
  }

  let albumArtPath = ""
  if (id3?.albumArt) {
    albumArtPath = id3.albumArt
  } else if (song) {
    albumArtPath = `${ PUBLIC_PATH }/${ song.id }.jpeg`
  }

  return (
    <div className="grid grid-cols-12 hover:bg-blue-100
                    p-1 px-2 relative">
      <div
        className={ `w-12 h-12 bg-gray-100 bg-cover bg-center rounded-lg
                  shadow-lg flex items-center
                  col-span-2` + ( song ? ' cursor-pointer' : '' ) }
        onClick={ togglePlayback }
        style={ albumArtPath ? { backgroundImage: `url("${ albumArtPath }")` } : {} }
      >
        { (signedUrl || song) && icon }
      </div>
      <div className="col-span-9" { ...dragHandleListeners }>
        <div className="font-bold truncate">{ id3?.title || song?.title || 'Your Song' }</div>
        <div className="text-gray-500 truncate">{ id3?.artist || song?.artist || 'User Uploaded' }</div>
      </div>
      <div
        className="col-span-1 flex items-center justify-end
                  cursor-pointer hover:opacity-100 opacity-80"
        onClick={ () => (confirmActionButton ? setShowConfirm(true) : onAction?.()) }
      >
        { actionButton }
      </div>
      { showConfirm &&
        <div className="absolute top-0 right-0 h-full bg-white hover:bg-blue-100"> 
          <div className="flex flex-col justify-center h-full">
            <div className="space-x-2 mr-2">
              <Button
                type='tertiary'
                onClick={ () => setShowConfirm(false) }>Cancel</Button>
              <Button
                type='danger'
                onClick={ () => { setShowConfirm(false); onAction?.() } }>{ confirmActionButton }</Button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
