import React from 'react'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'

import { useAuthentication } from '../apollo-client'
import { ContactUsDocument } from '../graphql/__generated__'
import Button from './Button'

type ContactFormProps = {
  details?: { [key: string]: string },
  onDone?: () => void,
  onBack?: () => void,
  type: 'issue' | 'contact',
}

export default function ContactForm({ onDone, onBack, details, type }: ContactFormProps) {
  const { customerData } = useAuthentication()
  let email = undefined
  if (customerData) {
    email = customerData.email
  }

  const [sendContact, { loading, error, data: done }] = useMutation(ContactUsDocument)

  const { register, handleSubmit, formState: { errors } } = useForm()

  const send = (data: any) => {
    sendContact({
      variables: {
        data: {
          type,
          fromEmail: data.email,
          body: data.comments,
          details,
        },
      },
    }).then(() => {
      onDone && onDone()
    })
  }

  if (done) {
    return <div className='flex flex-col items-center justify-center space-y-4'>
      <div className='text-2xl font-bold'>Thank you!</div>
      <div className='text-lg'>We'll get back to you as soon as we can.</div>
    </div>
  }

  return <div>
    { type === 'issue'
      ? <div className="space-y-2">
          <p>
            After you submit this report, we will promptly review it and reply to you by email.
            Please double-check that the email address below is correct, we won't be able to
            contact you if it's not.
          </p>
          <p>
            We are very sorry you experienced this issue, and look forward to resolving it
            quickly for you.
          </p>
        </div>
      : <div className="space-y-2">
        <h2 className="text-lg font-medium">Contact Us</h2>
        <p>
          We will promptly review your message and will reply to by email in the next 24 hours.
          Please double-check that the email address below is correct, we won't be able to
          contact you if it's not.
        </p>
      </div>
    }

    <form onSubmit={ handleSubmit(send) }>
      <div className="mt-6 grid gap-y-6 gap-x-4 grid-cols-6">
        <div className="col-span-6">
          <div className="flex justify-between">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              Email
            </label>
            { errors.email && <span className="text-red-500 text-sm">Please provide a valid email address</span> }
          </div>
            
          <div className="mt-2 flex">
            <input
              {...register("email", {
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "invalid",
                }
              })}
              type="text"
              name="email"
              id="email"
              autoComplete="email"
              defaultValue={ email ?? '' }
              className="block w-full min-w-0 flex-1 rounded max-w-md
                          rounded-md shadow-sm
                          border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300
                          placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600
                          sm:text-sm sm:leading-6"
            />
          </div>

          <p className="mt-2 text-sm text-gray-500">
            { type === 'issue'
              ? "We will only use this to contact you about this issue."
              : "We will only use this to contact you about your message."
            }
          </p>
        </div>

        <div className="col-span-6">
          <div className="flex justify-between">
            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
              Comments
            </label>
            { errors?.comments && <p className="text-red-500 text-sm">Please provide your comments</p> }
          </div>
          <div className="mt-2">
            <textarea
              {...register("comments", { required: true })}
              id="comments"
              name="comments"
              rows={3}
              className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset
                          ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                          focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
              defaultValue={''}
            />
          </div>
          <p className="mt-2 text-sm text-gray-500">
            { type === 'issue'
              ? "Share any details we should be aware of. The more information we have, the faster we can resolve your issue."
              : "Please let us know how we can help!"
            }
          </p>
        </div>
      </div>

      { error && <p className="bg-red-100 text-sm my-4 p-2">There was an error sending your message. Please try again.</p> }

      <div className="mt-4 flex justify-between">
        { onBack &&
          <Button
            type="secondary"
            onClick={ onBack }>Back</Button>
        }
        <div />

        <div className="flex justify-between">
          <Button
            active={ loading }
            type="primary">Submit</Button>
        </div>
      </div>
    </form>
  </div>
}
