import React, { useState } from 'react'

import { OrderFragment, StockFragment } from '../graphql/__generated__'
import DesignSummary from '../components/DesignSummary'
import ButtonLink from '../components/ButtonLink'
import { useOrderValidation } from '../utils/OrderValidation'
import useSteps, { MediaStep } from '../utils/Steps'
import Button from './Button'
import { useRemoveOrder } from '../utils/Order'
import ImportDestinationsModal from './ImportDestinationsModal'

type OrderEditProps = {
  order: OrderFragment | undefined
  stock: StockFragment | undefined
}

export type DesignWithDestinationsFragment = NonNullable<OrderFragment["designs"]>[0]

export default function OrderEdit({ order, stock }: OrderEditProps) {
  const { navigateTo, nextPath } = useSteps()

  const { designValidations, destinationValidations, hasProblems } = useOrderValidation(order, stock)
  const { removeOrder, removing } = useRemoveOrder(order ? [order] : [])
  const [importDesignId, setImportDesignId] = useState<string | undefined>(undefined)

  const showBorder = order?.designs && order.designs.length > 1

  return <div>
    { order?.isReplacement && <div className="max-w-full mx-auto my-4">
      <div className="p-4 bg-yellow-100 space-y-2">
        <p className="text-lg font-semibold text-center">This is a replacement for your damaged books.</p>
        <p className="text-base">Please make any necessary changes, and then review and submit this order on the Checkout
        page when you are done. You will not be charged for these replacement books, provided you
        return your damaged books to us.</p>
      </div>
    </div>
    }

    { importDesignId && order && <ImportDestinationsModal
      order={ order }
      designId={ importDesignId }
      onClose={ () => setImportDesignId(undefined) }
    /> }

    { !!order?.designs?.length &&
      <ul>
        {
          order.designs.map((design, i) => {
            if (!design.design) {
              return
            }

            return <li key={ design.design.id }
              className={"flex flex-col mt-2" + (showBorder ? " p-2 border-gray-200 border-4" : "")}>

              <DesignSummary
                allowEdit={ true }
                order={ order }
                design={ design.design }
                destinations={ design.destinations || null }
                designValidation={ designValidations[design.design.id] }
                destinationValidations={ destinationValidations }
                showOffer={ i === 0 }
                onShowImport={ () => setImportDesignId(design?.design?.id) }
              />
            </li>
          })
        }
      </ul>
    }

    <div className="mt-4 space-y-2">
      { order && <>
        { !order.isGift && <Button
          onClick={ () => navigateTo(MediaStep, {
            orderId: order.id,
            designId: 'add',
            destinationId: undefined,
          }, {
            returnHere: true,
          }) }
          className="m-auto text-sm block">+ Design a New Book</Button> }
        { !order?.designs?.length && <Button
          type="secondary"
          active={ removing }
          onClick={ () => removeOrder(order.id, '/') }
          className="m-auto text-sm block">Delete this Order</Button> }
      </>}
    </div>

    { !!order?.designs?.length && <div className="max-w-full mx-auto mt-4 flex justify-end">
      <ButtonLink
        to={ nextPath({}) }
        disabled={ !order || !!hasProblems }
        type="primary">Review Order</ButtonLink>
    </div> }
  </div>
}
