import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw'

import { EditMediaDocument, ClipFragment } from '../graphql/__generated__'
import Button from './Button'
import ModalMediaEditor from './ModalMediaEditor'
import { mediaAgeMin } from './MediaListing'
import type { MediaEntryFragment } from '../graphql/__generated__'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { classNames } from '../utils/classes'

type SortableMediaViewerProps = {
  media: MediaEntryFragment
  allMedia?: MediaEntryFragment[]
  showRemove: boolean
  setShowRemove: (mediaId: string, showRemove: boolean) => void
  onRemove?: () => void
  playable?: boolean
  designId: string
}

export default function SortableMediaViewer({ media, designId, showRemove, setShowRemove, onRemove, playable, allMedia }: SortableMediaViewerProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: media.id,
    resizeObserverConfig: undefined,
  })

  const [editMedia, { loading, error }] = useMutation(EditMediaDocument)

  const rotateImage = () => {
    if (media.type !== 'picture') {
      throw new Error('Cannot rotate non-image media')
    }
    if (!!media.clips && media.clips.length > 1) {
      throw new Error('More than one clip found for image media')
    }

    const existing = media.clips && media.clips[0] && media.clips[0].rotation || 0
    const newRotation = (existing + 90) % 360

    const clips = [{
      startMs: 0,
      endMs: 4000,
      rotation: newRotation,
    }]

    editMedia({
      variables: {
        designId,
        mediaEntryId: media.id,
        media: {
          clips,
        },
      },
      context: {
        debounceKey: 'media-viewer',
        debounceTimeout: 500,
      },
      optimisticResponse: {
        editMedia: {
          ...media,
          clips,
        },
      },
    })
  }

  const [showEditor, setShowEditor] = useState<boolean>(false)

  const location = useLocation()
  const showClipEditor = location.search.includes('showClipEditor')

  const formatParts = media?.format?.split('/')
  let invalidFormat: string | undefined = undefined
  if (formatParts && !(['video', 'audio', 'image'].includes(formatParts[0]))) {
    invalidFormat = formatParts[1]?.split(';')[0] || formatParts[0]
  }

  const draggableStyle: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  // show dragged media on top of Uppy elements
  if (isDragging) {
    draggableStyle.position = 'relative'
    draggableStyle.zIndex = 3000
  }

  let mediaTransform = ''
  if (media.clips && media.clips.length > 0) {
    const clip = media.clips[0]
    if (clip.rotation) {
      mediaTransform = `rotate(${ clip.rotation }deg)`
    }
  }

  const mediaPreview = <div className={ classNames(...[
    'flex h-[126px] items-center justify-center overflow-hidden',
    'sm:cursor-move', // the image isn't draggable, only the bottomBar, on smaller screens
    'sm:touch-none', // allows dragging to work flawlessly on mobile
  ]) }>
    { invalidFormat && <div className="text-center px-1">
      <div className="text-base font-bold">Invalid Format: { invalidFormat }</div>
      <div className="text-sm">This can't be included in your video book.</div>
    </div> }
    
    { !invalidFormat && !media.signedThumbnailLocation &&
      <div className="text-center px-1">
        { mediaAgeMin(media) < 5
          ? "Loading..."
          : (media.mezzanineLocation
            ?
              <span className="text-sm text-red-300 pb-6 block">
                There was an error generating thumbnail, but your video may render normally.
              </span>
            :
              <span className="text-center text-red-500 font-bold px-1 pb-6">
                File is missing. Please delete and re-upload.
              </span>
            )
        }
      </div>
    }

    <div style={ { 'transform': mediaTransform } }>
      { !!media.signedThumbnailLocation && (
        media.signedVideoThumbnailLocation && playable !== false
        ?
          <video
            className="max-w-full m-auto max-h-full"
            playsInline
            muted
            disableRemotePlayback
            poster={ media.signedThumbnailLocation }
            src={ media.signedVideoThumbnailLocation }
            onMouseOver={ e => { media.signedVideoThumbnailLocation && e.currentTarget.play() } }
            onMouseLeave={ e => { e.currentTarget.pause(); e.currentTarget.currentTime = 0 } }
          />
        :
          (media.signedThumbnailLocation &&
            <img className="max-w-full m-auto max-h-full"
              src={ media.signedThumbnailLocation || "" }
              alt="Thumbnail of video / photo"
            />
          )
      ) }
    </div>
  </div>

  const bottomBar = <div className={ classNames(...[
    'w-full h-[34px] grid grid-cols-3 z-10',
    'bg-pink-300 text-white leading-none p-1 pt-[0.325rem] cursor-move',
    'touch-none sm:touch-auto', // allows dragging to work flawlessly on mobile
  ]) }>
    <div>
      <button
        onClick={ () => {
          if (media.type === 'video') {
            setShowEditor(true)
          } else {
            rotateImage()
          }
        } }
        title={ 
          media.signedEncodedLocation ?
            (media.type === 'video' ? "Edit video" : "Rotate image")
          : `Video still being processed (${ media.encodeProgress }%)`
        }
        className={ media.signedEncodedLocation ? "hover:text-gray-100" : "" }
        disabled={ !media.signedEncodedLocation }
        >
        { !!media.signedEncodedLocation ? (
          media.type === 'video' ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
            </svg>
          ) : (
            <Rotate90DegreesCwIcon className="h-6 w-6" />
          )
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>
        ) }
      </button>
    </div>

    <div className="justify-self-center mt-[0.125rem] hover:text-gray-100" title="Reorder photo / video">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
      </svg>
    </div>

    { onRemove && !showRemove &&
      <div className="justify-self-end">
        <button
          onClick={ () => setShowRemove(media.id, true) }
          title={ "Remove video / photo" }
          className="hover:text-gray-100"
          >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
    }
  </div>

  const removeOverlay = onRemove && showRemove && <>
    <div className="absolute top-0 right-0 bottom-0 left-0 backdrop-blur">
      <div className="flex flex-col justify-center items-center gap-2 h-full text-sm">
        <Button type="danger" onClick={ () => onRemove() } className="w-24">Remove</Button>
        <Button onClick={ () => setShowRemove(media.id, false) } className="w-24">Cancel</Button>
      </div>
    </div>
  </>

  const containerClassName = classNames(...[
    'flex flex-col h-[160px] w-[160px] overflow-hidden',
    'bg-gray-50 rounded shadow-lg',
  ])

  return <>
    <div ref={ setNodeRef } style={ draggableStyle } className="h-full md:mx-2">
      {/* on mobile, drag target on bottomBar */}
      <div className={ classNames(containerClassName, 'sm:hidden') }>
        <div className="relative flex flex-col h-full">
          { mediaPreview }
          <div { ...attributes } { ...listeners }>
            { bottomBar }
          </div>
          { removeOverlay }
        </div>
      </div>

      {/* on larger devices, drag target also includes mediaPreview */}
      <div className={ classNames(containerClassName, 'hidden sm:flex') } { ...attributes } { ...listeners }>
        <div className="relative flex flex-col h-full">
          { mediaPreview }
          { bottomBar }
          { removeOverlay }
        </div>
      </div>
    </div>

    { media.type === 'video' &&
      <ModalMediaEditor
        designId={ designId }
        media={ media }
        show={ showEditor }
        onClose={ () => setShowEditor(false) }
        allMedia={ allMedia }
      />
    }
  </>
}
