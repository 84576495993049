import React, { useEffect, useMemo, useState } from 'react'
import type { RefObject } from 'react'
import { useParams } from 'react-router-dom'

import { DesignDocument, DesignQuery, OrderDocument, OrderQuery, OrderQueryVariables } from '../graphql/__generated__'
import MediaManager from '../components/MediaManager'
import useSteps, { LocalFlow, BuilderFlow, MediaStep } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import { CircularProgress } from '@mui/material'
import { useAuthenticatedQuery } from '../apollo-client'
import { useRedirectWithDesignDestination } from '../utils/RedirectWithDesignDestination'
import DesignSummaryListing from '../components/DesignSummaryListing'
import Button from '../components/Button'
import { useOrderValidation } from '../utils/OrderValidation'

type DesignEditorProps = {
  dropContainer: RefObject<HTMLDivElement>
}

export default function DesignEditor({ dropContainer }: DesignEditorProps) {
  // We collect the destinationId here, even if designs are the same for all
  // their destinations. We do have a URL pattern which supports destination id
  // to allow us to navigate through steps remembering which destination sent
  // us so we can navigate to other pages specific to that destination.
  const { orderId, designId, destinationId } = useParams()

  const { setStepState } = useStepContext()
  useEffect(() => {
    // This page is used both by the local flow, which customers use to load books they already
    // have, and the builder flow which is used to design books which are mailed to them fully
    // loaded. In the local flow there won't be an order id, as those designs aren't associated
    // with an order.
    setStepState({
      flow: orderId ? BuilderFlow : LocalFlow,
      step: MediaStep,
      args: { orderId, destinationId, designId },
    })
  }, [orderId, designId, destinationId])

  const { navigateTo, navigateNext, navigatePrev, currentPath, currentStep, hasReturn } = useSteps()

  useRedirectWithDesignDestination({
    orderId, designId, destinationId, currentPath,
    skip: currentStep !== MediaStep,
  })

  const {
    data: { orderStatus: order, stock } = {},
    loading: orderLoading,
    error: orderError,
  } = useAuthenticatedQuery<OrderQuery, OrderQueryVariables>(OrderDocument, {
    skip: !orderId,
    variables: {
      orderId: orderId ?? '',
    },
  })

  const { designValidations } = useOrderValidation(order, stock)

  // need to fetch design directly, w/o order for local flow
  const {
    data: { design: designFromQuery } = {},
    loading: designLoading,
    error: designError,
  } = useAuthenticatedQuery<DesignQuery>(DesignDocument, {
    skip: !!orderId || !designId,
    variables: {
      designId,
    },
  })

  const loading = orderLoading || designLoading
  const error = orderError ?? designError

  const { designs, design } = useMemo(() => {
    if (!order?.designs && !designFromQuery) {
      return {}
    }

    return {
      designs: order?.designs,
      design: designFromQuery ?? order?.designs?.find(({ design }) => design?.id === designId)?.design,
    }
  }, [order?.designs, designFromQuery, designId])

  const [isSaving, setIsSaving] = useState(false)

  if (loading) {
    return <div className="flex my-4 justify-center">
      <CircularProgress />
    </div>
  }

  const hasMany = designs && designs.length > 1

  const buttons = (() => {
    let nextButtonName = 'Next'
    if (designId && !design?.media?.length) {
      // Intentionally doesn't set blank to design metadata, in case user wanted to come back later.
      // That will happen on the Review step along w/other order validation.
      nextButtonName = 'Skip'
    }
    if (hasReturn) {
      nextButtonName = 'Done'
    }

    return <div className="mt-6">
      <div className="grid grid-cols-3 items-center">
        <div className="justify-self-start">
          <Button
            disabled={ isSaving }
            type="secondary"
            onClick={ () => hasReturn ? navigateNext({}) : navigatePrev({}) }
          >Back</Button>
        </div>

        <div className="justify-self-center">
          { hasMany && order && !design &&
            <Button
              onClick={ () => navigateTo(MediaStep, {
                orderId: order.id,
                designId: "add",
                destinationId: undefined,
              }, {
                returnHere: true,
              }) }
            >+ Design a New Book</Button>
          }
        </div>

        <div className="justify-self-end">
          <Button
            disabled={ isSaving }
            type="primary"
            onClick={ () => navigateNext({}) }
          >{ nextButtonName }</Button>
        </div>
      </div>
    </div>
  })()

  const formWithButtons = design ? <>
    <MediaManager
      design={ design }
      designValidation={ designValidations[design.id] }
      dropContainer={ dropContainer }
      onSaveStateChange={ setIsSaving }
    />
    { buttons }
  </> : undefined

  return <>
    { error && <p className="p-2 m-4 bg-red-300">Error loading design.</p> }

    { order && <>
      { hasMany ? <>
        <DesignSummaryListing
          order={ order }
          designId={ designId }
          onEdit={({ design }) => {
            if (design?.id && design.id !== designId) {
              navigateTo(MediaStep, { designId: design.id }, {})
            }
          }}
          onCancel={() => {
            if (designId) {
              navigateTo(MediaStep, { designId: undefined }, {})
            }
          }}
          form={ formWithButtons }
        />
        { !design && buttons }
      </> : formWithButtons }
    </> }

    {/* support local flow */}
    { !order && !!design && formWithButtons }
  </>
}
