import type { ApolloCache } from '@apollo/client'
import type { OrderFragment, CustomerBalanceFragment } from '../graphql/__generated__'
import { ShippingOptionsDocument } from '../graphql/__generated__'

export function formatPrice(val: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: (val % 100 !== 0) ? 2 : 0,
  }).format(val / 100)
}

// resetPaymentIntent is called anytime an Order's Cost changes
export function resetPaymentIntent(cache: ApolloCache<any>, order: OrderFragment) {
  cache.modify({
    id: cache.identify(order),
    fields: {
      paymentIntent(_, { DELETE }) {
        return DELETE
      },
    },
  })
}

export function booksInBalance(balance: CustomerBalanceFragment): number {
  return balance.products.reduce((prevVal, { product, quantity }) => {
    if (product.id.indexOf('book_5in') === 0) {
      return prevVal + quantity
    }
    return prevVal
  }, 0)
}

export type SavingsTier = {
  minQuantity: number
  savings: number
}

export const savingsTiers: Array<SavingsTier> = [
  { minQuantity: 2, savings: 5 },
  { minQuantity: 10, savings: 11 },
  { minQuantity: 30, savings: 14 },
]

export var REFETCH_ON_COST_CHANGE = ['ShippingOptions']
