import React from 'react'
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader'

type LoaderProps = {
  description: string
}

export default function Loader({ description }: LoaderProps) {
  return <div className="m-auto">
      <ClimbingBoxLoader css='display: block;' />

      { description &&
        <div>{ description }</div>
      }
    </div>
}
