import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

type ProgressOverlayProps = {
  size?: string
}

export default function ProgressOverlay({ size }: ProgressOverlayProps) {
  return <div className="
    fixed top-0 left-0 right-0 bottom-0 pointer-events-none
    flex justify-center items-center
    ">
    <div className="bg-white/95 rounded h-24 w-24 flex justify-center items-center
                    border border-gray-100
    ">
      <div className="flex flex-col items-center">
        <CircularProgress size={ size || '2em' } color="inherit" />
        <span className="text-sm text-center mt-2">Loading...</span>
      </div>
    </div>
  </div>
}
