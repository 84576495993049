import type { DesignFragment } from '../graphql/__generated__'

export function hasUnfinishedMedia(media: NonNullable<DesignFragment["media"]>): boolean {
  for (let i=media.length; i--;) {
    const hasSignedLocation = !!media[i].signedThumbnailLocation && !!media[i].signedEncodedLocation
    const encodingInProgress = media[i].encodeProgress && media[i].encodeProgress < 100

    switch (media[i].type) {
      case 'video':
      case 'picture':
        if (!hasSignedLocation || encodingInProgress) {
          return true;
        }
        break
      case 'music':
        // built-in music doesn't have encodeProgress
        // custom music doesn't have signed[Thumbnail|Encoded]Location
        if (!hasSignedLocation && encodingInProgress) {
          return true;
        }
        break
    }
  }

  return false;
}