import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { FormProvider, useForm } from 'react-hook-form'
import { AddExtraDocument, AddExtraMutation, AddExtraMutationVariables, RemoveExtraDocument, RemoveExtraMutation, RemoveExtraMutationVariables } from '../graphql/__generated__'
import type { FormRef, DestFormProps } from './DestComponentEditor'
import { findDestination } from '../utils/Destination'
import type { GiftBoxFormFields } from './GiftBoxFormInput'
import GiftBoxFormInput from './GiftBoxFormInput'
import { REFETCH_ON_COST_CHANGE } from '../utils/Price'

const horizOnlyCovers = ['heirloom', 'vhs', 'moon'].map((id) => 'cover-' + id)

const SOLD_OUT = false

const BoxForm = forwardRef<FormRef, DestFormProps>(({ destination, onSave, onError }, formRef) => {
  const hasGiftBox = useMemo(
    () => !!destination?.extras?.find(extra => extra.id === 'giftbox-5in'),
    [destination],
  )

  const [
    addExtra,
    { loading: addExtraSaving, error: addExtraSaveError },
  ] = useMutation<AddExtraMutation, AddExtraMutationVariables>(AddExtraDocument, {
    refetchQueries: REFETCH_ON_COST_CHANGE,
  })
  const [
    removeExtra,
    { loading: removeExtraSaving, error: removeExtraSaveError },
  ] = useMutation<RemoveExtraMutation, RemoveExtraMutationVariables>(RemoveExtraDocument, {
    refetchQueries: REFETCH_ON_COST_CHANGE,
  })

  const saving = addExtraSaving || removeExtraSaving
  const saveError = addExtraSaveError?.message || removeExtraSaveError?.message

  const submit = async function (values: GiftBoxFormFields) {
    if (!destination?.id) {
      throw new Error("No destination provided")
    }

    const opts = {
      onError: (err: ApolloError) => onError?.(err.message),
    }

    if (values.hasGiftBox && !hasGiftBox) {
      addExtra({...opts,
        variables: {
          destinationId: destination.id,
          extra: {
            id: 'giftbox-5in',
          },
        },
        onCompleted: ({ addExtra: _order }) => {
          const result = findDestination(_order, destination.id)
          result && onSave?.(result)
        },
      })
    } else if (!values.hasGiftBox && hasGiftBox) {
      removeExtra({...opts,
        variables: {
          destinationId: destination.id,
          id: 'giftbox-5in',
        },
        onCompleted: ({ removeExtra: _order }) => {
          const result = findDestination(_order, destination.id)
          result && onSave?.(result)
        },
      })
    } else {
      onSave?.(destination)
    }
  }

  const methods = useForm<GiftBoxFormFields>()
  const { handleSubmit, reset } = methods

  useEffect(() => reset({ hasGiftBox }), [reset, hasGiftBox])

  const errorHandler = useCallback(
    () => onError?.("Check validation errors on form"),
    [onError],
  )

  useImperativeHandle(formRef, () => ({
    saving,
    submit: handleSubmit(submit, errorHandler),
  }), [saving, errorHandler])

  let disabledReason: string = ''
  if (horizOnlyCovers.includes(destination?.coverId || '')) {
    disabledReason = 'cover'
  } else if (SOLD_OUT) {
    disabledReason = 'sold-out'
  }

  return <div className="mx-auto md:mx-4 space-y-4">
    <FormProvider { ...methods }>
      <form onSubmit={ handleSubmit(submit, errorHandler) }>
        <GiftBoxFormInput disabledReason={ disabledReason } />
        { saveError && <p className="p-2 m-4 bg-red-300">Error saving, please try again.</p> }
      </form>
    </FormProvider>
  </div>
})

export default BoxForm
