import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'

import { ShippingOptionsDocument } from '../graphql/__generated__'
import type { DestinationFragment } from '../graphql/__generated__'
import { useAuthenticatedQuery } from '../apollo-client'

type ShippingChoiceProps = {
  destination: DestinationFragment
}

export default function ShippingChoice({ destination }: ShippingChoiceProps) {
  const { loading, error, data } = useAuthenticatedQuery(ShippingOptionsDocument, {
    variables: {
      destinationId: destination.id,
    },
  })

  if (loading) {
    return <div className="flex justify-center my-2"><CircularProgress /></div>
  }

  if (error) {
    return <div className="p-2 bg-red-200">Error loading shipping rates. Please confirm that the address is valid.</div>
  }

  let option = data.shippingOptions.find((option: any) => (
    (destination.shippingSpeed && option.shippingSpeed === destination.shippingSpeed)
    ||
    (destination.shippingServiceCode && option.shippingServiceCode === destination.shippingServiceCode)
  ))

  if (!option) {
    return <p>Standard</p>
  }

  return <p>{ option.displayName }</p>
}
