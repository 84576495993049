import React, { useState } from 'react'
import type { ChangeEvent, MouseEvent } from 'react'

import { UpdateQuantityDocument, RemoveDestinationDocument } from '../graphql/__generated__'
import CircularProgress from '@mui/material/CircularProgress'
import { useMutation } from '@apollo/client'
import { resetPaymentIntent, REFETCH_ON_COST_CHANGE } from '../utils/Price'

import Button from './Button'
import type { DestinationFragment, OrderFragment } from '../graphql/__generated__'
import useSteps from '../utils/Steps'
import { setLastTouchedDestinationForOrder } from '../utils/LastTouchedDestination'

type QuantityFormProps = {
  destination: DestinationFragment
  designId: string
  order: OrderFragment
}

export default function QuantityForm({ order, designId, destination }: QuantityFormProps) {
  const { currentStep, currentArgs, navigateTo } = useSteps()
  const [updateQuantity, { loading: qtyLoading, error: qtyError }] = useMutation(UpdateQuantityDocument, {
    update(cache) {
      resetPaymentIntent(cache, order)
    },
    refetchQueries: REFETCH_ON_COST_CHANGE,
  })
  const [removeDestination, { loading: removeLoading, error: removeError }] = useMutation(RemoveDestinationDocument)
  const [showRemove, setShowRemove] = useState(false)

  const loading = qtyLoading || removeLoading
  const error = qtyError || removeError

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const qty = parseInt(event.target.value)

    if (qty === 0) {
      setShowRemove(true)
    } else {
      setShowRemove(false)

      updateQuantity({
        variables: {
          destinationId: destination.id,
          quantity: qty,
        },
      })
    }
  }

  const remove = () => {
    setLastTouchedDestinationForOrder(order.id, undefined)
    removeDestination({
      variables: {
        orderId: order.id,
        designId,
        destinationId: destination.id,
      },
      onCompleted: () => {
        if (currentStep && currentArgs?.destinationId) {
          navigateTo(currentStep, {
            destinationId: undefined,
          }, {})
        }
      },
      refetchQueries: ['Order'],
    })
  }

  if (loading) {
    return <div className="flex justify-center my-4"><CircularProgress /></div>
  }

  return <form className="my-2">
    { showRemove
      ?
        <div className="flex items-center">
          <Button
            active={ removeLoading }
            onClick={ () => remove() }
            type="danger"
          >
            Remove
          </Button>
          <button
            className="ml-2"
            onClick={ () => setShowRemove(false) }
          >
            Cancel
          </button>
        </div>
      :
        <select
          defaultValue={ destination.quantity || 1 }
          onChange={ handleChange }
          className="inline rounded"
        >
          <option value={ 0 }>Remove</option>
          {/*
            // @ts-ignore TS doesn't like this weird iterator style */}
          {[...Array(99).keys()].map(i => <option key={i + 1} value={i + 1}>{i + 1}</option>)}
        </select>
    }
    { error && <div className="m-2 p-2 bg-red-500">{ error.message }</div> }
  </form>
}

