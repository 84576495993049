import React, { useEffect } from 'react'
import type { MouseEvent } from 'react'
import { useParams } from 'react-router-dom'

import { DesignDocument } from '../graphql/__generated__'
import type { RenderFragment } from '../graphql/__generated__'
import useSteps, { LocalFlow, TransferStep } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import { useAuthenticatedQuery } from '../apollo-client'

export default function TransferDesign() {
  const { designId } = useParams()

  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow: LocalFlow,
      step: TransferStep,
      args: { designId },
    })
  }, [designId])

  const { prevPath } = useSteps()

  const { data, error } = useAuthenticatedQuery(DesignDocument, {
    variables: {
      designId,
    },
  })
  const design = data?.design
  const render = design?.renders?.find((render: RenderFragment) => render.task === 'share')

  const sharePath: string | undefined = render?.url

  return <>
    <h1 className="text-2xl mb-4">Transfer Design</h1>

    <h3 className="mt-4 text-lg">1. Connect your book to your computer</h3>
    <p className="mt-2">
      Using the cable included with your book, connect it to your computer. If you don't have a cable,
      contact us for a replacement.
    </p>

    <h3 className="mt-4 text-lg">2. Navigate to the book on your computer</h3>
    <p className="mt-2">
      Your book should appear as a drive on your computer. It may appear on your desktop, or in your
      file browser under the name "UNTITLED".
    </p>

    <h3 className="mt-4 text-lg">3. Remove any existing videos</h3>
    <p className="mt-2">
      Navigate to the 'Video' folder on your book. Delete any existing videos.
    </p>

    <h3 className="mt-4 text-lg">4. Transfer the new video file</h3>
    <p className="mt-2">
      Drag the video file downloaded in the <a href={ prevPath({}) }>previous step</a> into the
      'Video' folder on your book.
    </p>

    <h3 className="mt-4 text-lg">5. Eject your book</h3>
    <p className="mt-2">
      Eject your book from your computer. This will ensure it is correctly disconnected and your
      file is fully transferred.
    </p>

    <p className="mt-4">You should now be able to open your book and see your video play! Be sure to remove any protective
    film which may remain on your book before giving it as a gift.</p>

    { sharePath &&
      <div className="mt-4 p-4 bg-gray-50">
        <h3 className="text-lg">Share Your Video</h3>
        <p className="mt-2">You're done building your book, but you don't have to celebrate alone. Use this link to send a preview of your book
        to your friends and family.</p>

        <input
          className="text-center w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-8 inline-block"
          readOnly
          onClick={ (e: MouseEvent) => {
            // @ts-ignore It thinks select doesn't exist on this type
            e.target.select()
          } }
          value={ sharePath } />
      </div>
    }

    {/* TODO: handle GQL error causing sharePath to be empty */}
  </>
}
