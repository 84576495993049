import React, { useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useParams } from 'react-router-dom'

import { API_HOST, useAuthenticatedQuery } from '../apollo-client'
import { DesignDocument } from '../graphql/__generated__'
import type { RenderFragment } from '../graphql/__generated__'
import useSteps, { LocalFlow, DownloadStep } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import RenderProgress from '../components/RenderProgress'

export default function DownloadDesign() {
  const { designId } = useParams()

  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow: LocalFlow,
      step: DownloadStep,
      args: { designId },
    })
  }, [designId])
  const { navigateNext } = useSteps()

  const { data, loading, error } = useAuthenticatedQuery(DesignDocument, {
    variables: {
      designId,
    },
    pollInterval: 2_000,
  })
  const design = data?.design

  if (!designId) {
    return <>Missing design id.</>
  }

  const render = design?.renders?.find((render: RenderFragment) =>
    render.formFactor === 'square' && render.task === 'normalize'
  )

  let url = ''
  if (render && render.signedURL) {
    const params = new URLSearchParams({
      signedURL: render.signedURL,
    })
    url = `${ API_HOST }/download/book?${ params.toString() }`
  }


  return <>
    <h1 className="text-2xl mb-4">Download Design</h1>

    { loading && <div className="flex justify-center my-4"><CircularProgress /></div> }
    { error && <p className="p-2 my-2 bg-red-300">Error loading design.</p> }

    { url ?
    <div>
      <p className="my-4">
        Download your book's completed video to your computer so you may load it onto your book.
        Please take note of where you save the file.
      </p>
      <div className="flex justify-center">
        <a
          href={ url }
          download
          target="_blank"
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded my-4 text-center"
          onClick={ () => navigateNext({}) }
          rel="noopener noreferrer"
        >Download</a>
      </div>
    </div>
    : (!loading && <RenderProgress design={ design } />)
    }
  </>
}
