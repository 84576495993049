import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'

import { AddDesignDocument, AddDesignMutation, AddDestinationDocument, AddDestinationMutation, DesignFragment, DestinationFragment } from '../graphql/__generated__'
import Button from './Button'
import DesignChooser from './DesignChooser'
import useSteps from '../utils/Steps'
import { useNavigate } from 'react-router'
import { getLastTouchedDestinationForOrder, setLastTouchedDestinationForOrder } from '../utils/LastTouchedDestination'
import { classNames } from '../utils/classes'

type NewDesignProps = {
  orderId: string
}

type Choice = 'new' | 'copy' | undefined

export default function NewDesignOptions({ orderId }: NewDesignProps) {
  const navigate = useNavigate()

  const [choice, setChoice] = useState<Choice>(undefined)
  const [cloneDesignId, setCloneDesignId] = useState<string>('')

  const { currentPath, hasReturn, navigateNext } = useSteps()

  const onCreate = (design: DesignFragment, _destination?: DestinationFragment) => {
    // currently no need to include destination
    navigate(currentPath({ designId: design.id }))
  }

  const [
    addDesign,
    { loading: addingDesign, error: addingDesignError },
  ] = useMutation<AddDesignMutation>(AddDesignDocument)

  const [
    addDestination,
    { loading: addingDestination, error: addingDestinationError },
   ] = useMutation<AddDestinationMutation>(AddDestinationDocument, {
    onError: () => {
      setLastTouchedDestinationForOrder(orderId, undefined)
    },
  })

  const createDesignAndDestination = (cloneDesignId?: string) => {
    addDesign({
      variables: {
        orderId,
        cloneDesignId,
      },
      onCompleted: ({ addDesign: { design } }) => {
        if (!orderId) {
          onCreate?.(design)
          return
        }

        addDestination({
          variables: {
            orderId,
            designId: design.id,
            cloneDestinationId: getLastTouchedDestinationForOrder(orderId) // w/o order fragment, we can't pick a destination if last touched undefined
          },
          onCompleted: ({ addDestination: { designs } }) => {
            const { destinations } = designs?.find(d => d.design?.id === design.id) ?? {}
            const destination = destinations?.length ? destinations[destinations.length - 1] : undefined

            onCreate?.(design, destination)
          }
        })
      }
    })
  }

  function submit() {
    if (choice === 'new') {
      createDesignAndDestination()
    } else if (choice === 'copy' && cloneDesignId !== '') {
      createDesignAndDestination(cloneDesignId)
    }
  }

  if (addingDesign || addingDestination) {
    return <div className="flex justify-center mt-8"><CircularProgress size="6em" /></div>
  }

  return <>
    { !!addingDesignError && <div className="bg-red-300 p-4 my-2">{ addingDesignError.message }</div> }
    { !!addingDestinationError && <div className="bg-red-300 p-4 my-2">{ addingDestinationError.message }</div> }

    <div className={ classNames(
      'grid grid-cols-2 space-x-1',
      choice === 'copy' ? 'mt-4 md:mt-12' : 'my-4 md:my-12',
    ) }>
      <div>
        <button
          className={ classNames(
            'block m-auto md:w-80 md:h-80 p-6 rounded border-4 border-pink-300',
            choice === 'new' ? 'bg-pink-100 border-green-300' : 'hover:border-green-500',
          ) }
          onClick={ () => {
            setChoice('new')
            setCloneDesignId('')
          } }
        >
          <h2 className="mb-6 text-lg font-bold text-gray-900">Start from Scratch</h2>
          <svg xmlns="http://www.w3.org/2000/svg" className="m-auto h-20 w-20 mb-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
          <span className="text-gray-600">Build a book by uploading new videos and photos.</span>
        </button>
      </div>

      <div>
        <button
          className={ classNames(
            'block m-auto md:w-80 md:h-80 p-6 rounded border-4 border-pink-300',
            choice === 'copy' ? 'bg-pink-100 border-green-300' : 'hover:border-green-500',
          ) }
          onClick={ () => setChoice('copy') }
        >
          <h2 className="mb-6 text-lg font-bold text-gray-900">Start From an Existing Book</h2>
          <svg xmlns="http://www.w3.org/2000/svg" className="m-auto h-20 w-20 mb-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
          </svg>
          <span className="text-gray-600">Start with the photos and videos of one of your existing books.</span>
        </button>
        <div className={ "w-0 h-0 mt-2 m-auto border-[40px] border-solid border-transparent border-b-0 border-t-pink-300" + (choice === 'copy' ? ' block' : ' hidden') }></div>
      </div>
    </div>

    { choice === 'copy' && <DesignChooser onChange={ setCloneDesignId } /> }

    <div className="grid grid-cols-2 justify-items-center">
      <div className="justify-self-start">
        { hasReturn &&
          <Button
            type="secondary"
            onClick={ () => navigateNext({}) }
            className="pointer-events-auto"
          >Back</Button>
        }
      </div>

      <div className="justify-self-end">
        <Button
          type="primary"
          onClick={ submit }
          disabled={ choice === undefined || (choice === 'copy' && cloneDesignId === '') }
        >Next</Button>
      </div>
    </div>
  </>
}
