import React, { useState, useCallback } from 'react'
import { CircularProgress } from '@mui/material'
import { useMutation } from '@apollo/client'

import DestComponentEditor from './DestComponentEditor'
import AddressForm from './AddressForm'
import BigOptions from './BigOptions'

import {
  OrderFragment,
  CompleteFreeOrderMutation,
  CompleteFreeOrderDocument,
  AddDestinationMutation,
  AddDestinationDocument,
  CreateDraftOrderFullResponseMutation,
  CreateDraftOrderFullResponseDocument,
  CreateDraftOrderMutationVariables,
} from '../graphql/__generated__'

export default function RequestCable(orderId: string) {
  const [newOrder, setNewOrder] = useState<undefined | OrderFragment>(undefined)

  const [
    createDraftOrder,
    { loading: createLoading, error: createError },
  ] = useMutation<CreateDraftOrderFullResponseMutation, CreateDraftOrderMutationVariables>(
    CreateDraftOrderFullResponseDocument,
    {
      variables: {
        order: {
          metadata: {
            replacesOrderId: orderId,
          },
        },
      },
    }
  )

  const [
    addDestination,
    { loading: addLoading, error: addError },
  ] = useMutation<AddDestinationMutation>(AddDestinationDocument)

  const [
    completeFreeOrder,
    { data: completeDone, loading: completeLoading, error: completeError },
  ] = useMutation<CompleteFreeOrderMutation>(CompleteFreeOrderDocument)

  const makeOrder = async (speed: string) => {
    const { data } = await createDraftOrder()

    if (data?.createDraftOrder?.order) {
      const { data: destData } = await addDestination({
        variables: {
          orderId: data.createDraftOrder.order?.id,
          designId: data.createDraftOrder.order?.designs?.[0]?.design?.id,
          extras: [{
            id: `${ speed }-cable`,
          }],
        },
      })

      if (destData?.addDestination) {
        setNewOrder(destData.addDestination)
      }
    }
  }

  const completeOrder = useCallback(() => {
    if (!newOrder?.id) {
      throw new Error('No order id')
    }

    completeFreeOrder({
      variables: {
        orderId: newOrder.id,
      },
    })
  }, [newOrder?.id, completeFreeOrder])

  let body = <></>
  if (completeDone) {
    body = <div className="flex justify-center py-8 text-center">
      <p>
        Thank you for your request. We will ship a replacement cable to you shortly, you can
        expect to receive it in 5-7 days.
      </p>
    </div>
  } else if (createLoading || addLoading || completeLoading) {
    body = <div className="flex justify-center my-8"><CircularProgress size="4em" /></div>
  } else if (createError || addError || completeError) {
    body = <div className="flex justify-center">
      <p className="bg-red-100 p-2">There was an error creating your replacement. Please try again or contact us for help.</p>
    </div>
  } else if (!newOrder) {
    body = <>
      <p>We can send you a new cable, free of charge. To do so, we need to know what
        version of our video books you have. Please select which applies to you:</p>

      <BigOptions options={[
        {
          title: "My book doesn't have buttons on the side",
          subtitle: "Select if your book has a mini-USB port and doesn't have play/pause or volume buttons on the edge.",
          onClick: () => makeOrder('old'),
        },
        {
          title: "My book has buttons on the side",
          subtitle: "Select if your book has a micro-USB port and has five buttons on the edge.",
          onClick: () => makeOrder('new'),
        },
      ]} />
    </>
  } else {
    body = <div className="mb-[-4.5em]">
      <p>Thank you! What address should we ship your cable to?</p>

      <DestComponentEditor
        form={ AddressForm }
        orderId={ newOrder.id }
        designId={ newOrder.designs?.[0]?.design?.id }
        destinationId={ newOrder.designs?.[0]?.destinations?.[0]?.id }
        hasReturn={ false }
        onDone={ () => completeOrder() }
      />
    </div>
  }

  return <div>
    { body }
  </div>
}
