import React from 'react'

import { GetOrCreateBlankDesignDocument, GetOrCreateBlankDesignMutation, UpdateDesignMetadataDocument, UpdateOrderMetadataDocument } from '../graphql/__generated__'
import { ArrowDownOnSquareIcon, GiftIcon } from '@heroicons/react/24/outline'
import { useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import { useAuthenticatedPage } from '../apollo-client'

export default function GiftSelect() {
  const navigate = useNavigate()
  const { orderId } = useParams()

  useAuthenticatedPage()

  const [updateOrderMetadata] = useMutation(UpdateOrderMetadataDocument)

  const [getOrCreateBlankDesign] = useMutation(GetOrCreateBlankDesignDocument, {
    variables: {
      orderId,
    },
  })

  const [updateDesignMetadata] = useMutation(UpdateDesignMetadataDocument)

  const sendBlankBook = () => {
    // We make an empty design to use for the blank book
    getOrCreateBlankDesign({
      onCompleted: ({ getOrCreateBlankDesign }: GetOrCreateBlankDesignMutation) => {
        const { design } = getOrCreateBlankDesign
        updateDesignMetadata({
          variables: {
            designId: design.id,
            metadataJSON: JSON.stringify({ blank: true })
          },
          onCompleted: () => navigate(`/gift/order/${ orderId }/design/${ design.id }/cover`),
        })
      },
    })
  }

  const sendGiftCard = () => {
    // We even use a blank design for e-gift cards, but we will end up adding a destination which is
    // an email address (not a physical address).
    getOrCreateBlankDesign({
      onCompleted: ({ getOrCreateBlankDesign }: GetOrCreateBlankDesignMutation) => {
        const { design } = getOrCreateBlankDesign
        updateOrderMetadata({
          variables: {
            orderId,
            metadataJSON: JSON.stringify({ egift: true })
          },
          onCompleted: () => navigate(`/e-gift/order/${ orderId }/design/${ design.id }/email`),
        })
      },
    })
  }

  return <>
    <div className="flex flex-col">
      <h1 className="text-2xl">Send a Gift</h1>
      <p className="pt-1 pb-6">A video book makes the perfect gift. Send a blank book, or send an e-gift card your recipient can use to build a book on our site.</p>

      <a
        onClick={ sendGiftCard }
        tabIndex={ 0 }
        className="block w-full h-48 cursor-pointer shadow p-6 my-3
                  bg-white focus:outline-0 focus:border-green-300 hover:border-green-500
                  flex rounded border-pink-300 border-4
        ">
        <div className="hidden w-32 mr-8 md:flex items-center justify-center">
          <GiftIcon className="w-14 h-14 md:w-24 md:h-24 text-pink-300" />
        </div>

        <div className="self-center">
          <h2 className="text-xl font-bold mb-2">Send Gift E-Card <span className="font-normal">(recommended)</span></h2>
          <p>Send your recipient a digital gift card they can use to build a book on our site.</p>
        </div>
      </a>

      <a
        onClick={ sendBlankBook }
        tabIndex={ 0 }
        className="block w-full h-48 cursor-pointer shadow p-6 my-3
                  bg-white focus:outline-0 focus:border-green-300 hover:border-green-500
                  flex rounded border-pink-300 border-4
        ">
        <div className="hidden w-32 mr-8 md:flex items-center justify-center">
          <ArrowDownOnSquareIcon className="w-14 h-14 md:w-24 md:h-24 text-pink-300" />
        </div>

        <div className="self-center">
          <h2 className="text-xl font-bold mb-2">Send a Blank Book</h2>
          <p>Send your recipient a blank book they can load with their photos and videos.</p>
        </div>
      </a>
    </div>
  </>
}
