import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import BoxForm from '../components/BoxForm'
import DestComponentEditor from '../components/DestComponentEditor'
import useSteps, { BuilderFlow, GiftFlow, GiftBoxStep, BoxStep } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import { CircularProgress } from '@mui/material'
import { useRedirectWithDesignDestination } from '../utils/RedirectWithDesignDestination'

interface BoxEditorProps {
  isGift?: boolean
}

export default function BoxEditor({ isGift }: BoxEditorProps) {
  const { orderId, designId, destinationId } = useParams()

  const flow = isGift ? GiftFlow : BuilderFlow
  const step = isGift ? GiftBoxStep : BoxStep

  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow,
      step,
      args: { orderId, destinationId, designId },
    })
  }, [flow, step, orderId, designId, destinationId])

  const { navigateNext, navigatePrev, currentPath, currentStep, hasReturn } = useSteps()

  const { loading } = useRedirectWithDesignDestination({
    orderId, designId, destinationId,
    skip: currentStep !== step,
    currentPath,
  })

  if (!orderId) {
    return <>Missing order id</>
  }

  if (loading) {
    return <div className="flex my-4 justify-center">
      <CircularProgress />
    </div>
  }

  return <>
    <DestComponentEditor
      form={ BoxForm }
      text={ {
        pageTitle: 'Box',
        pageDescription: <div className="bg-gray-50 p-4 space-y-2">
          <p>
            Our books are normally packaged in a flat cardboard box. We also offer gift boxes which are custom-designed
            to fit our books and look better than cardboard ever can. A gift box is a great way to make your book even more memorable!
          </p>
          <ul className="list-disc ml-8">
            <li>Your book's charging cord is integrated into the box design.</li>
            <li>Based on your cover selection we will secure the box with either a gold, silver, or red reusable ribbon.</li>
            <li>Gift boxes are shipped in an outer box to ensure they arrive in perfect condition.</li>
          </ul>
        </div>,
        componentName: 'Box',
      } }
      showSkip={ true }
      orderId={ orderId }
      destinationId={ destinationId }
      designId={ designId }
      hasReturn={ hasReturn }
      onDone={ () => {
        navigateNext({})
      }}
      onBack={ () => {
        hasReturn ? navigateNext({}) : navigatePrev({})
      }}
    />
  </>
}
