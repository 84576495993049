import React, { useState, useMemo } from 'react'

import type { MediaEntryFragment } from '../graphql/__generated__'
import MusicBrowser from './MusicBrowser'
import MusicBrowserItem, { SortableMusicBrowserItem } from './MusicBrowserItem'
import { MUSIC } from '../utils/Audio'

import {
  DndContext,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'


type MusicListingProps = {
  segments: Array<MediaEntryFragment>
  onAdd: (songId: string) => void
  onRemove: (songId: string, musicIndex: number) => void
  onReorder: (newSegmentIds: Array<string>) => void
  onOpenUpload(): void
}

export default function MusicListing({ segments, onAdd, onRemove, onReorder, onOpenUpload }: MusicListingProps) {
  const [showBrowser, setShowBrowser] = useState(false)
  const [draggedSongId, setDraggedSongId] = useState<string | undefined>(undefined)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  function handleDragStart({ active }: DragStartEvent) {
    setDraggedSongId(active.id.toString().split(':')[0])
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    setDraggedSongId(undefined)

    if (!active || !over || !segments) {
      return
    }

    if (active.id !== over.id) {
      const oldIndex = +(active.id.toString().split(':')[1])
      const newIndex = +(over.id.toString().split(':')[1])

      const newSegments = arrayMove(segments, oldIndex, newIndex)
      onReorder(newSegments.map(m => m.id))
    }
  }

  return (
    <div>
      <div
        className={ `p-2 mt-2 mb-[-4px] bg-gray-50 cursor-pointer
                     rounded w-full text-sm font-bold` }
        onClick={ () => setShowBrowser(!showBrowser) }>
        <div className="flex justify-between">
          <div className="flex items-center md:shrink-0 text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" className={ "h-6 w-6 transition-transform duration-500" + (showBrowser ? ' -rotate-90' : ' rotate-90') } fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}><path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" /></svg>
            <span className="ml-2">Background Music</span>
          </div>
          <div className={ `justify-between transition-opacity
                            duration-1000 hidden opacity-0` + (showBrowser ? "" : " !flex !opacity-100") }>
            <ul className="flex flex-wrap">
              { segments.map((segment, i) => {
                const song = MUSIC.find(song => song.id === segment.id)
                return <li key={ `${segment.id}:${i}` } className="inline-block bg-gray-100 rounded p-2 ml-2 my-1
                                                                  whitespace-nowrap">
                  { song?.title || segment.id3?.title || 'Your Song' }
                </li>
              })}
            </ul>
          </div>
        </div>
      </div>

      <div className={ `flex pt-2 flex-wrap transition-[height] duration-500
                        overflow-hidden h-0` + (showBrowser ? " h-auto md:h-[300px]" : "") }>
        <div className="md:w-1/2 w-full">
          { <MusicBrowser onAdd={ onAdd } onOpenUpload={ onOpenUpload } /> }
        </div>

        <div className="md:w-1/2 w-full flex flex-col justify-between">
          <DndContext
            sensors={ sensors }
            collisionDetection={ closestCorners }
            onDragEnd={ handleDragEnd }
            onDragStart={ handleDragStart }>
            <SortableContext
              items={ segments?.map(m => m.id) ?? [] }
              strategy={ verticalListSortingStrategy }
              >
              <div className="md:max-h-[286px] overflow-y-auto pb-4">
                { segments.map((segment, i) => (
                    <SortableMusicBrowserItem
                      key={ `${segment.id}:${i}` }
                      id={ `${segment.id}:${i}` }
                      songId={ segment.id }
                      id3={ segment.id3 }
                      action='remove'
                      confirmActionButton='Remove'
                      signedUrl={ segment.signedEncodedLocation || undefined }
                      onAction={ () => onRemove(segment.id, i) }
                    />
                )) }
              </div>
              <DragOverlay>
                { draggedSongId && <MusicBrowserItem
                    songId={ draggedSongId }
                    action={ null }
                    id3={ segments.find(s => s.id === draggedSongId)?.id3 }
                  />
                }
              </DragOverlay>
            </SortableContext>
          </DndContext>
        </div>
      </div>
    </div>
  )
}
