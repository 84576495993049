import React, { useState } from 'react'
import type { FormEvent, ChangeEvent } from 'react'
import { useMutation } from '@apollo/client'

import Button from './Button'
import { ApplyDiscountCodeDocument, OrderFragment } from '../graphql/__generated__'
import { resetPaymentIntent, REFETCH_ON_COST_CHANGE } from '../utils/Price'

type DiscountFormProps = {
  order: OrderFragment
}

type Show = 'option' | 'existing' | 'form'

export default function DiscountForm({ order }: DiscountFormProps) {
  const [show, setShow] = useState<Show>(order.discountId ? 'existing' : 'option')
  const [newCode, setNewCode] = useState<string>(order?.discountId || '')
  const [applyDiscountCode, { loading, error }] = useMutation(ApplyDiscountCodeDocument, {
    update(cache) {
      resetPaymentIntent(cache, order)
    },
    refetchQueries: REFETCH_ON_COST_CHANGE,
  })

  async function applyCode(code: string) {
    if (!order) {
      return
    }

    applyDiscountCode({
      variables: {
        orderId: order.id,
        discountId: code || '',
      },
      onCompleted: () => {
        setShow(code ? 'existing' : 'option')
      },
    })
  }

  if (!order) {
    return <></>
  }

  if (show === 'option') {
    return <button
      className="text-blue-500 underline cursor-pointer"
      onClick={ () => setShow('form') }>Apply discount code</button>
  } else if (show === 'existing') {
    return <>
      <span className="text-gray-500">Discount code:</span>
      <span className="font-bold mx-1"> { order.discountId && order.discountId.toUpperCase() }</span>
      <button
        className="text-blue-500 underline cursor-pointer"
        onClick={ () => setShow('form') }>edit</button>
    </>
  } else {
    return (
      <form
        className="my-2"
        onSubmit={ async (e: FormEvent<HTMLFormElement>) => {
          e.preventDefault()
          applyCode(newCode)
        } }
      >
        <input type="text"
          defaultValue={ newCode }
          className="mr-2"
          onChange={
            (e: ChangeEvent<HTMLInputElement>) => setNewCode(e.target.value)
          } placeholder="Discount code" />
        <Button
          active={ loading }
          type="primary">Apply</Button>

        { error && <div className="p-2 bg-red-300">Error applying discount code.</div> }
      </form>
    )
  }
}
