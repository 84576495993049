import React from 'react'
import type { ReactNode } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

export type ButtonProps = {
  children: ReactNode
  type?: 'primary' | 'secondary' | 'tertiary' | 'danger' | undefined
  className?: string
  disabled?: boolean
  title?: string
  active?: boolean

  // If button
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void> | void | undefined

  // If link
  href?: string
  newPage?: boolean
}

const typeStyles = {
  primary: 'bg-green-500 hover:bg-green-700',
  secondary: 'bg-pink-300 hover:bg-pink-500',
  tertiary: 'bg-gray-50 hover:bg-gray-300 text-black',
  danger: 'bg-red-500 hover:bg-red-700',
  default: 'bg-gray-500 hover:bg-gray-700',
}

export default function Button({ children, type, onClick, className, disabled, title, active, href, newPage }: ButtonProps) {
  let styles = "text-white "
  styles += typeStyles[type || 'default']
  styles += " font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline align-right"
  if (disabled) {
    styles += " opacity-50 cursor-not-allowed"
  }
  styles += className ? ` ${className}` : ''

  if (href) {
    styles += " block"

    return <a
      className={ styles }
      target={ newPage ? '_blank' : undefined }
      rel={ newPage ? 'noopener noreferrer' : undefined }
      href={ href }
      title={ title }>{ children }</a>
  } else {
    return <button
      className={ styles }
      onClick={ onClick }
      title={ title }
      disabled={ active || disabled }>{ active
        ? <CircularProgress size="1em" sx={{
          color: 'white',
          position: 'relative',
          top: '0.2rem',
        }}/>
        : children }</button>
    }
}
