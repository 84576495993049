import React from 'react'
import { GiftIcon } from '@heroicons/react/24/outline'

import { classNames } from '../utils/classes'

export type BigOption = {
  title: string
  subtitle: string
  body?: string
  icon?: typeof GiftIcon
  active?: boolean
  onClick: () => void
}

type BigOptionsProps = {
  options: Array<BigOption>,
}

export default function BigOptions({ options }: BigOptionsProps) {
  const optionsWithIcon = options.filter((option) => option.icon)

  return <div>
    { options.map((option, i) => {
      const Icon = option.icon

      return <a
          key={ i }
          onClick={ option.onClick }
          tabIndex={ 0 }
          className={ classNames(
            'block w-full h-48 cursor-pointer shadow p-6 my-3',
            'bg-white focus:outline-0 focus:border-green-300',
            'flex rounded border-4',
            !option.active ? 'border-gray-300' : 'border-pink-300 hover:border-green-500',
          ) }>
          { optionsWithIcon.length > 0 &&
            <div className="hidden w-32 mr-8 md:flex items-center justify-center">
              { !!Icon &&
                <Icon className={ classNames(
                  'w-14 h-14 md:w-24 md:h-24',
                  !option.active ? 'text-gray-300' : 'text-pink-300',
                ) } />
              }
            </div>
          }

          <div className="self-center">
            <h2 className="text-xl font-bold mb-2">{ option.title }</h2>
            <p className="text-base">{ option.subtitle }</p>
            { option.body && <p className="mt-2 text-sm">{ option.body }</p> }
          </div>
        </a>
      })
    }
  </div>
}
