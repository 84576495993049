import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate, Link } from 'react-router-dom'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { API_HOST, isAuthenticated, useAuthentication } from '../apollo-client'
import { useQueryParams } from '../utils/Query'

type LoginData = {
  email?: string
  codes?: string
  optIn?: boolean
}

type LoginModalParams = {
  codeVendor?: string
  presetCodes?: string
}

const vendorNames: {[key: string]: string} = {
  "qvc": "QVC",
  "cbs": "CBS",
}

export default function LoginModal({ codeVendor, presetCodes }: LoginModalParams) {
  const navigate = useNavigate()

  const queryParams = useQueryParams()
  const next = queryParams.get('next')

  // We don't actually know if the customer is opted-in or not, but it seems unlikely that someone
  // will need to opt-in multiple times on the same computer.
  const showMarketingOptIn = !localStorage.optedInToMarketing && codeVendor !== 'cbs'

  const [loggingIn, setLoggingIn] = useState(false)
  const [loginError, setLoginError] = useState<string|undefined>(undefined)
  const [loginDone, setLoginDone] = useState(false)

  const [presetEditable, setPresetEditable] = useState(false)

  async function login(data: LoginData) {
    if (!data.email) {
      return
    }

    setLoggingIn(true)
    setLoginError(undefined)
    localStorage.setItem('email', data.email)

    if (data.optIn) {
      localStorage.setItem('optedInToMarketing', 'true')
    }

    let resp: Response|undefined
    try {
      resp = await fetch(API_HOST + "/auth/by-email", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: data.email,
          vendorCodes: data.codes || '',
          origin: document.location.origin,
          optInToMarketing: !!data.optIn,
          next,
        }),
      })
    } catch (err) {
      console.error('Login error', err)
      setLoginError('Request failed')
    }

    setLoggingIn(false)

    if (resp) {
      const body = await resp.json()
      if (resp.status < 300) {
        setLoginDone(true)
        if (body.token) {
          // This was a new email, never registered, so we got back a token immediately.
          navigate(`/auth/link?token=${ body.token }&next=${ next ?? '/' }`)
        }
      } else {
        console.error('Bad status from login', resp.status, body.message)
        setLoginError(body.message || 'Unknown error')
      }
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>({
    defaultValues: {
      optIn: true,
      codes: presetCodes || '',
    },
  })

  const { isAuthenticated } = useAuthentication()

  if (isAuthenticated) {
    return <></>
  }

  const form = <>
    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Let's Get Started</h1>

    { codeVendor
      ? <>
        <p className="font-medium text-slate-600">{ "Thank you for your purchase" + (vendorNames[codeVendor] ? ` on ${ vendorNames[codeVendor] }` : '') + "!" }</p>

        <p className="font-medium text-slate-600">Please enter your email address
          { presetCodes ? ' to get started.' : ' and the eight letter code' +
            ( codeVendor === 'qvc'
              ? ' beginning with "QC" or "QV" printed on the card you received in the mail.'
              : ' provided to you.'
            )
          }
        </p>
      </>
      : <>
          <p className="font-medium text-slate-600 hover:text-indigo-500">Before we begin building your book, please provide us
        with your email address so your work is not lost.</p>

          <p className="font-medium text-slate-600 hover:text-indigo-500">Already working on a book? Enter your email address and we'll find it for you.</p>
      </>
    }

    <form className="mt-8 space-y-3" onSubmit={ handleSubmit(login) }>
      <div>
        <div>
          <label htmlFor="email-address" className="text-slate-600 mb-2 block">Email address</label>
          <input id="email-address" type="email" autoComplete="email" required data-cy="email-address-input"
            {...register('email', { required: true })}
            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 shadow placeholder-gray-100 text-gray-900
                       rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-lg sm:text-md"
            placeholder="Email address" />
        </div>

        { codeVendor && (
          presetCodes && !presetEditable
          ? <div className="mt-4">
            <label htmlFor="codes" className="text-slate-600 mb-2 block">Unique code(s)</label>
            <span className="font-bold text-slate-600">{ presetCodes }</span>
            <button
              onClick={ () => { setPresetEditable(true) } }
              className="ml-2 text-slate-600 hover:text-indigo-500 underline text-sm">
                <XMarkIcon className="inline-block w-4 h-4 top-[-2px] relative" />
              </button>
          </div>
          : <div className="mt-4">
              <label htmlFor="codes" className="text-slate-600 mb-2 block">Unique code(s)</label>
              <input id="codes" type="text" autoComplete="none" required data-cy="codes-input"
                {...register('codes', { required: true })}
                defaultValue={ presetCodes || '' }
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-100 text-gray-900
                          rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-lg sm:text-md"
                placeholder="QVKEJEZR, QCFJSAEE, ..." />
              <span className="text-sm text-gray-500">Have more than one code? Seperate them with commas.</span>
            </div>
        )}

        { showMarketingOptIn &&
          <div className="flex items-center p-4 mt-4 bg-gray-50 rounded-md">
            <input
              type="checkbox"
              className="
                shadow flex-shrink-0 form-tick appearance-none cursor-pointer h-6 w-6 mt-[3px] border border-gray-300
                rounded checked:bg-black checked:border-transparent focus:outline-none
              "
              id="opt-in-login"
              {...register('optIn', { required: false })}
            />
            <label className="ml-4 text-sm cursor-pointer" htmlFor="opt-in-login">
              You may occasionally contact me about new products, updates, and discounts.
            </label>
          </div>
        }
      </div>
      { errors.email && <p className="py-2 text-red-700">Please enter a valid email address.</p> }
      { loginError && (
        loginError === 'code already assigned'
        ? <p className="py-2 text-red-700">This code has already been assigned to a different email address. Please contact us for assistance.</p>
        : <p className="py-2 text-red-700">There was an error logging you in. Please try again.</p>
      )}

      <div>
        <button type="submit" className="group mt-5 relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
            </svg>
          </span>
          { loggingIn ?
            <CircularProgress />
            : <>Continue</>
          }
        </button>
      </div>

      { codeVendor
        ? <p className="text-slate-600 text-sm pt-2">Don't have an email address? Have another question? <Link
            className="text-pink-300 underline"
            to="/help"
            >Contact us</Link> or call us at 855-584-4029.
          </p>
        : <p className="text-slate-600 text-sm pt-2 text-center">Having trouble? <Link
          className="text-pink-300 underline"
          to="/help"
          >Contact us</Link>
          </p>
      }
    </form>
  </>

  return <>
    <div className="fixed top-0 left-0 right-0 bottom-0 z-50
                    backdrop-blur flex items-center justify-center">
      <div className="max-w-md w-full space-y-6 p-8 bg-white rounded">

        { loginDone ?
          <>
            <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Login Link Sent</h1>
            <p>We have sent a login link to your email address. Be sure to check your spam folder.</p>
            <p>If you don't receive it in the next several minutes, please&nbsp;
              <a onClick={ () => setLoginDone(false) } className="text-indigo-500 hover:text-indigo-600 cursor-pointer">try again</a>
              &nbsp;or <a className="text-indigo-500 hover-text-indigo-600 cursor-pointer" href="mailto:help@sendheirloom.com">contact us</a>.
            </p>
          </>
        :
          form
        }

      </div>
    </div>
  </>
}
