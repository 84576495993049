import React from 'react'
import { CheckIcon } from '@heroicons/react/24/solid'

import { classNames } from '../utils/classes'
import useSteps from '../utils/Steps'
import Link from './Link'

type StepStatus = 'current' | 'upcoming' | 'complete'

export default function Steps() {
  const { currentStep, currentFlow, steps, path } = useSteps()
  if (!currentFlow || !steps || currentStep?.showNav === false) return <></>

  return (
    <nav aria-label="Progress" className="w-full h-22 pt-4 pb-10 hidden md:block">
       <ol role="list" className="flex items-center justify-center">
        {steps.map((step, stepIdx) => {
          if (step.showInNav === false) {
            return null
          }

          let subsequentShownStep = false
          for (let i=stepIdx + 1; i < steps.length; i++){
            if (steps[i].showInNav !== false) {
              subsequentShownStep = true
              break
            }
          }

          const _path = path(step)

          return <li key={step.name} className={classNames(subsequentShownStep ? 'pr-8 sm:pr-20' : '', 'relative')}>
            {step.status === 'complete' ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-green-500" />
                </div>
                <Link
                  href={ _path }
                  disabled={ !_path }
                  testKey="step-name-container"
                  className="relative w-8 h-8 flex items-center justify-center bg-green-500 rounded-full hover:bg-green-700"
                >
                  <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                  <div className="mt-16 text-xs text-green-500 uppercase font-bold absolute">{ step.name }</div>
                </Link>
              </>
            ) : step.status === 'current' ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-green-300" />
                </div>
                <Link
                  href={ _path }
                  disabled={ !_path }
                  className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-green-500 rounded-full"
                  testKey="step-name-container"
                  ariaCurrent="step"
                >
                  <span className="h-2.5 w-2.5 bg-green-500 rounded-full" aria-hidden="true" />
                  <div className="mt-16 text-xs text-green-500 uppercase font-bold absolute">{ step.name }</div>
                </Link>
              </>
            ) : (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-green-300" />
                </div>
                <Link
                  href={ _path }
                  disabled={ !_path }
                  testKey="step-name-container"
                  className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-green-300 rounded-full hover:border-gray-400"
                >
                  <span
                    className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <div className="mt-16 text-xs text-green-500 uppercase font-bold absolute">{ step.name }</div>
                </Link>
              </>
            )}
          </li>
        })}
      </ol>
    </nav>
  )
}

