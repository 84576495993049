import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { BuilderFlow, MediaStep } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import NewDesignOptions from '../components/NewDesignOptions'

export default function NewDesignSelection() {
    const { orderId } = useParams()

    const { setStepState } = useStepContext()
    useEffect(() => {
        setStepState({
            flow: BuilderFlow,
            step: MediaStep,
            args: { orderId },
        })
    }, [orderId])

    // an orderId is always expected here, since this is for the BuilderFlow
    return orderId ? <NewDesignOptions orderId={ orderId } /> : <></>
}