import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import AddressForm from '../components/AddressForm'
import DestComponentEditor from '../components/DestComponentEditor'
import useSteps, { EGiftFlow, EGiftEmailStep } from '../utils/Steps'
import type { DestinationFragment } from '../graphql/__generated__'
import { useStepContext } from '../utils/StepContext'
import { CircularProgress } from '@mui/material'
import { useRedirectWithDesignDestination } from '../utils/RedirectWithDesignDestination'

export default function SendEGiftCard() {
  const navigate = useNavigate()
  const { orderId, designId, destinationId } = useParams()

  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow: EGiftFlow,
      step: EGiftEmailStep,
      args: { orderId, designId, destinationId },
    })
  }, [orderId, designId, destinationId])

  const { navigateNext, navigatePrev, currentPath, currentStep, hasReturn } = useSteps()

  const { loading } = useRedirectWithDesignDestination({
    orderId, designId, destinationId,
    skip: currentStep !== EGiftEmailStep,
    currentPath,
  })

  if (!orderId) {
    return <>Missing order id</>
  }

  if (loading) {
    return <div className="flex my-4 justify-center">
      <CircularProgress />
    </div>
  }

  return <>
    <div className="flex flex-col">
      <h1 className="text-2xl">Send an E-Gift Card</h1>
      <p className="pt-1 pb-6">
        A digital gift card is the best way to gift your video book to a friend or loved one.
        Your recipient will receive a link to build their video book, and we will ship it
        directly to them, fully loaded and ready to enjoy. It's the easiest way to share your gift.
      </p>

      <DestComponentEditor
        form={ AddressForm }
        formOptions={{ digital: true }}
        orderId={ orderId }
        destinationId={ destinationId }
        designId={ designId }
        hasReturn={ hasReturn }
        onSaveDest={ (dest: DestinationFragment) => {
          navigate(currentPath({ destinationId: dest.id }) || '', {
            replace: true,
          })
        }}
        onDone={ () => {
          navigateNext({})
        }}
        onBack={ () => {
          hasReturn ? navigateNext({}) : navigatePrev({})
        }}
      />
    </div>
  </>
}
