import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { CircularProgress } from '@mui/material'
import { useLazyQuery } from '@apollo/client'

import { useAuthentication, navigateToLogin } from '../apollo-client'
import { OrdersDocument, OrdersQuery, OrdersQueryVariables, OrderState, OrderFilter } from '../graphql/__generated__'
import Button from './Button'
import BigOptions from './BigOptions'
import { formatDate } from '../utils/Date'
import { formatBooks } from '../pages/PreorderRefundListing'
import { pluralize } from './OrderSummaryTile'

export const UnknownOrder = "unknown"

type IssueOrderChooserProps = {
  onChosen: (orderId: string) => void
  onBack: () => void
}

export default function IssueOrderChooser({ onChosen, onBack }: IssueOrderChooserProps) {
  const navigate = useNavigate()

  const { customerId, isAuthenticated } = useAuthentication()

  const [query, { data, loading, error }] = useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument)
  useEffect(() => {
    if (isAuthenticated && customerId) {
      query({
        variables: {
          customerId,
          drafts: OrderFilter.NoDrafts,
        }
      })
    }
  }, [isAuthenticated, customerId, query])

  if (!isAuthenticated) {
    return <div>
      <p>You are not currently logged in. Logging in will help us find your order.</p>

      <BigOptions
        options={[
          {
            title: "Log In",
            subtitle: "I have an account, I'll log in now.",
            onClick: () => {
              navigateToLogin(navigate)
            }
          },
          {
            title: "Continue as Guest",
            subtitle: "I don't have an account associated with the book in question.",
            onClick: () => {
              onChosen(UnknownOrder)
            }
          },
        ]}
      />
    </div>
  }

  if (loading || !data) {
    return <div className="my-4 flex justify-center"><CircularProgress size="6em" /></div>
  }
  if (error) {
    return <p className="bg-red-100 p-2 my-2">Error loading orders, please try again.</p>
  }

  const orders = data.customerOrders

  return <div>
    <p className="mb-4">Please select the order which your issue affects. If your issue affects multiple orders,
    please complete this process for each one.</p>

    <ul className="space-y-2">
      { orders && orders.map( (order) => {
        if (order.isEGift) return false
        if (!order.designs) return false

        const destinationCount = order.designs.reduce((last, design) => last + (design.destinations?.length || 0), 0)

        // @ts-ignore TS refuses to believe that last will be initialized by us
        let addresses: string[] = order.designs.reduce((last: string[], design) => {
          return last.concat(design.destinations?.map((destination) => destination.address?.fullName?.trim() || '') || [])
        }, [])
        addresses = addresses.filter((address) => address)
        addresses = [...new Set(addresses)]

        let addressString = addresses.slice(0, 3).join(", ")
        if (addresses.length > 3) {
          addressString += ` and ${ addresses.length - 3 } more`
        } else if (addresses.length > 1) {
          addressString = addressString.replace(/, ([^,]*)$/, ", and $1")
        }

        return <li key={ order.id }>
          <a
            className="block cursor-pointer px-4 py-8 border border-gray-200"
            onClick={ () => onChosen(order.id) }>

            Order #{ order.name } on <time dateTime={ order.paidAt } className="font-bold">
              { formatDate(order.paidAt) }
            </time> sending <span className="font-bold">
              { formatBooks(order.designs.length) }
            </span> to <span className="font-bold">
              { addressString }
            </span>
          </a>
        </li>
      } ) }

      <li>
        <a
          className="block cursor-pointer px-4 py-8 border border-gray-200 font-bold"
          onClick={ () => onChosen(UnknownOrder) }>

          I don't see my order or it was a gift
        </a>
      </li>
    </ul>

    <Button
      type="secondary"
      className="mt-8"
      onClick={ onBack }
      >Back</Button>
  </div>
}
