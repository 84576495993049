import React from 'react'

import MusicBrowserItem from './MusicBrowserItem'
import { MUSIC } from '../utils/Audio'

type MusicBrowserProps = {
  onAdd: (songId: string) => void
  onOpenUpload(): void
}

export default function MusicBrowser({ onAdd, onOpenUpload }: MusicBrowserProps) {
  const onOpenYouTube = () => {
    // these DOM lookups are a bit hacky; but, there are no obvious ways
    // to manually open an Uppy plugin w/in the Uppy dashboard via their API
    const button: HTMLElement | null = document.querySelector('[data-uppy-acquirer-id="YouTube"] button')
    if (button) {
      button.click()
      window.setTimeout(() => {
        const checkbox: HTMLInputElement | null = document.querySelector('#uppy-DashboardContent-panel--YouTube input[type="checkbox"]')
        if (checkbox) {
          checkbox.checked = true
        }
        button.scrollIntoView()
      })
    }
  }

  return <div className="border border-gray-200 rounded bg-white">
    <h2 className="text-lg font-bold p-2 text-gray-800">Select New Music</h2>
    <ul className="space-y-2 overflow-y-auto max-h-[15em] bg-gray-50 border-t border-gray-200">
      { MUSIC.map(song => (
        <li key={ song.id }>
          <MusicBrowserItem
            songId={ song.id }
            action='add'
            onAction={ () => onAdd(song.id) }
          />
        </li>
      )) }
      <li key="custom-upload" onClick={ onOpenUpload } className="cursor-pointer">
        <div className="grid grid-cols-12 p-1 px-2">
          <div className="col-span-2"></div>
          <div className="col-span-9">
            <div className="font-bold">Add your own music</div>
            <div className="text-gray-500">Select any .mp3 file from your computer</div>
          </div>
          <div className="flex items-center justify-end">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
            </svg>
          </div>
        </div>
      </li>
      <li key="custom-youtube" onClick={ onOpenYouTube } className="cursor-pointer">
        <div className="grid grid-cols-12 p-1 px-2">
          <div className="col-span-2"></div>
          <div className="col-span-9">
            <div className="font-bold">Add from YouTube</div>
            <div className="text-gray-500">Search for your song on YouTube.com and provide the link</div>
          </div>
          <div className="flex items-center justify-end">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
            </svg>
          </div>
        </div>
      </li>
    </ul>
  </div>
}
