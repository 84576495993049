import { h } from 'preact'
import { UIPlugin } from '@uppy/core'

import { API_HOST } from '../../apollo-client'

// Tell Babel to transform JSX into preact.h() calls:
/** @jsxRuntime classic */
/** @jsx h */

export default class Phone extends UIPlugin {
  constructor (uppy, opts) {
    super(uppy, opts)
    this.id = this.opts.id || 'Phone'
    this.title = this.opts.title || 'Phone'
    this.type = 'acquirer'
    this.icon = () => <svg viewBox="0 0 32 32" width="32" height="32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <ellipse style="stroke: rgb(0, 0, 0); stroke-width: 0px; fill: rgb(133, 186, 246);" cx="16.207" cy="15.887" rx="14.793" ry="14.793"/>
      <path d="M 13.69 22.536 C 13.69 22.056 14.079 21.666 14.559 21.666 L 17.458 21.666 C 18.129 21.666 18.547 22.391 18.212 22.971 C 18.057 23.24 17.769 23.406 17.458 23.406 L 14.559 23.406 C 14.079 23.406 13.69 23.016 13.69 22.536 Z" style="fill: rgba(255, 255, 255, 0.85);"/>
      <path fill-rule="evenodd" d="M 9.051 8.977 C 9.051 7.056 10.609 5.498 12.53 5.498 L 19.488 5.498 C 21.409 5.498 22.966 7.056 22.966 8.977 L 22.966 22.892 C 22.966 24.814 21.409 26.371 19.488 26.371 L 12.53 26.371 C 10.609 26.371 9.051 24.814 9.051 22.892 L 9.051 8.977 Z M 13.69 7.238 L 13.69 8.107 C 13.69 8.587 14.079 8.977 14.559 8.977 L 17.458 8.977 C 17.94 8.977 18.328 8.587 18.328 8.107 L 18.328 7.238 L 19.488 7.238 C 20.449 7.238 21.227 8.016 21.227 8.977 L 21.227 22.892 C 21.227 23.852 20.449 24.632 19.488 24.632 L 12.53 24.632 C 11.57 24.632 10.791 23.852 10.791 22.892 L 10.791 8.977 C 10.791 8.016 11.57 7.238 12.53 7.238 L 13.69 7.238 Z" clip-rule="evenodd" style="fill-rule: evenodd; paint-order: stroke; stroke: rgb(255, 255, 255); fill: rgba(255, 255, 255, 0.85); stroke-width: 0px;"/>
    </svg>

    const defaultOptions = {}
    this.opts = { ...defaultOptions, ...opts }
  }

  async textLink (phoneNumber) {
    // It could theoretically be useful to use the host this was rendered on, but your
    // phone is unlikely to know what to do with 'localhost'
    let next = document.location.pathname

    let params = new URLSearchParams
    params.set("next", next)

    // This is a bit hacky, it might be better to make a GQL request which returns a
    // new authenticated URL. Rendering the useMutation hook in this Preact world might
    // be tricky though.
    params.set("token", localStorage.token)

    const link = "https://make.sendheirloom.com/auth/link?" + params.toString()

    try {
      const resp = await fetch(API_HOST + "/send-link-to-web", {
        method: "POST",
        body: JSON.stringify({
          link,
          phoneNumber,
          params: {
            utm_source: "link-to-phone",
            utm_campaign: "link-to-phone",
          },
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })

      return resp.ok
    } catch (e) {
      console.error("Error sending message", e)
      return false
    }
  }
  
  render () {
    const _this = this

    function submit(e) {
      e.preventDefault()

      _this.setPluginState({loading: true, error: false})
      _this.textLink(e.target.querySelector('input[type=tel]').value).then((resp) => {
        _this.setPluginState({loading: false})

        if (resp) {
          _this.setPluginState({done: true})
        } else {
          _this.setPluginState({error: true, done: false})
        }
      })
    }

    const state = _this.getPluginState()
    if (state.loading) {
      return <div>Sending message...</div>
    }
    if (state.done) {
      return <div>
        <p>We have sent a text message to your phone. Please open it on your phone and tap the link to continue.</p>
        <p className="mt-4">When your photos and videos have finished uploading on your phone, <a href="" className="bold underline text-blue-500">reload</a> to see them here.</p>
      </div>
    }

    return <form onSubmit={ submit }>
      <div className="mb-4">We can text you a link to this editor, so you can add photos and videos from your phone.</div>
      <label className="block text-gray-700 text-sm font-bold mb-4">
        Phone Number
        <input
          type="tel"
          placeholder="555-123-4567"
          className="shadow appearance-none border rounded w-full mt-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </label>
      <input className="cursor-pointer bg-pink-300 hover:bg-pink-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" />

      { state.error && <p className="p-2 my-2 bg-red-100">Error sending message, please try again.</p> }
    </form>
  }

  install () {
    const { target } = this.opts
    if (target) {
      this.mount(target, this)
    }
  }

  uninstall () {
    this.unmount()
  }
}
