import React from 'react'

import MediaStack from './MediaStack'
import Button from './Button'

import type { OrderFragment, DesignWithDestinationsFragment } from '../graphql/__generated__'

type DesignSummaryListingProps = {
  order: OrderFragment
  designId?: string
  form?: JSX.Element
  onEdit?: (design: DesignWithDestinationsFragment) => void
  onCancel?: () => void
  hideEmpty?: boolean
}

export default function DesignSummaryListing({ order, designId, form, onEdit, onCancel, hideEmpty }: DesignSummaryListingProps) {
  return <>
    { order.designs?.map((design, i) => {
      const selected = !!designId && design.design?.id === designId
      const isBlank = !!design.design?.isBlank

      if (hideEmpty && (isBlank || !design.design?.media?.length)) {
        return
      }

      if (!!designId && !selected) {
        return
      }

      return <div key={ design.design?.id ?? `design:${ i }` }>
        <div className="max-w-full mx-auto my-2 flex items-center">
          <h1 className="flex-grow text-md">Design { i + 1 }</h1>
          { isBlank
            ? <span className="italic">Intentionally Blank</span>
            : ((selected && !!onCancel) || (!selected && !!onEdit)) && <Button
              className="w-[8rem]"
              type={ selected ? "secondary" : undefined }
              onClick={ () => selected ? onCancel?.() : onEdit?.(design) }
            >{ selected ? "Cancel" : "Edit Design" }</Button>
          }
        </div>
        { !designId && <div className="max-w-full mx-auto my-4">
          {/* intentionally render MediaStack container when isBlank for extra spacing */}
          { !isBlank && (design.design?.media?.length
            ? <MediaStack media={ design.design?.media } />
            : <div className="p-4 bg-red-100 text-center">This book has no photos or videos.</div>
          ) }
        </div> }
        { selected && <>
          { form }
          { i !== (order.designs?.length ?? 0) - 1 && <div className="h-6"></div> }
        </> }
      </div>
    })}
  </>
}
