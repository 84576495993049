import React, { useEffect, useState, useRef } from 'react'

import { useVolume } from '../utils/Audio'
import { MediaEntryFragment } from '../graphql/__generated__'

type SynchronizedMusicPlayerProps = {
  media: MediaEntryFragment[]

  syncToMediaId: string
  gain: number
  playing: boolean
  currentTimeMs: number
}

// This is trying to replicate what the Worker does in rendering the video.
// It tries to play the background music in sequence, skipping any media, or
// clip from media, where that music is muted.
//
// - syncToMediaId is the piece of media who's video is being shown on screen. We
//   will attempt to play the appropriate background music behind it.
// - currentTimeMs is the time we should be playing within the media in question, with any
// of its clips ignored.
export default function SynchronizedMusicPlayer({ media, syncToMediaId, playing, currentTimeMs }: SynchronizedMusicPlayerProps) {
  const music = media.filter(m => m.type === 'music')
  const notMusic = media.filter(m => m.type !== 'music')
  let backgroundVolume: number = 0

  // Figure out where this clip will be placed in the overall video book.
  let offsetMs = 0
  for (let i = 0; i < notMusic.length; i++) {
    const m = notMusic[i]

    if (m.id === syncToMediaId) {
      // This is the clip who's video is being shown on screen.

      let offsetIntoClips = 0
      for (let j = 0; j < m.clips.length; j++) {
        const c = m.clips[j]
        if (c.startMs <= currentTimeMs) {
          if (c.endMs >= currentTimeMs) {
            // We're in this clip, let's figure out how much time was spent in
            // clips before it.
            currentTimeMs -= (c.startMs - offsetIntoClips)
            break
          } else {
            // This clip occurs before our segment, let's tally up the time.
            offsetIntoClips += c.endMs - c.startMs
          }
        } else {
          // This clip occurs after our segment, we don't care about it
          break
        }
      }

      offsetMs += currentTimeMs
      backgroundVolume = m.backgroundVolume
      break
    }

    // This is a clip that is before the clip shown on screen.

    if (m.backgroundVolume === 0) {
      continue
    }

    if (m.type === 'video' && m.clips) {
      for (let j = 0; j < m.clips.length; j++) {
        const c = m.clips[j]
        offsetMs += c.endMs - c.startMs
      }
    } else {
      offsetMs += (m.durationSeconds || 0) * 1000
    }
  }

  // We now want to offset by offsetMs into our background music selections.
  let musicTrack: MediaEntryFragment | null = null
  for (let i = 0; i < music.length; i++) {
    const m = music[i]
    const durationMs = (m.durationSeconds || 0) * 1000

    if (durationMs > offsetMs) {
      musicTrack = m
      break
    } else {
      offsetMs -= durationMs
    }
  }

  const audioRef = useRef<HTMLAudioElement>(null)

  useVolume(audioRef, backgroundVolume)

  useEffect(() => {
    if (audioRef.current) {
      if (!playing || !musicTrack?.signedEncodedLocation) {
        audioRef.current.pause()
        return
      }

      // Things get really janky if we try to update the currentTime every time
      // offsetMs changes. Fortunately whever we seek, we pause, so we are
      // guarenteed to get a play toggle before the user notices its out of sync.
      audioRef.current.currentTime = offsetMs / 1000
      audioRef.current.play()
    }
  }, [playing, audioRef.current])

  // If it's not playing, we can update the time immediately without risking
  // jank.
  useEffect(() => {
    if (audioRef.current && musicTrack?.signedEncodedLocation && !playing){
      audioRef.current.currentTime = offsetMs / 1000
    }
  }, [audioRef.current, musicTrack?.signedEncodedLocation, offsetMs, playing])


  return <audio
    crossOrigin="anonymous"
    ref={ audioRef }
    src={ musicTrack?.signedEncodedLocation || '' } />
}
