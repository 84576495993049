import type { DocumentNode } from 'graphql'

export function getFieldNames(doc: DocumentNode): string[] {
  let out = [];
  for (let i = 0; i < doc.definitions.length; i++) {
    const def = doc.definitions[i];

    if (def.kind === 'FragmentDefinition') {
      for (let j = 0; j < def.selectionSet.selections.length; j++) {
        const selection = def.selectionSet.selections[j];

        if (selection.kind === 'Field') {
          out.push(selection.name.value)
        }
      }
    }
  }

  return out
}
