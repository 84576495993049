import React, { useState, useRef, useMemo, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import Button from '../components/Button'
import DestinationSummaryListing from '../components/DestinationSummaryListing'
import { findDestination } from '../utils/Destination'
import { OrderDocument, OrderQuery, StockFragment } from '../graphql/__generated__'
import type { OrderFragment, DestinationFragment } from '../graphql/__generated__'
import { useAuthenticatedQuery } from '../apollo-client'
import useSteps from '../utils/Steps'
import { DesignWithDestinationsFragment } from './OrderEdit'
import { setLastTouchedDestinationForOrder } from '../utils/LastTouchedDestination'

export type FormRef = {
  submit: () => Promise<void>
  saving: boolean
}

type DestComponentEditorProps<TOptions = any> = {
  showSkip?: boolean
  orderId: string
  designId?: string
  destinationId?: string

  hasReturn: boolean
  onSaveDest?: (dest: DestinationFragment) => void
  onDone: () => void
  onBack?: () => void

  text?: {
    componentName?: string
    pageTitle?: string
    pageDescription?: React.ReactElement
    addMoreHint?: string
  }

  form: React.ElementType<DestFormProps>,
  formOptions?: TOptions,
}

export type DestFormProps<TOptions = any> = {
  ref: React.Ref<FormRef>
  options: TOptions
  order: OrderFragment
  stock?: StockFragment
  designId?: string
  destination?: DestinationFragment
  onSave: (destination: DestinationFragment) => void
  onError: (error: string) => void
}

export default function DestComponentEditor<TOptions = any>({
  showSkip,
  orderId,
  designId,
  destinationId,

  hasReturn,
  onSaveDest,
  onDone,
  onBack,

  text: {
    componentName,
    pageTitle,
    pageDescription,
    addMoreHint,
  } = {},

  form: FormComponent,
  formOptions,
}: DestComponentEditorProps<TOptions>) {
  const [saving, setSaving] = useState(false)

  const { navigateTo, currentStep } = useSteps()

  const {
    data: { orderStatus: order, stock } = {},
    loading,
    error: loadingError,
  } = useAuthenticatedQuery<OrderQuery>(OrderDocument, {
    variables: {
      orderId,
    },
  })

  useEffect(() => {
    if (destinationId) {
      setLastTouchedDestinationForOrder(orderId, destinationId)
    }
  }, [orderId, destinationId, setLastTouchedDestinationForOrder])

  const { destination, designCount, destinationCount } = useMemo(() => {
    if (!order) {
      return {}
    }

    return {
      destination: destinationId ? findDestination(order, destinationId) : undefined,
      designCount: order.designs?.length ?? 0,
      destinationCount: order.designs?.reduce((prev, { destinations }: DesignWithDestinationsFragment) => {
        return prev + (destinations?.length ?? 0)
      }, 0)
    }
  }, [destinationId, order])

  const formRef = useRef<FormRef | null>(null)

  const submit = async () => {
    if (!formRef.current?.submit){
      return
    }

    setSaving(true)
    await formRef.current.submit()
  }

  const onSave = (dest: DestinationFragment) => {
    setSaving(false)
    onSaveDest?.(dest)
    onDone()
  }

  const hasMany = (designCount && designCount > 1) || (destinationCount && destinationCount > 1)

  const buttons = <div className="mt-6">
    <div className="grid grid-cols-3">
      <div className="justify-self-start">
        { onBack &&
          <Button type="secondary" onClick={ () => onBack() }>Back</Button>
        }
      </div>

      <div className="justify-self-center">
        { showSkip && !hasMany &&
          <Button type="secondary" onClick={ () => onDone() }>Skip</Button>
        }
      </div>

      <div className="justify-self-end">
        <Button
          type="primary"
          onClick={ destinationId ? submit : onDone }
          active={ saving }>{ destinationId ? 'Save' : (hasReturn ? 'Done' : 'Next') }</Button>
      </div>
    </div>
  </div>

  const formWithButtons = order ? <>
    <FormComponent
      ref={ formRef }
      options={ formOptions }
      order={ order }
      stock={ stock }
      designId={ designId }
      destination={ destination }
      onSave={ onSave }
      onError={ () => {
        setSaving(false)
      } }
    />
    { buttons }
  </> : undefined

  return <div className="w-full md:w-[50rem] m-auto pb-4 flex flex-col">
    <div className="mb-2">
      <h1 className="text-xl font-bold">{ pageTitle }</h1>
    </div>
    { pageDescription ? <div className="mb-2">{ pageDescription }</div> : undefined }

    { loading && <div className="flex my-4 justify-center"><CircularProgress /></div> }
    { loadingError && <p className="p-2 m-4 bg-red-300">Error loading order.</p> }

    { order && <div className="mt-2">
      { hasMany ? <>
        <DestinationSummaryListing
          order={ order }
          designId={ designId }
          destinationId={ destinationId }
          onEdit={({ design }, destination) => {
            if (currentStep && design?.id && destination?.id && (design.id !== designId || destination.id !== destinationId)) {
              navigateTo(currentStep, {
                designId: design.id,
                destinationId: destination.id,
              }, {})
            }
          }}
          onCancel={() => {
            if (currentStep && destinationId) {
              navigateTo(currentStep, {
                designId: undefined,
                destinationId: undefined,
              }, {})
            }
          }}
          form={ formWithButtons }
          componentName={ componentName }
        />
        { !destinationId && buttons }
      </> : <>
        { formWithButtons }

        { addMoreHint &&
          <p className="my-4 text-center text-slate-500 text-sm">
            { addMoreHint }<br />
            Setup the first now and you will be able to create more on a later page.
          </p>
        }
      </> }
    </div> }

  </div>
}
