import Skeleton from '@mui/material/Skeleton'

export default function LoadingMediaManager() {
  return <div className="grid grid-cols-2 md:grid-cols-5">
    <StyledSkeleton />
    <StyledSkeleton />
    <StyledSkeleton />
    <StyledSkeleton />
    <StyledSkeleton />
  </div>
}

function StyledSkeleton() {
  return <Skeleton width="10rem" height="10rem" classes={{
    root: "!bg-white border shadow-lg !rounded-none !transform-none mx-auto mx-3 !mt-6 !md:mt-0"
  }} />
}
