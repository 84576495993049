import Uppy from '@uppy/core'
import type { Uppy as UppyType } from '@uppy/core'
import Tus from '@uppy/tus'
import XHRUpload from '@uppy/xhr-upload'
import { setUploadFunc } from 'polotno/config'

import { API_HOST } from '../apollo-client'

const MB = 1024 * 1024
const GB = MB * 1024

type UppyTarget = 'media' | 'simple'

export default function createUppy(target: UppyTarget): UppyType {
  const uppy = new Uppy({
    meta: { type: 'avatar' },
    restrictions: {
      maxFileSize: 15 * GB,
//      allowedFileTypes: ['image/*', 'video/*', 'audio/*', 'video/3gpp'],
    },
    autoProceed: true,
    locale: {
      strings: {
        youCanOnlyUploadFileTypes: `Unfortunately the file you are trying to upload
          is not a video, audio, or picture file. Is it an archive (ending in ".zip")
          which has to be extracted, a webpage (".html"), or a pdf (".pdf")?`,
      },
    }
  })

  if (target === 'media'){
    // Our fancy upload and rendering system for big videos and files
    // which will be loaded onto books.
    uppy.use(Tus, {
      endpoint: `${ localStorage.DEBUG_local_tusd ? 'http://localhost:2344' : 'https://upload.sendheirloom.com' }/files/`,

      // Larger is a bit better, as it has to include all paths in the final call,
      // and too large of a header tends to cause issues.
      chunkSize: 15 * MB,

      // Allow broken files to be reuploaded
      removeFingerprintOnSuccess: true,

      retryDelays: [100, 1000, 3000, 5000],

      limit: 10,
    })
  } else {
    // A simple endpoint which uploads files up to 16 MB to S3, and returns the URL
    uppy.use(XHRUpload, {
      endpoint: API_HOST + '/upload',
      formData: true,
    })

    uppy.setOptions({
      restrictions: {
        maxFileSize: 16 * MB,
        allowedFileTypes: ["image/*"],
      }
    })
  }

  return uppy
}

type DeferredUpload = {
  reject: (error: string) => void
  resolve: (path: string) => void
}

let polotnoUppy: UppyType | null = null
let waitingOnUploads: {[key: string]: DeferredUpload} = {}
export function initPolotnoUploader() {
  if (!polotnoUppy) {
    polotnoUppy = createUppy('simple')
    polotnoUppy.on('upload-success', (file, resp) => {
      if (!file) {
        return
      }
      waitingOnUploads[file.id]?.resolve(resp.body.url)
      delete waitingOnUploads[file.id]
    })
    polotnoUppy.on('upload-error', (file, error) => {
      console.error("Error uploading", file, error)
      if (!file) {
        return
      }
      waitingOnUploads[file.id]?.reject(error.toString())
      delete waitingOnUploads[file.id]
    })
  }

  const upload = async (file: File) => {
    const id = polotnoUppy?.addFile({
      name: file.name,
      type: file.type,
      data: file,
    })

    if (!id) {
      throw new Error("Didn't get an id from addFile")
    }

    let resolve: ((path: string) => void) | undefined = undefined
    let reject: ((error: string) => void) | undefined = undefined
    const promise = new Promise<string>((res, rej) => {
      [resolve, reject] = [res, rej]
    })

    if (resolve && reject) {
      waitingOnUploads[id] = {resolve, reject}
    } else {
      throw new Error("Promise creation was not synchronous")
    }

    return promise
  }

  setUploadFunc(upload)
}
