import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import type { RefObject } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useBeforeunload } from 'react-beforeunload'

import Uploader from './Uploader'
import MediaListing from './MediaListing'
import MusicListing from './MusicListing'
import ModalPreview from './ModalPreview'
import Button from './Button'
import LoadingMediaManager from './LoadingMediaManager'
import MediaUsageBar from './MediaUsageBar'
import TooLongWarning from './TooLongWarning'
import { DesignDocument, AppendMediaDocument, UpdateMediaDocument, DesignFragment, AppendMediaMutation, UpdateMediaMutation, UpdateDesignMetadataDocument, AppendMediaMutationVariables, UpdateMediaMutationVariables, UpdateDesignMetadataMutation, UpdateDesignMetadataMutationVariables } from '../graphql/__generated__'
import type { MediaEntryFragment } from '../graphql/__generated__'
import { DesignValidation } from '../utils/OrderValidation'
import { hasUnfinishedMedia } from '../utils/Design'

const MUTATION_DEBOUNCE = 1000
const POLLING_INTERVAL = 2000

type MediaManagerProps = {
  design: DesignFragment
  designValidation?: DesignValidation
  designDurationExceedBalancesUsed?: boolean
  dropContainer: RefObject<HTMLElement>
  onSaveStateChange: (saving: boolean) => void
}

export default function MediaManager({ design, designValidation, dropContainer, onSaveStateChange }: MediaManagerProps) {
  // re-polling this query will update the Apollo cache for the parent component's query
  // this means we can continue to rely on the design prop w/o accessing the data here
  const [, { startPolling, stopPolling }] = useLazyQuery(DesignDocument, {
    notifyOnNetworkStatusChange: true,
    variables: {
      designId: design.id,
    },
  })

  const [appendMedia, {
    loading: appendSaving,
    error: appendSaveError,
  }] = useMutation<AppendMediaMutation, AppendMediaMutationVariables>(AppendMediaDocument)
  const [updateMedia, {
    loading: updateSaving,
    error: updateSaveError,
  }] = useMutation<UpdateMediaMutation, UpdateMediaMutationVariables>(UpdateMediaDocument)

  const saving = appendSaving || updateSaving
  useEffect(() => onSaveStateChange(saving), [onSaveStateChange, saving])

  const [updateDesignMetadata] = useMutation<
    UpdateDesignMetadataMutation,
    UpdateDesignMetadataMutationVariables
  >(UpdateDesignMetadataDocument, {
    onError: error => {
      console.error("Error updating design metadata", error)
    },
  })

  const [changed, setChanged] = useState<boolean>(false)
  const [processingAddQueue, setProcesingAddQueue] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState(false)
  const [showUpload, setShowUpload] = useState(false)

  const [media, setMedia] = useState(design.media ?? [])
  useEffect(() => {
    // handle changes in design from polled lazy query checking for media processing state
    // to avoid "jumping" of media, we merge the existing state w/updated objects from API based on IDs
    const newMedia = design.media ?? []
    setMedia(existingMedia => existingMedia.map(em => {
      return newMedia.find(nm => nm.id === em.id) ?? em
    }))
  }, [design])

  const { music, notMusic } = useMemo(() => media.reduce((result, m) => {
    if (m.type === 'music') {
      result.music.push(m)
    } else {
      result.notMusic.push(m)
    }
    return result
  }, {
    music: [] as MediaEntryFragment[],
    notMusic: [] as MediaEntryFragment[],
  }), [media])

  const mediaIdsToFragments = (mediaIds: string[]) => mediaIds
    .map(mediaId => media.find(m => m.id === mediaId))
    .filter<MediaEntryFragment>((m): m is MediaEntryFragment => m?.__typename === 'MediaEntry')

  // addMedia can be called multiple times in quick succession by Uppy when
  // uploading files in bulk; create a basic queue for tracking the pending
  // media to be added to the design and debounce with a simple timeout
  // NOTE: we don't use apollo-link-debounce here because there isn't a
  // great way to consistently empty the queue when mutation is started and
  // the onCompleted is fired for each request (not debounced). With some
  // extra engineering and/or patch to apollo-link-debounce, this could
  // probably be updated but it isn't worth the additional effort.
  const addMediaQueue = useRef<string[]>([])
  const addMediaTimeout = useRef<ReturnType<typeof setTimeout> | undefined>()
  const addMedia = (id: string) => {
    setChanged(true)
    setProcesingAddQueue(true)
    const onFinish = () => {
      setChanged(false)
      setProcesingAddQueue(false)
    }

    addMediaQueue.current.push(id)
    addMediaTimeout.current && clearTimeout(addMediaTimeout.current)
    addMediaTimeout.current = setTimeout(async () => {
      if (!addMediaQueue.current.length) {
        return onFinish()
      }

      const newMediaIDs = addMediaQueue.current
      addMediaQueue.current = []

      await appendMedia({
        variables: {
          designID: design.id,
          media: newMediaIDs,
        },
        onCompleted: async ({ appendMedia: { media: _media } }) => {
          // include newly added media on top of existing state
          if (_media) {
            const newMedia: MediaEntryFragment[] = []
            newMediaIDs.forEach(id => {
              // built-in songs have the same ID, so we need to specifically find a single match
              // instead of filtering _media by newMediaIDs which could match additional results
              const matchedEntry = _media.find(m => m.id === id)
              if (matchedEntry) {
                newMedia.push(matchedEntry)
              }
            })
            if (newMedia.length) {
              setMedia(existingMedia => [...existingMedia, ...newMedia])
            }
          }

          // if the design was marked as blank, remove that metadata now that media was added
          if (design.isBlank) {
            await updateDesignMetadata({
              variables: {
                designId: design.id,
                metadataJSON: JSON.stringify({ blank: false })
              },
            })
          }
        },
        // the queue was already drained, but we don't try again in case there was a partial success of media uploads
        // NOTE: eventually we could use onError to refetch the design from the server and make a better determination
        // of what needs to happen, but for now we will rely on having errors logged to Sentry for further investigation
      })

      // reset state whether or not mutation is successful or fails
      onFinish()
    }, MUTATION_DEBOUNCE)
  }

  const removeMediaById = (ids: string[]) => {
    const mediaIds = media
      .filter(m => !ids.includes(m.id))
      .map(m => m.id)

    saveMedia(mediaIds)
  }

  // built-in music has the same ID, so we also check by index (specific to media of type music)
  const removeMusicByIdAndIndex = (id: string, index: number) => {
    let currentMusicIndex = 0 // used to keep track of where we are in the music media
    const mediaIds = media
      .filter(m => {
        if (m.type !== 'music') {
          // don't remove anything but music
          return true
        }

        const isMatch = m.id === id && currentMusicIndex === index
        currentMusicIndex++

        return !isMatch
      })
      .map(m => m.id)

    saveMedia(mediaIds)
  }

  const saveMedia = async (mediaIds: string[]) => {
    setMedia(mediaIdsToFragments(mediaIds))

    setChanged(true)
    const onFinish = () => {
      setChanged(false)
    }

    await updateMedia({
      variables: {
        designID: design.id,
        media: mediaIds,
      },
      context: {
        debounceKey: 'media-manager',
        debounceTimeout: MUTATION_DEBOUNCE,
      },
      onError: () => {
        // reset media state to last known value from design query
        setMedia(design.media ?? [])
      },
    })

    // reset state whether or not mutation is successful or fails
    onFinish()
  }

  const onReorderMusic = async (musicSegmentIds: string[]) => {
    // reorder music segments based on the passed in IDs, putting
    // the music after the non-music as per usual
    let lastUsedAt: Record<string, number> = {}
    let mediaIds = media
      .map((m, i) => {
        if (m.type !== 'music') {
          return `${ i.toString().padStart(9, '0') }:${ m.id }`
        }

        const musicIndex = musicSegmentIds.indexOf(m.id, lastUsedAt[m.id] ?? 0)
        lastUsedAt[m.id] = musicIndex + 1

        return `${ (10e5 + musicIndex).toString().padStart(9, '0') }:${ m.id }`
      })

    mediaIds.sort()
    mediaIds = mediaIds
      .map(id => id.split(':')[1])

    saveMedia(mediaIds)
  }

  useBeforeunload((event) => {
    if (changed) {
      event.preventDefault()
    }
  });

  if (media) {
    if (hasUnfinishedMedia(media)) {
      startPolling(POLLING_INTERVAL)
    } else {
      stopPolling()
    }
  }

  // We memoize to prevent the polling from rerendering when nothing
  // has actually changed.
  return useMemo(() => {
    if (!media) {
      return <div className="mb-4"><LoadingMediaManager /></div>
    }

    return <>
      <Uploader
        dropContainer={ dropContainer }
        onUploaded={ (entry: { id: string }) => addMedia(entry.id) }
        showUpload={ showUpload }
        onUploadShown={ () => setShowUpload(false) }
        waitingForSave={ processingAddQueue }
      >
        <div className="my-4">
          <MediaListing
            segments={ notMusic }
            musicSegments={ music }
            allMedia={ media }
            designId={ design.id }
            onRemove={ removeMediaById }
            onReorder={ saveMedia }
            onOpenUpload={ () => setShowUpload(true) }
          />

          { appendSaveError &&
            <div className="p-2 my-2 bg-red-100">
              There was an error adding your file. Please try again.
            </div>
          }
          { updateSaveError &&
            <div className="p-2 my-2 bg-red-100">
              There was an error updating your media. Please try again.
            </div>
          }
        </div>
      </Uploader>


      <div className="mt-16 mb-2">
        <MusicListing
          segments={ music }
          onAdd={ addMedia }
          onRemove={ removeMusicByIdAndIndex }
          onReorder={ onReorderMusic }
          onOpenUpload={ () => setShowUpload(true) }
        />
      </div>

      <div className="h-10">
        <div className="grid grid-cols-3">
          <MediaUsageBar design={ design } designValidation={ designValidation } />

          { design.generation && <div className="flex">
            <Button
              onClick={ () => setShowPreview(true) }
              disabled={ notMusic.length === 0 }
              title={ notMusic.length === 0 ? "You must add at least one photo or video to preview your design." : "Watch a preview of your design, just as it will go onto your video book." }
              className="m-auto"
              type="tertiary"
            ><span className="md:inline hidden">Watch </span>Preview</Button>
            <ModalPreview
              show={ showPreview }
              onClose={ () => setShowPreview(false) }
              generation={ design.generation }
              designId={ design.id } />
          </div> }
        </div>
      </div>

      <TooLongWarning designValidation={ designValidation } />

      <div className="flex justify-center pointer-events-none relative">
        <div className="absolute top-8">
          { saving
            ? <span className="text-base font-bold text-gray-300">Saving...</span>
            : (!appendSaveError && !updateSaveError && <span className="text-base font-bold text-green-300">Saved</span>)
          }
        </div>
      </div>
    </>
  }, [media, processingAddQueue, showPreview, showUpload, design.id, design.generation, updateSaveError, appendSaveError, saving])
}
