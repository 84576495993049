// @ts-ignore
import escapeHtml from 'escape-html'

import { renderHTMLToCanvas, getTextHeight } from './Draw'

export const WIDTH_DPI = 300
export const HEIGHT_DPI = 300

export interface CanvasRef {
  canvas: HTMLCanvasElement
  unload: () => void
}

export function messageAsHTML(note: string): string {
  // TODO Protect better against XSS
  note = escapeHtml(note)

  // Add paragraphs everywhere, but not if it's many lines (see #3112)
  if ((note.match(/\n/g) || []).length < 8) {
    note = note.replace(/\n+/g, '</p><p>')
  } else {
    note = note.replace(/\n{2,}/g, '</p><p>')
    note = note?.replace(/\n/g, '<br/>')
  }
  note = `<p>${note}</p>`

  return note
}

export async function renderMessage(note: string, size: string): Promise<CanvasRef|null> {
  let widthIn = 6
  let heightIn = 4
  if (size === '4x4') {
    widthIn = 4
  }

  const pixelWidth = widthIn * WIDTH_DPI
  const pixelHeight = heightIn * HEIGHT_DPI

  let canvas = document.createElement('canvas')
  canvas.width = pixelWidth
  canvas.height = pixelHeight
  canvas.style.display = 'none'
  canvas.style.width = `${ pixelWidth/2 }px`
  canvas.style.height = `${ pixelHeight/2 }px`
  document.body.appendChild(canvas)

  // TODO Protect better against XSS
  note = messageAsHTML(note)

  const extraCSS = localStorage.DEBUG_extra_message_css || ''
  let html: string = ''

  var fontSize: number
  for (fontSize = 1.3; fontSize > 0.5; fontSize -= .1) {
    html = `
    <style>
      .printed {
        overflow: hidden;
        font-family: Avenir, "Avenir Next", "Avenir New", Helvetica, "Helvetica Neue", sans-serif;
        font-size: 60px;
        line-height: 1.5;
        margin-top: 7.5px;
        height: ${ pixelHeight - 15 }px;
      }

      .message {
        position: relative;
        box-sizing: border-box;
        height: 100%;
        padding: 2em;
      }

      .debug {
        font-size: .6em;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        font-family: monospace;
      }

      .message-outer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      .body p {
        margin: 0 0 .75em;
      }

      .body p:last-child {
        margin-bottom: 0;
      }

      ${ extraCSS }
    </style>
    <div class="printed">
      <div class="message">
        <div class="message-outer">
          <div class="message-inner">
            <div class="body" style="font-size: ${fontSize}em">
              ${note}
            </div>
          </div>
        </div>
      </div>
    </div>`

    const textHeight = getTextHeight(html, pixelWidth)
    if (textHeight <= (pixelHeight - 200)) {
      break
    }
  }

  await renderHTMLToCanvas(html, canvas, pixelWidth, pixelHeight)

  let unloadCanvas = () => {
    document.body.removeChild(canvas)
  }

  return {
    canvas,
    unload: unloadCanvas,
  }
}
