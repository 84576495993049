import React from 'react'

import { formatDuration } from '../utils/Duration'
import type { DesignFragment } from '../graphql/__generated__'
import { DesignValidation, MAXIMUM_DURATION_USER_FRIENDLY } from '../utils/OrderValidation'
import { classNames } from '../utils/classes'
import { hasUnfinishedMedia } from '../utils/Design'

type MediaUsageBarProps = {
  design: DesignFragment
  designValidation?: DesignValidation
}

export default function MediaUsageBar({ design, designValidation }: MediaUsageBarProps) {
  const usagePercent = Math.min(100 * design.durationSeconds / MAXIMUM_DURATION_USER_FRIENDLY, 100)

  return (
    <div className="flex flex-col justify-end pr-1 pb-1">
      <div className="text-xs md:text-sm pb-1 text-gray-500">
        Duration: { design.media && hasUnfinishedMedia(design.media) ? '...' : formatDuration(design.durationSeconds) }
      </div>

      <div className="w-full bg-gray-25 h-3 rounded">
        <div
          className={ classNames(
            'h-3 rounded',
            (() => {
              if (designValidation?.designDurationExceedsMaximum) {
                return 'bg-red-700'
              }
              if (design.durationSeconds > MAXIMUM_DURATION_USER_FRIENDLY) {
                return 'bg-orange-300'
              }
              return 'bg-gray-100'
            })(),
          ) }
          style={{ width: `${ usagePercent }%` }}
        />
      </div>
    </div>
  )
}

