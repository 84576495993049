import { useApolloClient } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import client, { TOKEN_VAR } from '../apollo-client'
import { useQueryParams } from '../utils/Query'
import ProgressOverlay from './ProgressOverlay'

export default function SudoAndRedirect() {
  const { email } = useParams()

  let query = useQueryParams()

  let next = query.get('next')
  if (next && !next.startsWith('/')) {
    next = '/' + next
  }

  // set/unset email in local storage and TOKEN_VAR
  useEffect(() => {
    const loginState = TOKEN_VAR()
    if (email) {
      loginState.sudo = email
      localStorage.setItem('sudo', email)
    } else {
      loginState.sudo = undefined
      localStorage.removeItem('sudo')
    }
    TOKEN_VAR(loginState)
  }, [])

  const [clearingCache, _setClearingCache] = useState<boolean>(true)
  const cacheCleared = () => _setClearingCache(false)

  // resets the Me query, etc.
  useEffect(() => {
    client.resetStore().finally(cacheCleared)
  }, [])

  return clearingCache ? <ProgressOverlay /> : <Navigate to={ next ?? "/" } replace />
}
