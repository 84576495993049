import React, { useEffect } from 'react'
import type { ChangeEvent } from 'react'

import { NetworkStatus, useMutation } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'

import { ShippingOptionsDocument, UpdateShippingChoiceDocument } from '../graphql/__generated__'
import type { ShippingOptionFragment, ShippingChoice, DestinationFragment, OrderFragment } from '../graphql/__generated__'
import { formatPrice, resetPaymentIntent } from '../utils/Price'
import { useAuthenticatedQuery, useAuthentication } from '../apollo-client'
import { isCustomCover } from '../utils/Covers'

type ShippingOptionsFormProps = {
  order: OrderFragment
  destination: DestinationFragment
  onSubmit?: (shippingOptionId: string) => void
}

export default function ShippingOptionsForm({ order, destination }: ShippingOptionsFormProps) {
  const [updateShippingChoice, { error: saveError }] = useMutation(UpdateShippingChoiceDocument, {
    update(cache) {
      resetPaymentIntent(cache, order)
    },
  })

  const { customerData } = useAuthentication()

  const { loading, error, data, networkStatus } = useAuthenticatedQuery(ShippingOptionsDocument, {
    variables: {
      destinationId: destination.id,
    },
    notifyOnNetworkStatusChange: true,

    // This is necessary because our attempt to force this to refetch when the address
    // is changed was unsuccessful. We cache rates on the server, so it's not much
    // trouble to refetch.
    fetchPolicy: 'no-cache'
  })

  let currentValue = ""
  if (destination.shippingSpeed) {
    currentValue = `speed::${ destination.shippingSpeed }`
  } else if (destination.shippingServiceCode) {
    currentValue = `code::${ destination.shippingServiceCode }`
  }

  let options: Array<ShippingOptionFragment> = data?.shippingOptions || []
  options = [...options].sort((a, b) => a.cost.actualInCents - b.cost.actualInCents)

  useEffect(() => {
    if (destination && !currentValue) {
      const hasExpressBalance = !!customerData?.balance?.products?.some(balanceProduct =>
        balanceProduct.product.id === "express-shipping" && balanceProduct.quantity > 0
      )

      let choice: ShippingChoice = {}
      if (hasExpressBalance && options.find(o => o.shippingSpeed === 'express')) {
        choice.shippingSpeed = 'express'
      } else if (options.find(o => o.shippingSpeed === 'economy')) {
        choice.shippingSpeed = 'economy'
      } else if (options.length) {
        const { serviceCode, shippingSpeed } = options[0]
        choice.serviceCode = serviceCode
        choice.shippingSpeed = shippingSpeed
      } else {
        // no choice to select; hook runs again if options becomes non-empty
        return
      }

      updateShippingChoice({
        variables: {
          destinationId: destination.id,
          choice,
        },
      })
    }
  }, [destination, currentValue, customerData?.balance?.products, options])

  function saveChange(e: ChangeEvent<HTMLInputElement>) {
    const [_type, val] = e.target.value.split('::')

    let choice: ShippingChoice = {}
    if (_type === 'code') {
      choice.serviceCode = val
    } else if (_type === 'speed') {
      choice.shippingSpeed = val
    } else {
      throw new Error(`Unknown type ${_type}`)
    }

    updateShippingChoice({
      variables: {
        destinationId: destination.id,
        choice,
      },
    })
  }

  if (loading || networkStatus === NetworkStatus.refetch) {
    return <div className="flex justify-center my-4"><CircularProgress /></div>
  }

  if (error) {
    return <div className="p-2 bg-red-300">Error loading shipping rates. Please confirm that the address is valid.</div>
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      { saveError &&
        <div className="p-4 bg-red-300">Error saving shipping choice.</div>
      }

      <ul>
        {options.map(({ shippingSpeed, serviceCode, displayName, displayArrival, cost }) => {
          const id = (shippingSpeed ? "speed::" + shippingSpeed : "code::" + serviceCode)
          return <li key={ id } className="mb-4">
            <label>
              <input
                type="radio"
                name="shippingOption"
                className="mr-2"
                data-cy={`shipping-option-${displayName}`}
                onChange={ saveChange }
                checked={ currentValue === id }
                value={ id } />

              { displayName }
              { cost.actualInCents > 0 &&
                <span>
                  &nbsp;-&nbsp;
                  { cost.actualInCents < cost.listInCents ?
                  <>
                    <del>{ formatPrice(cost.listInCents) }</del>
                    <span>{ formatPrice(cost.actualInCents) }</span>
                  </> :
                  <>
                    <span>{ formatPrice(cost.actualInCents) }</span>
                  </>
                  }
                </span>
              }
              { displayArrival &&
                <div className="text-sm text-gray-600">Arrives { displayArrival }</div>
              }
            </label>
          </li>
        })}
      </ul>

      {!!destination.coverId && isCustomCover(destination.coverId) &&
        <p className="text-sm italic">Custom covers take 5-7 business days to print before they ship.</p>
      }
    </form>
  )
}
