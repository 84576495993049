import { OrderFragment } from '../graphql/__generated__'

const SESSION_KEY = 'last_touched_destination_map'

// getLastTouchedDestinations returns a map of orders to last touched destinations from browser storage
const getLastTouchedDestinations = () => {
    const lastTouchedRaw = sessionStorage.getItem(SESSION_KEY)
    if (lastTouchedRaw) {
        try {
            return JSON.parse(lastTouchedRaw)
        } catch (err) {
            console.error(`attempting to parse ${ SESSION_KEY }`, err)
        }
    }
    return {}
}

// setLastTouchedDestinationForOrder helps set or unset the last touched destination on an order
export const setLastTouchedDestinationForOrder = (orderID: string, destinationID: string | undefined) => {
    const lastTouched = getLastTouchedDestinations()
    if (destinationID) {
        lastTouched[orderID] = destinationID
    } else {
        delete lastTouched[orderID]
    }
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(lastTouched))
}

// getLastTouchedDestinationForOrder returns the last touched destination on an order, if exists, for cloning
export const getLastTouchedDestinationForOrder = (orderID: string) => {
    const lastTouched = getLastTouchedDestinations()
    return orderID in lastTouched
        ? lastTouched[orderID]
        : undefined
}

// getLastDestinationIDForOrder is used when creating a new design and there is no last touched destination to clone
export const getLastDestinationIDForOrder = (order: OrderFragment): string | undefined => {
    if (!order.designs?.length) {
        return
    }

    const lastDesign = order.designs[order.designs.length - 1]
    if (!lastDesign.destinations?.length) {
        return
    }

    return lastDesign.destinations[lastDesign.destinations.length - 1]?.id
}