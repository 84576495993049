import React from 'react'

import { formatPrice } from '../utils/Price'
import type { CostsFragment, Maybe } from '../graphql/__generated__'

type PriceSummaryProps = {
  cost: CostsFragment["total"]
  showDiscount?: boolean
}

function PriceSummary({ cost, showDiscount }: PriceSummaryProps) {
  const hasDiscount = !!showDiscount && cost.discountInCents > 0
  return <span>
    { hasDiscount && <>
      <span className="line-through">{ formatPrice(cost.listInCents) }</span>
      &nbsp;
    </> }
    <span className={ hasDiscount ? "italic" : undefined }>
      { formatPrice(cost.actualInCents) }
    </span>
  </span>
}

type Field = 'total' | 'tax' | 'shipping' | 'subtotal'

type PricingSummaryProps = {
  costs: Maybe<CostsFragment>
  isEGift: boolean
  fields?: Field[]
}

export default function PricingSummary({ costs, isEGift, fields }: PricingSummaryProps) {
  if (!costs) {
    return <></>
  }

  fields = fields || ['total', 'tax', 'shipping', 'subtotal']
  if (isEGift) {
    const shippingKey = fields.findIndex(field => field === 'shipping')
    if (shippingKey !== -1) {
      fields.splice(shippingKey, 1)
    }
  }

  const balanceUsed = costs.balanceUsed.productBalanceUsed?.reduce<{
    book10min: number
    book20min: number
    book30min: number
    giftBox: number
    customCoverFront: number
    customCoverBack: number
    economyShipping: number
    expressShipping: number
  }>((prev, { product, quantity }) => {
      switch (product.id) {
        case 'book_5in_10min':
          return {
            ...prev,
            book10min: prev.book10min + quantity,
          }
        case 'book_5in_20min':
          return {
            ...prev,
            book20min: prev.book20min + quantity,
          }
        case 'book_5in_30min':
          return {
            ...prev,
            book30min: prev.book30min + quantity,
          }
        case 'giftbox-5in':
          return {
            ...prev,
            giftBox: prev.giftBox + quantity,
          }
        case 'custom-cover-front':
          return {
            ...prev,
            customCoverFront: prev.customCoverFront + quantity,
          }
        case 'custom-cover-back':
          return {
            ...prev,
            customCoverBack: prev.customCoverBack + quantity,
          }
        case 'economy-shipping':
          return {
            ...prev,
            economyShipping: prev.economyShipping + quantity,
          }
        case 'express-shipping':
          return {
            ...prev,
            expressShipping: prev.expressShipping + quantity,
          }
      }
    return prev
  }, {
    book10min: 0,
    book20min: 0,
    book30min: 0,
    giftBox: 0,
    customCoverFront: 0,
    customCoverBack: 0,
    economyShipping: 0,
    expressShipping: 0,
  })
  
  const hasBookExtraBalanceUsed = balanceUsed &&
    !!(
      balanceUsed.book10min || balanceUsed.book20min || balanceUsed.book30min ||
      balanceUsed.giftBox || balanceUsed.customCoverFront || balanceUsed.customCoverBack
    )
  const hasShippingBalanceUsed = balanceUsed &&
    !!(balanceUsed.economyShipping || balanceUsed.expressShipping)

  return <>
    <dl className="grid grid-cols-3 max-w-xl mx-auto">
      { fields.includes('subtotal') && (costs.product.listInCents > 0 || hasBookExtraBalanceUsed) && <>
        <dt className="col-span-2">{ isEGift ? 'E-Cards' : 'Books & Extras' }</dt>
        <dd className="text-right">
          <PriceSummary
            cost={ costs.product }
            showDiscount={!hasBookExtraBalanceUsed}
          />
        </dd>
        { hasBookExtraBalanceUsed && <>
          <dd className="text-right text-green-600 text-sm col-span-3">
            { balanceUsed.book10min > 0 && <>
              + { balanceUsed.book10min } prepaid book{ balanceUsed.book10min > 1 ? 's' : '' } (10min)<br />
            </> }
            { balanceUsed.book20min > 0 && <>
              + { balanceUsed.book20min } prepaid book{ balanceUsed.book20min > 1 ? 's' : '' } (20min)<br />
            </> }
            { balanceUsed.book30min > 0 && <>
              + { balanceUsed.book30min } prepaid book{ balanceUsed.book30min > 1 ? 's' : '' } (30min)<br />
            </> }
            { balanceUsed.giftBox > 0 && <>
              + { balanceUsed.giftBox } prepaid gift box{ balanceUsed.giftBox > 1 ? 'es' : '' }<br />
            </> }
            { balanceUsed.customCoverFront > 0 && <>
              + { balanceUsed.customCoverFront } prepaid custom front cover{ balanceUsed.customCoverFront > 1 ? 's' : '' }<br />
            </> }
            { balanceUsed.customCoverBack > 0 && <>
              + { balanceUsed.customCoverBack } prepaid custom back cover{ balanceUsed.customCoverBack > 1 ? 's' : '' }<br />
            </> }
          </dd>
        </> }
      </> }

      { fields.includes('shipping') && <>
        <dt>Shipping</dt>
        <dd className="text-right col-span-2">
          <PriceSummary cost={ costs.shipping } />
        </dd>
        { (hasShippingBalanceUsed || !costs.shipping.actualInCents) && <>
          <dd className="text-right text-green-600 text-sm col-span-3">
            { hasShippingBalanceUsed ? <>
              { !!balanceUsed.economyShipping && <>
                + prepaid shipping ($5)<br />
              </> }
              { !!balanceUsed.expressShipping && <>
                + prepaid shipping ($15)<br />
              </> }
            </> : <>
              (free to the same address)
            </> }
          </dd>
        </> }
      </> }

      { fields.includes('tax') && costs.tax.listInCents > 0 && <>
        <dt>Tax</dt>
        <dd className="text-right col-span-2">
          <PriceSummary cost={ costs.tax } />
        </dd>
      </> }

      { fields.includes('total') && <>
        <dt>Total</dt>
        <dd className="text-right col-span-2">
          <PriceSummary cost={ costs.total } />
        </dd>
      </> }
    </dl>
  </>
}
