import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'

import { ApolloProvider } from "@apollo/client"
import client from "./apollo-client"

/*
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/sw.js') // path to your bundled service worker with GoldenRetriever service worker
    .then((registration) => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope)
    })
    .catch((error) => {
      console.log(`Registration failed with ${error}`)
    })
}
 */

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
)
