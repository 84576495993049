import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { UpdateAddressDocument } from '../graphql/__generated__'
import type { Address, UpdateAddressMutation, AddressValidationMessage } from '../graphql/__generated__'
import { usePlacesWidget } from 'react-google-autocomplete'

import type { FormRef, DestFormProps } from './DestComponentEditor'
import { mapPlaceToAddressForm, API_KEY } from "../utils/GoogleMaps"
import { findDestination } from '../utils/Destination'
import { REFETCH_ON_COST_CHANGE } from '../utils/Price'

import countries from '../utils/countries.json'

export type AddressFormFields = {
  address: Address,
}

// Mapping the names our validation responses (coming from ShipEngine) will use,
// to the names we use.
let fieldMapping = {
  'postal_code': 'postalCode',
}

const nullAddress: Address = {
  fullName: '',
  companyName: '',
  street1: '',
  street2: '',
  cityLocality: '',
  stateProvinceCode: '',
  postalCode: '',
  countryCode: '',
  phoneNumber: '',
  nickname: '',
  email: '',
}

const AddressForm = forwardRef<FormRef, DestFormProps>(({ options, destination, onSave, onError }, formRef) => {
  const address = destination?.address

  const { register, watch, setValue, setError, handleSubmit, formState } = useForm<AddressFormFields>()

  const countryCode = watch("address.countryCode")
  const currentCountry = countries.find(({code}) => code === countryCode)

  useEffect(() => {
    if (address) {
      setValue('address', {...address})
    } else {
      setValue('address', nullAddress)
    }
  }, [address])

  useEffect(() => {
    if (address?.stateProvinceCode) {
      setValue('address.stateProvinceCode', address?.stateProvinceCode)
    }
  }, [address?.stateProvinceCode, currentCountry?.states])

  useEffect(() => {
    setValue("address.countryCode", address?.countryCode?.trim() || 'US')
  }, [address?.countryCode])

  const [validationError, setValidationError] = useState<string>("")

  const [
    updateAddress,
    { loading: addrSaving, error: addrSaveError },
  ] = useMutation<UpdateAddressMutation>(UpdateAddressDocument, {
    refetchQueries: REFETCH_ON_COST_CHANGE,
  })

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: API_KEY,
    options: {
      types: ["address"],
      componentRestrictions: {
        country: countryCode?.trim() || 'US',
      },
    },
    onPlaceSelected: mapPlaceToAddressForm.bind(null, setValue),
  })

  // When you hit enter in the Autocomplete you don't want the form to immediately submit.
  const ignoreEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
    }
  }

  const submit = async function (values: AddressFormFields) {
    if (!destination?.id) {
      throw new Error("No destination provided")
    }

    await updateAddress({
      variables: {
        destinationId: destination.id,
        address: values.address,
      },
      onError: err => onError?.(err.message),
      onCompleted: ({ updateAddress: { order: _order, valid, messages } }) => {
        const result = findDestination(_order, destination.id)
        if (valid) {
          result && onSave?.(result)
        } else {
          var notFound: Array<AddressValidationMessage> = []
          messages.forEach((message: AddressValidationMessage) => {
            var found = false
            Object.entries(fieldMapping).forEach(([key, field]: [string, string]) => {
              if (message.message.includes(key)) {
                // @ts-ignore Typescript doesn't know about the nested fields
                setError(`address.${ field }`, message, true)
                found = true
              }
            })

            if (!found) {
              notFound.push(message)
            }
          })

          if (notFound.length > 0) {
            setValidationError(notFound[0].message)
          }
          onError?.(notFound[0]?.message ?? "Error saving address change")
        }
      },
    })
  }

  const errorHandler = useCallback(
    () => onError?.("Check validation errors on form"),
    [onError],
  )

  useImperativeHandle(formRef, () => ({
    saving: addrSaving,
    submit: handleSubmit(submit, errorHandler),
  }), [addrSaving, errorHandler])

  return <form onSubmit={ handleSubmit(submit, errorHandler) }>
    <div className="shadow overflow-hidden sm:rounded-md">

        { options?.digital ?
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="address.email" className="block text-sm font-medium text-gray-700">Recipient Email Address</label>
            <input {...register("address.email", {
              required: 'Email is required',
            })} type="email" autoComplete="email" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            { formState.errors?.address?.email && <p className="mt-2 text-sm text-red-600">{ formState.errors.address?.email.message }</p> }
          </div>

          :
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="address.countryCode" className="block text-sm font-medium text-gray-700">Country</label>
                <select {...register("address.countryCode")} autoComplete="country-name" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {
                    countries.map(({code, name}) => {
                      return <option value={ code } key={ code }>{ name }</option>
                    })
                  }
                </select>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="address.addressSearch" className="block text-sm font-medium text-gray-700">Address Search</label>
                <input ref={ ref } type="text" onKeyPress={ ignoreEnter } autoComplete="off" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" data-cy="address-search-input" />
              </div>

              <hr className="col-span-6" />

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="address.fullName" className="block text-sm font-medium text-gray-700">Full Name</label>
                <input {...register("address.fullName", {
                  required: 'Name is required',
                })} type="text" autoComplete="name" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                { formState.errors?.address?.fullName && <p className="mt-2 text-sm text-red-600">{ formState.errors.address?.fullName.message }</p> }
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="address.companyName" className="block text-sm font-medium text-gray-700">Company</label>
                <input {...register("address.companyName")} type="text" autoComplete="organization" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
              </div>

              <div className="col-span-6">
                <label htmlFor="address.street1" className="block text-sm font-medium text-gray-700">Address Line 1</label>
                <input {...register("address.street1", {
                  required: 'Address is required',
                })} type="text" autoComplete="address-line1" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                { formState.errors?.address?.street1 && <p className="mt-2 text-sm text-red-600">{ formState.errors.address?.street1.message }</p> }
              </div>

              <div className="col-span-6">
                <label htmlFor="address.street2" className="block text-sm font-medium text-gray-700">Address Line 2</label>
                <input {...register("address.street2")} type="text" autoComplete="address-line2" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
              </div>

              <div className="col-span-6">
                <label htmlFor="address.cityLocality" className="block text-sm font-medium text-gray-700">City / Locality</label>
                <input {...register("address.cityLocality", {
                  required: 'City is required',
                })} type="text" autoComplete="address-level2" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                { formState.errors?.address?.cityLocality && <p className="mt-2 text-sm text-red-600">{ formState.errors.address?.cityLocality.message }</p> }
              </div>

              <div className="col-span-3">
                <label htmlFor="address.stateProvinceCode" className="block text-sm font-medium text-gray-700">State / Province</label>
                <select {...register("address.stateProvinceCode")} autoComplete="address-level1" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {
                    currentCountry?.states.map(({code, name}) => {
                      return <option value={ code } key={ code }>{ name }</option>
                    })
                  }
                </select>

              </div>

              <div className="col-span-3">
                <label htmlFor="address.postalCode" className="block text-sm font-medium text-gray-700">ZIP / Postal code</label>
                <input {...register("address.postalCode", {
                  required: 'Postal code is required',
                })} type="text" autoComplete="postal-code" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                { formState.errors?.address?.postalCode && <p className="mt-2 text-sm text-red-600">{ formState.errors.address?.postalCode.message }</p> }
              </div>
            </div>
          </div>
        }
    </div>

    { validationError &&
    <div className="text-red-500 mt-8 text-center">
      { validationError }
    </div>
    }

    { addrSaveError &&
      <div className="text-red-500 mt-8 text-center">
        { addrSaveError.toString() }
      </div>
    }


  </form>
})

export default AddressForm
