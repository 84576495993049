import React, { useRef, useEffect } from 'react'
import type { Maybe, Message } from '../graphql/__generated__'
import { renderMessage } from '../deps/loom-client/src/Labels/Message'

type GiftMessagePreviewProps = {
  message: Maybe<string>,
}

export default function GiftMessagePreview({ message }: GiftMessagePreviewProps) {
  const ref = useRef<HTMLDivElement>(null)

  message = message || ''

  // Replace all heart-like emojis with the hollow heart, as it prints better
  // This also appears in Loom client
  message = message.replace(/😍|❤️|💕|💟|💗|💓|💝|💞|💘|💖|🩷/g, '♡')

  useEffect(() => {
    renderMessage(message || '', "4x4").then(result => {
      if (ref.current && result?.canvas) {
        ref.current.innerHTML = ''
        ref.current.appendChild(result.canvas)
        result.canvas.style.display = 'block'
      }
    })
  }, [ref.current, message])

  const [windowWidth, setWindowWidth] = React.useState<number>(window.innerWidth)
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const scaleFactor = Math.min(windowWidth, 480) / 1200
  let offset = (Math.min(windowWidth, 480) - 252) / 2
  if (offset > 85) {
    offset = 85
  }

  return (
    <div className="relative">
      <img src={ `${ process.env.PUBLIC_URL }/images/message-backdrop.jpg` } />
      <div ref={ ref } style={{
        transform: `scale(${ scaleFactor }) skewX(-1deg) skewY(1deg) rotate(2deg)`,
        left: `${ offset }px`,
        top: `${ offset }px`,
        }} className="
        absolute transform-gpu origin-top-left shadow
        filter grayscale rounded-xl border border-gray-500
      " />
    </div>
  )
}
