import React, { useState, Suspense } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { useMutation } from '@apollo/client'

import countries from '../utils/countries.json'
import type { OrderFragment, DestinationInput } from '../graphql/__generated__'
import { AddDestinationsDocument } from '../graphql/__generated__'
import Button from './Button'

type ImportDestinationsModalProps = {
  onClose: () => void
  designId: string
  order: OrderFragment
}

export default function ImportDestinationsModal({onClose, designId, order}: ImportDestinationsModalProps) {
  const Importer = React.lazy(() => import('react-csv-importer').then(module => ({default: module.Importer})))
  const ImporterField = React.lazy(() => import('react-csv-importer').then(module => ({default: module.ImporterField})))

  const [error, setError] = useState<string | undefined>(undefined)
  const [addDestinations, { loading: saving, error: savingError, data: saved }] = useMutation(AddDestinationsDocument)

  if (!order || !order.designs) {
    return <>No order or contains no designs</>
  }

  // We default the cover ID to the ID of whatever destination currently exists for this design.
  let coverId = ''
  for (let i=0; i < order.designs.length; i++) {
    const design = order.designs[i]
    if (design.design?.id === designId && design.destinations) {
      for (let j=0; j < design.destinations.length; j++) { 
        const destination = design.destinations[j]

        if (destination.coverId) {
          coverId = destination.coverId
          break
        }
      }
    }
  }


  function rowToDestination(defaults: any, row: any): DestinationInput | null {
    let extras: DestinationInput['extras'] = []
    if (row.useGiftBox) {
      let gb = row.useGiftBox.toLowerCase()
      if (gb === 'yes' || gb === 'true') {
        extras = [{id: 'giftbox-5in'}]
      } else if (gb !== 'no' && gb !== 'false') {
        setError('Gift Box value must be "yes", "no", "true", or "false"')
        return null
      }
    }

    let shippingSpeed = ''
    let shippingServiceCode = ''
    if (row.shippingSpeed === 'express') {
      shippingSpeed = 'express'
    } else if (row.shippingServiceCode) {
      shippingServiceCode = row.shippingServiceCode
    }

    if (!row.country){
      row.country = 'US'
    } else if (row.country.length !== 2) {
      const val = row.country.toUpperCase().trim()

      const country = countries.find(c => c.name.toUpperCase() === val)
      if (country) {
        row.country = country.code
      } else if (val === 'CAN') {
        row.country = 'CA'
      } else if (val === 'GBR') {
        row.country = 'GB'
      } else if (val === 'USA') {
        row.country = 'US'
      } else {
        setError('Country must be a 2-letter country code')
        return null
      }
    }

    if (row.state.length !== 2) {
      const country = countries.find(c => c.code === row.country.toUpperCase())
      const state = country?.states.find(s => s.name.toLowerCase() === row.state.toLowerCase())
      if (state) {
        row.state = state.code
      } else {
        setError('State must be a two-letter code')
        return null
      }
    }

    return {...defaults,
      extras,
      giftMessage: row.message,
      quantity: row.quantity || 1,

      shippingSpeed,
      shippingServiceCode,
      coverId: row.coverId || defaults?.coverId || "",
      
      address: {
        fullName: row.name,
        companyName: row.company,
        street1: row.address1,
        street2: row.address2,
        cityLocality: row.city,
        stateProvinceCode: row.state,
        postalCode: row.zip,
        countryCode: row.country,
        phoneNumber: row.phone,
      },
    }
  }

  return <Dialog
    onClose={ onClose }
    open={ true }
    fullWidth={ true }
    maxWidth={ 'lg' }
  >
    <DialogTitle>
      Import Destinations
      - <a
          className="text-blue-500 underline"
          target="_blank"
          title="Help"
          href="https://help.sendheirloom.com/article/18-importing-addresses-from-a-file">
          <QuestionMarkCircleIcon className="w-6 h-6 inline-block" />
        </a>

      <a
        title="Close dialog"
        className="absolute top-4 right-4"
        onClick={ onClose }>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </a>
    </DialogTitle>

    <DialogContent>
      <Suspense
        fallback={ <div className="m-8 flex justify-center"><CircularProgress className="w-8 h-8" /></div> }
      >
        <Importer
          onStart={ () => { setError(undefined) } }
          // @ts-ignore
          dataHandler={ (rows) => {
            // May be called several times
            // Returning the promise will block more data getting imported until this batch is done
            const destinations = []
            for (let i=0; i < rows.length; i++) {
              if (rows[i].address1 === '') {
                // Files often have blank lines
                continue
              }

              const destination = rowToDestination({
                coverId,
                quantity: 1,
              }, rows[i])
              if (destination === null) {
                // We give up on the first error. This might end up a little weird if someone's
                // file actually requires multiple batches.
                return undefined
              }

              destinations.push(destination)
            }

            if (destinations.length > 0) {
              return addDestinations({
                variables: {
                  orderId: order.id,
                  designId,
                  destinations,
                  removeBlankOriginal: true,
                }
              })
            }
            return undefined
          }}
        >
          <ImporterField name="name" label="Name" />
          <ImporterField name="company" label="Company" optional />
          <ImporterField name="address1" label="Address Line 1" />
          <ImporterField name="address2" label="Address Line 2" optional />
          <ImporterField name="city" label="City" />
          <ImporterField name="state" label="State" />
          <ImporterField name="zip" label="Zip" />
          <ImporterField name="country" label="Country" optional />
          <ImporterField name="phone" label="Phone" optional />
          <ImporterField name="message" label="Message" optional />
          <ImporterField name="quantity" label="Quantity" optional />
          <ImporterField name="shippingSpeed" label="Shipping Speed" optional />
          <ImporterField name="useGiftBox" label="Use Gift Box" optional />
          <ImporterField name="coverId" label="Cover ID" optional />
        </Importer>
      </Suspense>
    </DialogContent>

    <DialogActions>
      <div className="m-auto p-4 text-center font-bold flex justify-center">
        { error && <div className="text-red-500">Error: { error }</div> }
        { saving && <div className="text-gray-500">Creating...</div> }
        { savingError && <div className="text-red-500">There was an error creating your destinations, please try again.</div> }
        { saved && <Button type='primary' onClick={ onClose }>Done</Button> }
      </div>
    </DialogActions>
  </Dialog>
}

