import React from 'react'
import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'

import Button from './Button'

type VolumeSliderProps = {
  ariaLabel?: string
  volume: number
  onChange: (volume: number) => void
}

const volumeLabels: { [key: number]: string; } = {
  0: 'Muted',
  0.2: 'Very Quiet',
  0.4: 'Quiet',
  0.6: 'Normal',
  0.8: 'Loud',
  1: 'Very Loud',
}

export default function VolumeSlider({ volume, onChange, ariaLabel }: VolumeSliderProps) {
  let buttonClasses = "text-gray-500 border-gray-200 !bg-white"
  if (volume === 0) {
    buttonClasses = "text-white border-gray-300 !bg-gray-300"
  }

  return <div className="flex">
    <Button
      onClick={ () => { volume > 0 ? onChange(0) : onChange(0.6) } }
      className={`px-1 py-0 mr-4 my-auto h-6 border 
                  hover:shadow ` + buttonClasses }>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z" clipRule="evenodd" />
      </svg>
    </Button>
    <ThickSlider
      aria-label={ ariaLabel }
      value={ volume }
      onChange={ (event: Event, value: number | number[]) => {
        if (typeof value === 'number') {
          onChange(value)
        } else {
          throw new Error('Expected value to be a number')
        }
      }}
      track={ false }
      valueLabelDisplay="auto"
      valueLabelFormat={ (v) => volumeLabels[v] }
      marks
      step={ 0.2 }
      min={ 0 }
      max={ 1.0 }
    />
  </div>
}

const ThickSlider = styled(Slider)({
  height: 12,
  /* These are all fixes to make the marks not run into the edges
   * of the slider.
   */
  margin: '0 10px',
  '& .MuiSlider-rail': {
    right: -10,
    left: -10,
    width: 'calc(100% + 20px)',
  },
  '& .MuiSlider-track': {
    left: '-10px !important',
    width: 'calc(60% + 10px) !important',
  },
})
