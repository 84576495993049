import { h } from 'preact'
import { UIPlugin } from '@uppy/core'
const { RequestClient } = require('@uppy/companion-client')

// Tell Babel to transform JSX into preact.h() calls:
/** @jsxRuntime classic */
/** @jsx h */

export default class YouTube extends UIPlugin {
  constructor (uppy, opts) {
    super(uppy, opts)
    this.id = this.opts.id || 'YouTube'
    this.title = this.opts.title || 'YouTube'
    this.type = 'acquirer'
    this.icon = () => <svg aria-hidden="true" focusable="false" width="32" height="32" viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <rect className="uppy-ProviderIconBg" fill="#FF753E" width="32" height="32" rx="16" />
        <path d="M22.788 15.389l-2.199 2.19a3.184 3.184 0 0 1-.513.437c-.806.584-1.686.876-2.638.876a4.378 4.378 0 0 1-3.519-1.752c-.22-.292-.146-.802.147-1.021.293-.22.806-.146 1.026.146.953 1.313 2.785 1.532 4.105.583a.571.571 0 0 0 .293-.292l2.199-2.189c1.1-1.167 1.1-2.992-.073-4.086a2.976 2.976 0 0 0-4.105 0l-1.246 1.24a.71.71 0 0 1-1.026 0 .703.703 0 0 1 0-1.022l1.246-1.24a4.305 4.305 0 0 1 6.083 0c1.833 1.605 1.906 4.451.22 6.13zm-7.183 5.035l-1.246 1.24a2.976 2.976 0 0 1-4.105 0c-1.172-1.094-1.172-2.991-.073-4.086l2.2-2.19.292-.291c.66-.438 1.393-.657 2.2-.584.805.146 1.465.51 1.905 1.168.22.292.733.365 1.026.146.293-.22.367-.73.147-1.022-.733-.949-1.76-1.532-2.859-1.678-1.1-.22-2.272.073-3.225.802l-.44.438-2.199 2.19c-1.686 1.75-1.612 4.524.074 6.202.88.803 1.979 1.241 3.078 1.241 1.1 0 2.199-.438 3.079-1.24l1.246-1.241a.703.703 0 0 0 0-1.022c-.294-.292-.807-.365-1.1-.073z" fill="#FFF" fillRule="nonzero" />
      </g>
    </svg>

    if (!this.opts.companionUrl) {
      throw new Error('companion url is required')
    }

    const defaultOptions = {}
    this.opts = { ...defaultOptions, ...opts }

    this.submit = this.submit.bind(this)

    this.client = new RequestClient(uppy, {
      companionUrl: this.opts.companionUrl,
      companionHeaders: this.opts.companionHeaders,
      companionCookiesRule: this.opts.companionCookiesRule,
    })
  }
    
  async submit(e) {
    e.preventDefault()

    this.setPluginState({ error: false })

    const url = e.target.querySelector('input[type=text]').value.trim()
    const isAudio = e.target.querySelector('input[type=checkbox]').checked

    const endpoint = (verb) => `youtube/${verb}${isAudio ? '/audio' : ''}`

    if (url) {
      this.setPluginState({ loading: true })
      try {
        const { token, size, error } = await this.client.post(endpoint('download'), { url })

        if (error) {
          this.setPluginState({ loading: false, error })
          return
        }

        await this.uppy.addFile({
          source: this.id,
          type: isAudio ? 'audio/m4a' : 'video/mp4',
          name: `noname.${isAudio ? 'm4a' : 'mp4'}`,
          data: {
            size,
          },
          isRemote: true,
          remote: {
            companionUrl: this.opts.companionUrl,
            url: `${this.opts.companionUrl}/${endpoint('upload')}`,
            body: { token },
            providerOptions: this.client.opts,
          },
        })

        this.setPluginState({ loading: false })
      } catch (err) {
        this.setPluginState({ loading: false, error: true })
      }
    }
  }
  
  render () {
    const state = this.getPluginState()

    return <form onSubmit={ this.submit } className="flex flex-col items-center justify-center w-full h-full">
      <input
        type="text"
        className="uppy-c-textInput w-5/6"
        placeholder="Enter URL to import a file"
        aria-placeholder="Enter URL to import a file"
        aria-label="Enter URL to import a file"
        data-uppy-super-focusable
        disabled={state.loading}
      />

      <label className="flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="
            form-tick
            appearance-none
            cursor-pointer
            h-6
            w-6
            my-6
            border border-gray-300
            rounded
            checked:bg-black
            checked:border-transparent
            focus:outline-none
          "
        />
        <p className="ml-4">
          <strong>Only Add Audio</strong><br />
          <span className="text-sm">
            Add just the audio from this video as background music
          </span>
        </p>
      </label>

      <button
        type="submit"
        className="uppy-c-btn uppy-c-btn-primary"
        disabled={state.loading}
      >
        { state.loading ? 'Importing...' : 'Import' }
      </button>
    
      { !!state.error && <p className="p-2 mt-6 mb-2 bg-red-100">
        { typeof state.error === 'string' ? state.error : 'An error occurred, please try again' }
      </p> }
    </form>
  }

  install () {
    const { target } = this.opts
    if (target) {
      this.mount(target, this)
    }
  }

  uninstall () {
    this.unmount()
  }
}
