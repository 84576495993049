import React, { useEffect, forwardRef, useImperativeHandle, useState, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { LoadOrUpdatePaymentIntentDocument, OrderForPaymentDocument, OrderFragment, PaymentIntentFragment } from '../graphql/__generated__'
import { useAuthenticatedQuery } from '../apollo-client'

let stripePromise: Promise<any> | null = null
if (localStorage.DEBUG_stripe_test){
  stripePromise = loadStripe('pk_test_51JPwtdAHAhN8nuhgoOcZtasegdGpbhZMlCKPdmFy5TxDpVhtMIIDLlu3uZdvi5MoNH7JXsKC3LkstaKHN9cQRfxL000gpQa6U7')
} else {
  stripePromise = loadStripe('pk_live_51JPwtdAHAhN8nuhgn3MvHmOe3dya0HLjA8UH1PuvF83vWQfG2vjscPXl07fnca1GOqSrjMP0DxNJ6zorBnvn39We00UF94fL3X')
}

type PaymentFormInputProps = {
  orderId: string
  orderCacheIdentifyObj: Pick<OrderFragment, "__typename" | "id">
  orderCost: number
  nextPath: string
  onSave?: () => void
  onError?: (message: string) => void
}

export type PaymentFormInputRef = {
  submit: () => void,
}

const PaymentFormInput = forwardRef(({ orderId, orderCacheIdentifyObj, orderCost, nextPath, onSave, onError }: PaymentFormInputProps, ref) => {
  const [stripeError, setStripeError] = useState<string | null>(null)

  const { data, loading: queryLoading, error } = useAuthenticatedQuery<{
    orderStatus: { paymentIntent: PaymentIntentFragment }
  }>(OrderForPaymentDocument, {
    skip: orderCost == 0,
    variables: {
      orderId,
    },
  })
  const paymentIntent = data?.orderStatus.paymentIntent

  const [loadOrUpdatePaymentIntent, { loading: mutationLoading }] = useMutation<{
    loadOrUpdatePaymentIntent: PaymentIntentFragment
  }>(LoadOrUpdatePaymentIntentDocument, {
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(orderCacheIdentifyObj),
        fields: {
          paymentIntent: () => data?.loadOrUpdatePaymentIntent,
        },
      })
    },
  })

  useEffect(() => {
    if (!orderCost || !!paymentIntent || queryLoading) {
      return
    }

    // Generate payment intent if one doesn't exist and there is a cost.
    loadOrUpdatePaymentIntent({
      variables: {
        orderId,
      },
    })
  }, [orderCost, paymentIntent, queryLoading, loadOrUpdatePaymentIntent, orderId])

  // Using useElements requires the component to be wrapped in an Elements element,
  // so we use a second inner component.
  function InnerPaymentElement(){
    const elements = useElements()
    const stripe = useStripe()

    const [submitting, setSubmitting] = useState<boolean>(false)

    useImperativeHandle(ref, () => ({
      submit() {
        handleSubmit()
      }
    }))

    const handleSubmit = useCallback(async () => {
      if (!stripe || !elements) {
        onError && onError("Missing stripe element")
        return
      }

      setSubmitting(true)

      const result = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url: document.location.origin + nextPath,
        },
      })

      setSubmitting(false)

      if (result.error) {
        console.error("Payment error", result.error.message)
        setStripeError(result.error.message || "Payment error")
        onError && onError(result.error.message || "")
      } else {
        // The customer will be HTTP redirected IFF the payment method required it,
        // otherwise this code path will run.
        onSave && onSave()
      }
    }, [stripe, elements, nextPath, onSave, onError, setStripeError])

    if (!orderCost) {
      return <></>
    }

    return <div data-cy="payment-details-container">
      <PaymentElement />

      { submitting && <div className="flex justify-center mb-2 mt-8"><CircularProgress /></div> }
    </div>
  }

  if (queryLoading || mutationLoading) {
    return <div className="flex justify-center"><CircularProgress /></div>
  }
  if (error) {
    return <div className="p-2 bg-red-200">Error loading payment options.</div>
  }
  if (!paymentIntent?.clientSecret) {
    return <></>
  }

  const stripeOptions = {
    clientSecret: paymentIntent.clientSecret,
  }

  return <>
    <Elements stripe={ stripePromise } options={ stripeOptions }>
      <InnerPaymentElement />
    </Elements>

    { stripeError && <div className="p-2 mt-4 bg-red-200">Error processing your payment, please try again.</div> }
  </>
})

export default PaymentFormInput
