import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import CoverForm, { CoverFormOptions } from '../components/CoverForm'
import DestComponentEditor from '../components/DestComponentEditor'
import useSteps, { BuilderFlow, GiftFlow, CoverStep, GiftCoverStep, CustomCoverStep } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import { CircularProgress } from '@mui/material'
import { useRedirectWithDesignDestination } from '../utils/RedirectWithDesignDestination'

interface CoverEditorProps {
  isGift?: boolean
}

export default function CoverEditor({ isGift }: CoverEditorProps) {
  const { orderId, designId, destinationId } = useParams()

  const step = isGift ? GiftCoverStep : CoverStep

  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow: isGift ? GiftFlow : BuilderFlow,
      step,
      args: { orderId, destinationId, designId },
    })
  }, [isGift, orderId, destinationId, designId])

  const { hasReturn, navigateNext, navigatePrev, navigateTo, currentPath, currentStep } = useSteps()

  const { loading } = useRedirectWithDesignDestination({
    orderId, designId, destinationId,
    skip: currentStep !== step,
    currentPath,
  })

  if (!orderId) {
    return <div>No order ID</div>
  }

  if (loading) {
    return <div className="flex my-4 justify-center">
      <CircularProgress />
    </div>
  }

  return <div className="mb-4">
    <DestComponentEditor<CoverFormOptions>
      form={ CoverForm }
      text={ {
        pageTitle: 'Cover',
        pageDescription: <p className="text-gray-500">
          Select the cover you'd like for your video book. All books are set in
          hardcovers with a matte finish.
        </p>,
        componentName: 'Cover',
      } }
      orderId={ orderId }
      destinationId={ destinationId }
      designId={ designId }
      hasReturn={ hasReturn }
      onDone={ () => {
        navigateNext({})
      }}
      onBack={ () => {
        hasReturn ? navigateNext({}) : navigatePrev({})
      }}
      formOptions={{
        onCustomCoverEdit: currentStep && orderId && designId && destinationId ? (customCoverId => {
          navigateTo(currentStep, {
            orderId, designId, destinationId,
            coverId: customCoverId,
          }, {})
        }) : undefined,
      }}
    />
  </div>
}
