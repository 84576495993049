import React, { useEffect } from 'react'
import { useAuthenticatedQuery } from '../apollo-client'
import { OrderDocument, OrderFragment } from '../graphql/__generated__'
import { PathFn } from './Steps'
import { useNavigate } from 'react-router-dom'

interface UseAuthSelectDesignProps {
    orderId: string | undefined
    designId: string | undefined
    destinationId: string | undefined
    currentPath: PathFn
    skip?: boolean
}

// useRedirectWithDesignDestination adds design and/or destination IDs to order-related routes;
// only adds a designId if there is a single design and a destinationId if there is a single destination.
export const useRedirectWithDesignDestination = ({ orderId, designId, destinationId, currentPath, skip }: UseAuthSelectDesignProps) => {
  const navigate = useNavigate()

  const {
    data: { orderStatus: order } = {},
    loading, error,
  } = useAuthenticatedQuery<{ orderStatus: OrderFragment }>(OrderDocument, {
    skip: !orderId || skip || (!!designId && !!destinationId),
    variables: {
      orderId,
    },
  })

  // TODO: loading states after the Apollo query but before navigate may need improvement;
  // delaying for further work to DestComponentEditor in a subsequent branch.

  useEffect(() => {
    if (!order) {
      return
    }

    let hasChanged = false

    if (!designId) {
      if (order.designs?.length === 1 && order.designs[0].design) {
        designId = order.designs[0].design.id
        hasChanged = true
      }
    }

    if (!destinationId && designId) {
      const design = order.designs?.find(({ design }) => design?.id === designId)
      if (design?.destinations?.length === 1) {
        destinationId = design.destinations[0].id
        hasChanged = true
      }
    }

    if (hasChanged) {
      navigate(currentPath({ designId, destinationId }), { replace: true })
    }
  }, [designId, destinationId, order?.designs, currentPath])

  return { loading: loading, error }
}