import React, { useEffect } from 'react'

import Button from '../components/Button'
import { useStepContext } from '../utils/StepContext';

declare global {
  var Beacon: any
}

export default function Help() {
  // Reset to hide steps in nav
  const { setStepState } = useStepContext();
  setStepState(undefined)

  useEffect(() => {
    if (window.Beacon) {
      window.Beacon('init', 'a72cb0ed-d93b-4afe-918c-737714545d58')
      window.Beacon('open')
      return () => {
        window.Beacon('destroy')
      }
    }
  }, [])

  return <div className="flex flex-col items-center space-y-2">
    <div className="bg-gray-50 md:p-6 p-4 w-full flex flex-col items-center space-y-4">
      <h2 className="text-xl">Have an issue with our website or with a book you've received?</h2>
      <p className="max-w-2xl">Our automated help tool can provide targeted help for the most common website
        and video book issues, including allowing you to get a replacement for broken or lost video books.</p>

      <a href="/help/issue/report" className="font-bold text-xl underline">Visit our Help Tool</a>
    </div>

    <div className="bg-gray-50 md:p-6 p-4 w-full flex flex-col items-center">
      <p className="mb-6">Search our knowledge base to find answers to the most commonly asked questions.</p>
      <form
        target="_blank"
        action="https://help.sendheirloom.com/search"
        method="GET"
        autoComplete="off">

        <input
          type="text"
          name="query"
          className="
            md:text-3xl md:w-[16em] w-full md:h-16 h-12 focus:outline-0 focus:box-shadow-0
            border-pink-300 border-2 focus:border-gray-500 rounded-l
            "
          title="search-query"
          placeholder="Search our knowledge base"
          aria-labelledby="Search our knowledge base" />

        <Button
          type="secondary"
          className="md:text-3xl text-xl rounded-l-none md:h-16 h-12 mt-3  w-full md:w-[8rem]">Search</Button>
      </form>
    </div>

    <div className="bg-gray-50 md:p-6 p-4 w-full flex flex-col items-center">
      <p className="mb-6">Get more help by sending us an email, giving us a call, or using the form on the right:</p>
      <a
        className="md:text-3xl text-xl text-pink-500 font-bold"
        href="mailto:help@sendheirloom.com">help@sendheirloom.com</a>
      <a
        className="md:text-3xl text-xl text-pink-500 font-bold mt-8 block"
        href="tel:855-584-4029">855-584-4029</a>
    </div>
  </div>
}
