import React from 'react'

import DesignSummary from './DesignSummary'
import OrderProgressBar from './OrderProgressBar'
import ButtonLink from '../components/ButtonLink'
import Button from '../components/Button'
import Link from '../components/Link'
import type { OrderFragment, ShippingLabelFragment, PackageFragment } from '../graphql/__generated__'
import type { DesignWithDestinationsFragment } from './OrderEdit'

type OrderSummaryProps = {
  order: OrderFragment | undefined
  showThanks: boolean
}

export default function OrderSummary({ order, showThanks }: OrderSummaryProps) {
  const showBorder = order?.designs && order.designs.length > 1

  let anotherOrderButtonText = "Start a new order"
  order?.designs?.forEach(({ design }) => {
    if (design?.media?.length) {
      anotherOrderButtonText = "Build another book"
    }
  })

  const unvoidedLabels = Array<ShippingLabelFragment>()
  order?.packages?.forEach((pkg: PackageFragment) => {
    pkg?.shippingLabels?.forEach((label: ShippingLabelFragment) => {
      if (!label.voided) {
        unvoidedLabels.push(label)
      }
    })
  })

  return <>
    { order &&
      <OrderProgressBar order={order} />
    }

    { unvoidedLabels.length > 0 &&
      <ul className="mt-6 space-y-6 max-w-lg mx-auto">
        { unvoidedLabels.map((label: ShippingLabelFragment) => {
          return <li className="bg-gray-50 p-4 rounded" key={ label.label_id }>
            <div className="flex justify-between">
              <div className="flex-1 pr-4">
                <h4 className="font-medium text-gray-900">Shipping Label</h4>
                <p className="mt-1 text-sm text-gray-500">
                  { label.tracking_number }
                </p>
              </div>
              <div className="flex-shrink-0 flex flex-col justify-center">
                <Button href={ `https://t.17track.net/en#nums=${ label.tracking_number }` } newPage={ true }>
                  Track
                </Button>
              </div>
            </div>
          </li>
        }) }
      </ul>
    }

    { showThanks &&
      <div className="bg-gray-100 p-6 text-center space-y-4">
        <h1 className="text-xl">Thank you for your order!</h1>

        <p className="text-gray-500 text-sm">You will receive an email with your order details shortly.</p>

        <ButtonLink to="/" className="mt-4">{ anotherOrderButtonText }</ButtonLink>
      </div>
    }

    <ul>
    {
      order?.designs?.map((design: DesignWithDestinationsFragment, i: number) => {
        if (design.design) {
          return <li key={ design.design.id }
            className={"flex flex-col mt-2" + (showBorder ? " p-2 border-gray-200 border-4" : "")}>

            <DesignSummary
              allowEdit={ false }
              showCloneDesign
              shrink={ true }
              order={ order }
              design={ design.design }
              destinations={ design.destinations || null } />
          </li>
        }
      })
    }
    </ul>
  </>
}
