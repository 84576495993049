import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Button from '../components/Button'
import BigOptions from '../components/BigOptions'
import type { BigOption } from '../components/BigOptions'
import ContactForm from '../components/ContactForm'
import OrderIssueReplaceBook from '../components/OrderIssueReplaceBook'
import IssueOrderChooser, { UnknownOrder } from '../components/IssueOrderChooser'
import RequestCable from '../components/RequestCable'

enum ReplaceType {
  No,
  AllowChanges,
  RequireChanges,
}

type Issue = {
  id: string
  title: string
  subtitle: string
  replace?: ReplaceType
  supportNote?: string
  helpStep?: JSX.Element
  customAction?: (orderId: string) => JSX.Element
}

const ISSUES: Array<Issue> = [{
  id: 'cracked',
  title: "Screen Cracked",
  subtitle: "You received a book with a cracked screen",
  replace: ReplaceType.AllowChanges,
}, {
  id: 'screen-quality',
  title: "Screen Quality",
  subtitle: "The viewing angle or picture quality is not what you expected",
  replace: ReplaceType.AllowChanges,
  supportNote: "BUTTON BOOK",
}, {
  id: 'printing',
  title: "Printing",
  subtitle: "The custom printing on your book is not what you were hoping for",
  replace: ReplaceType.AllowChanges,
}, {
  id: 'volume',
  title: "Volume",
  subtitle: "Your book is either too loud or too quiet",
  replace: ReplaceType.AllowChanges,
  supportNote: "BUTTON BOOK",
}, {
  id: 'torn',
  title: "Torn Cover",
  subtitle: "Your book has a cover that is torn",
  replace: ReplaceType.AllowChanges,
}, {
  id: 'detached',
  title: "Detached Cover",
  subtitle: "Your book arrived with the cover detached",
  replace: ReplaceType.AllowChanges,
  helpStep: <>
    <p>If possible, please attempt to firmly press the screen onto the cover, being careful to
      have the same sized gap around the screen on the top, right, and bottom edges.</p>
  </>,
}, {
  id: 'playback',
  title: "Won't Play",
  subtitle: "Your book won't turn on or play your media",
  replace: ReplaceType.AllowChanges,
  helpStep: <>
    <p>Please attempt to charge your book by plugging it into a wall adapter or a computer using
      the included cable. After at least one hour, please unplug the book, and try to open it
      again to see if it will play.</p>
  </>,
}, {
  id: 'missing-media',
  title: "Missing Media",
  subtitle: "Your book is missing one or more of your photos or videos",
  replace: ReplaceType.RequireChanges,
}, {
  id: 'missing-cable',
  title: "Missing Charging Cord",
  subtitle: "You have lost or never receieved the charging cable for your book",
  customAction: RequestCable,
}, {
  id: 'wrong-book',
  title: "Wrong Book",
  subtitle: "Your book does not appear to be what you ordered",
  replace: ReplaceType.AllowChanges,
}, {
  id: 'other',
  title: "Other",
  subtitle: "You have a different issue with a book you received",
},
]

type DoneOpts = {
  contacted: boolean
}

type ReportOrderIssueParams = {
  orderId?: string
  issueType?: string
}

export default function ReportOrderIssue() {
  const { orderId, issueType } = useParams<ReportOrderIssueParams>()
  const [showHelpStep, setShowHelpStep] = useState<JSX.Element | undefined>(undefined)
  const [replaceFailed, setReplaceFailed] = useState<boolean>(false)
  const [showDone, setShowDone] = useState<DoneOpts | undefined>(undefined)

  const navigate = useNavigate()
  const navigateTo = (params: ReportOrderIssueParams) => {
    let next = {orderId, issueType, ...params}
    let parts = ['help', 'order', next.orderId, 'issue', 'report', next.issueType]
    parts = parts.filter(p => p !== undefined)
    navigate('/' + parts.join('/'))
  }

  const onBack = () => {
    navigate('/help/issue/report')
  }

  if (!orderId) {
    return <IssueOrderChooser onChosen={ (orderId) => navigateTo({ orderId }) } onBack={ onBack } />
  }

  const options: Array<BigOption> = []
  ISSUES.forEach(({ id, title, subtitle, helpStep }) => {
    options.push({
      title,
      subtitle,
      onClick: () => {
        navigateTo({ issueType: id })

        if (helpStep) {
          setShowHelpStep(helpStep)
        }
      }
    })
  })

  let body: JSX.Element
  if (showDone) {
    body = <div className="space-y-4">
      <h1>Thank you!</h1>
      { showDone.contacted
        ? <p>We have received your request and will be in touch shortly.</p>
        : <p>We are pleased your issue is resolved. Please don't hesitate to contact us if you have
             any further questions.</p>
      }
    </div>
  } else if (showHelpStep) {
    body = <div className="space-y-4">
      <p>We have an idea that might help! Please follow the following instructions:</p>

      <div className="m-4 p-4 bg-gray-50">
        { showHelpStep }
      </div>

      <p>Did that solve the issue?</p>

      <div className="flex justify-center space-x-4">
        <Button
          onClick={ () => setShowDone({contacted: false}) }
          type="primary">Yes!</Button>

        <Button
          onClick={ () => setShowHelpStep(undefined) }
          type="secondary">No</Button>
      </div>
    </div>
  } else if (!!issueType) {
    const issueDetails = ISSUES.find(({ id }) => id === issueType)
    const replace: ReplaceType | undefined = issueDetails?.replace ?? undefined

    if (issueDetails?.customAction) {
      body = issueDetails.customAction(orderId);
    } else if (replace && issueDetails && orderId !== UnknownOrder && !replaceFailed) {
      body = <div className="space-y-4 mb-[-3.5rem]">
        <div>
          <OrderIssueReplaceBook
            requireChanges={ replace === ReplaceType.RequireChanges }
            supportNote={ `Issue: ${ issueDetails.title }\n${ issueDetails?.supportNote ?? '' }` }
            orderId={ orderId }
            onFail={ () => setReplaceFailed(true) }
          />
        </div>
      </div>
    } else {
      body = <div className="mb-[-3.5rem]">
        <ContactForm
          type="issue"
          details={{
            orderId,
            issueType,
            supportNote: issueDetails?.supportNote ?? '',
          }}
          onDone={ () => setShowDone({contacted: true}) }
          onBack={ () => navigateTo({ issueType: undefined }) } />
      </div>
    }
  } else {
    body = <>
      <p>Please tell us more about the nature of the issue with the book you received.</p>

      <BigOptions options={ options } />
    </>
  }

  return <div className="space-y-4 p-4 md:p-0">
    { body }

    <div className="mt-12">
      <Button
        type="secondary"
        onClick={ () => !!issueType ? (setShowHelpStep(undefined), navigateTo({ issueType: undefined }), setReplaceFailed(false)) : onBack() }
        >Back</Button>
    </div>
  </div>
}
